import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';

import {MarketingConsents, StepCustomerEntityData, StepCustomerValue} from 'models';
import { FunnelInfo } from 'models';
import { DynamicEntityKey } from 'models';
import { StepId } from 'models';
import {
  StepConsentsEntityData,
  StepConsentsValue
} from '../../../../../app-shared/models/steps/step-consents.interface';
import { EntityStoreFacadeService } from '../../../../facades/entity-store-facade/entity-store-facade.service';
import { NavigationActions, NavigationHelpers } from '../../../navigation-feature-store';
import * as FlowActions from '../../actions/flow.actions';

@Injectable()
export class StepConsentsEffects {

  private filterByStep = NavigationHelpers.filterByStepFactory(this.entityStoreFacadeService.store$);


  goNext$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.goNext),
    this.filterByStep(StepId.Consents),
    withLatestFrom(this.entityStoreFacadeService.step.consentsValue$),
    map(([ , stepData ]: [ any, StepConsentsValue ]) => {
      const { marketingConsents } = stepData;

      const newMarketingConsents = {};
      Object.keys(marketingConsents).forEach(key => {
        newMarketingConsents[key] = marketingConsents[key] || false;
      });

      const customerDataPayload: StepConsentsEntityData = {
        [DynamicEntityKey.MarketingConsents]: newMarketingConsents as unknown as MarketingConsents
      };

      return FlowActions.postStepData( { data: customerDataPayload, stepId: StepId.Consents });
    })
  ));


  constructor(
    private actions$: Actions,
    private entityStoreFacadeService: EntityStoreFacadeService
  ) {}
}

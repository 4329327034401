import {
  Document,
  DocumentConstraint
} from '../entity-dynamic.interface';
import { CustomerAvailableDocuments } from '../entity-static.interface';
import { DynamicEntityKey, StaticEntityKey } from '../entity.interface';

export enum DocumentTypeId {
  DrivingLicense = 'italian-driving-license',
  ForeignPassport = 'foreign-passport',
  IdentityCard = 'italian-identity-card',
  Passport = 'italian-passport',
  ResidencePermit = 'residence-permit'
}

export interface DocumentType {
  id: string;
  name: string;
}

export interface StepDocumentValue {
  customerAvailableDocuments: CustomerAvailableDocuments;
  customerDocument: Document;
}

export interface StepDocumentConstraint {
  customerDocument: DocumentConstraint;

}

export interface StepDocumentEntityData {
  [StaticEntityKey.CustomerAvailableDocuments]?: CustomerAvailableDocuments;
  [DynamicEntityKey.CustomerDocument]?: Document;
}

export interface GenderType {
  id: string;
  name: string;
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { DynamicEntityKey, FunnelInfo, StepId, StepShippingMethodEntityData, StepShippingValue } from 'models';
import { map, withLatestFrom } from 'rxjs/operators';
import { StepSimTypeEntityData, StepSimTypeValue } from '../../../../../app-shared/models/steps/step-sim.interface';
import { EntityStoreFacadeService } from '../../../../facades/entity-store-facade/entity-store-facade.service';
import { NavigationActions, NavigationHelpers } from '../../../navigation-feature-store';
import { postStepData } from '../../actions/flow.actions';

@Injectable()
export class StepSimTypeEffects {

  private filterByStep = NavigationHelpers.filterByStepFactory(this.entityStoreFacadeService.store$);

  goNext$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.goNext),
    this.filterByStep(StepId.Sim),
    withLatestFrom(this.entityStoreFacadeService.step.simTypeValue$),
    map(([ , stepData ]: [ any, StepSimTypeValue ]) => {
      const { simType } = stepData;
      const dataPayload: StepSimTypeEntityData = {
        [DynamicEntityKey.SimType]: simType
      };

      return postStepData({ stepId: StepId.Sim, data: dataPayload });
    })
  ));

  goPrevious$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.goPrevious),
    this.filterByStep(StepId.Sim),
    withLatestFrom(this.entityStoreFacadeService.entity.funnelInfo$),
    map(([ , funnelInfo ]: [ any, FunnelInfo ]) => {
      const { returnUrl } = funnelInfo;
      return NavigationActions.navigateTo({ commands: [ returnUrl ], extras: null, external: true });
    })
  ));

  constructor(
    private actions$: Actions,
    private entityStoreFacadeService: EntityStoreFacadeService
  ) {}
}

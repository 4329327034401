import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { DynamicEntityKey, PaymentMethodEnum, StepId } from 'models';
import { concatMap, map, withLatestFrom } from 'rxjs/operators';
import {
  StepPaymentMethodEntityData,
  StepPaymentMethodValue
} from '../../../../../app-shared/models/steps/step-payment-method.interface';
import { EntityStoreFacadeService } from '../../../../facades/entity-store-facade/entity-store-facade.service';
import { NavigationActions, NavigationHelpers } from '../../../navigation-feature-store';
import { postStepData } from '../../actions/flow.actions';
import * as PaymentTransactionActions from '../../actions/payment-transaction.actions';

@Injectable()
export class StepPaymentMethodEffects {

  private filterByStep = NavigationHelpers.filterByStepFactory(this.entityStoreFacadeService.store$);

  goNext$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.goNext),
    this.filterByStep(StepId.PaymentMethod),
    withLatestFrom(
      this.entityStoreFacadeService.step.paymentMethodValue$,
      this.entityStoreFacadeService.entity.paypalTransactionInfo$),
    map(([ , stepData, paypalTransactionInfo ]: [ any, StepPaymentMethodValue, any ]) => {
      const { paymentMethod } = stepData;
      const dataPayload: StepPaymentMethodEntityData = {
        [DynamicEntityKey.PaymentMethod]: paymentMethod
      };
      if (
        paymentMethod &&
        paymentMethod.method === PaymentMethodEnum.PayPal &&
        paypalTransactionInfo && paypalTransactionInfo.value) {
        dataPayload[DynamicEntityKey.PaypalTransactionInfo] = paypalTransactionInfo.value;
      }
      return postStepData({ stepId: StepId.PaymentMethod, data: dataPayload });
    })
  ));

  storePaypalTransactionInfo$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentTransactionActions.savePaypalTransactionInfo),
    concatMap((action: any) => {
      // Save transaction info and go next.
      return [
        NavigationActions.goNext()
      ];
    })
  ));

  restoreStepFromStorage$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.restoreStepFromStorage),
    this.filterByStep(StepId.PaymentMethod),
    map(({ storage }: any) => {
      this.entityStoreFacadeService.entity.storeEntityValue(DynamicEntityKey.PaymentMethod, storage.paymentMethod);
      return null;
    }),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private entityStoreFacadeService: EntityStoreFacadeService
  ) {}
}

import { NavigationExtras, Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { MacroStepId, StepId } from 'models';

export enum NavigationActionsTypes {
  SetNavigationHistory = '[Navigation] Set navigation history',
  PushToNavigationHistory = '[Navigation] Push to navigation history',

  SetNavigationCurrentStepId = '[Navigation] Set navigation current step',
  SetNavigationCurrentMacroStepId = '[Navigation] Set navigation current macro step',

  NavigateTo = '[Navigation] Navigate to',
  NavigateToNext = '[Navigation] Navigate to next',
  NavigateToPrevious = '[Navigation] Navigate to previous',
  NavigateToErrorPage = '[Navigation] Navigate to error page',
  NavigateToWarningPage = '[Navigation] Navigate to warning page',

  SetQueryParams = '[Naviation] Set query params',

  GoNext = '[Navigation] Go next',
  GoPrevious = '[Navigation] Go previous',
  GoCancel = '[Navigation] Go cancel',
  RestoreStepFromStorage = '[Navigation] Restore step from storage'
}

export const setNavigationHistory = createAction(
  NavigationActionsTypes.SetNavigationHistory,
  props<{ steps: StepId[] }>()
);

export const pushToNavigationHistory = createAction(
  NavigationActionsTypes.PushToNavigationHistory,
  props<{ step: StepId }>()
);

export const setNavigationCurrentMacroStepId = createAction(
  NavigationActionsTypes.SetNavigationCurrentMacroStepId,
  props<{ macroStepId: MacroStepId }>()
);

export const setNavigationCurrentStepId = createAction(
  NavigationActionsTypes.SetNavigationCurrentStepId,
  props<{ stepId: StepId }>()
);

export const goNext = createAction(
  NavigationActionsTypes.GoNext
);

export const navigateTo = createAction(
  NavigationActionsTypes.NavigateTo,
  props<{ commands: any[], extras?: NavigationExtras, external?: boolean }>()
);

export const navigateToNext = createAction(
  NavigationActionsTypes.NavigateToNext
);

export const navigateToPrevious = createAction(
  NavigationActionsTypes.NavigateToPrevious
);

export const navigateToErrorPage = createAction(
  NavigationActionsTypes.NavigateToErrorPage,
  props<{ errorMessage?: string }>()
);

export const navigateToWarningPage = createAction(
  NavigationActionsTypes.NavigateToWarningPage,
  props<{ errorMessage?: string }>()
);

export const setQueryParams = createAction(
  NavigationActionsTypes.SetQueryParams,
  props<{ queryParams?: Params }>()
);

export const goPrevious = createAction(
  NavigationActionsTypes.GoPrevious
);

export const restoreStepFromStorage = createAction(
  NavigationActionsTypes.RestoreStepFromStorage,
  props<{ storage?: any }>()
);

export const goCancel = createAction(
  NavigationActionsTypes.GoCancel
);


export type NavigationActionsUnion = ReturnType<
  | typeof setNavigationHistory
  | typeof setNavigationCurrentStepId
  | typeof setNavigationCurrentMacroStepId

  | typeof pushToNavigationHistory

  | typeof navigateTo
  | typeof navigateToNext
  | typeof navigateToPrevious
  | typeof navigateToErrorPage
  | typeof navigateToWarningPage

  | typeof goNext
  | typeof goPrevious
  | typeof goCancel

  | typeof setQueryParams
  >;

import { createAction, props } from '@ngrx/store';
import { BasketValueData } from '../../../../app-shared/models/basket.interface';
import { StepId } from '../../../../app-shared/models/step.interface';

export enum FlowActionsTypes {
  GetStepData = '[Flow] Get step data',
  PostStepData = '[Flow] Post step data'
}

export const getStepData = createAction(
  FlowActionsTypes.GetStepData,
  props<{ stepId?: StepId }>()
);

export const postStepData = createAction(
  FlowActionsTypes.PostStepData,
  props<{  data: BasketValueData, stepId: StepId }>()
);

export type FlowActionsUnion = ReturnType<
  | typeof getStepData
  | typeof postStepData
  >;

import { Component, Input } from '@angular/core';

@Component({
  selector: 'sb-box-info',
  templateUrl: './box-info.component.html',
  styleUrls: ['./box-info.component.scss']
})


export class BoxInfoComponent {
  @Input() infoText;
  @Input() iconType;
  @Input() transparent = false;
  @Input() autowidth = false;
  @Input() additionalCSSClasses = '';
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {DynamicEntityKey, FunnelInfo, StepId} from 'models';
import { map, withLatestFrom } from 'rxjs/operators';
import {StepCouponEntityData, StepCouponValue} from '../../../../../app-shared/models/steps/step-coupon.interface';
import { EntityStoreFacadeService } from '../../../../facades/entity-store-facade/entity-store-facade.service';
import { NavigationActions, NavigationHelpers } from '../../../navigation-feature-store';
import * as FlowActions from '../../actions/flow.actions';

@Injectable()
export class StepCouponEffects {

  private filterByStep = NavigationHelpers.filterByStepFactory(this.entityStoreFacadeService.store$);


  goNext$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.goNext),
    this.filterByStep(StepId.Coupon),
    withLatestFrom(this.entityStoreFacadeService.step.couponValue$),
    map(([ , stepData ]: [ any, StepCouponValue ]) => {
      const { couponInfo } = stepData;
      const dataPayload: StepCouponEntityData = {
        [DynamicEntityKey.CouponInfo]: {
          ...couponInfo,
        }
      };
      return FlowActions.postStepData({ stepId: StepId.Coupon, data: dataPayload });
    })
  ));

  goPrevious$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.goPrevious),
    this.filterByStep(StepId.Coupon),
    withLatestFrom(
      this.entityStoreFacadeService.entity.funnelInfo$
    ),
    map(([ , funnelInfo ]: [ any, FunnelInfo ]) => {
      const { returnUrl } = funnelInfo;
      return NavigationActions.navigateTo({ commands: [ returnUrl ], extras: null, external: true });
    })
  ));

  constructor(
    private actions$: Actions,
    private entityStoreFacadeService: EntityStoreFacadeService
  ) {}
}

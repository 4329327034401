import { Inject, Injectable } from '@angular/core';
import { SESSION_STORAGE } from '../../../app/providers/browser.provider';

export const HEADER_TOKEN_NAME = 'X-FunnelBuddy';

@Injectable()
export class AuthService {

  private token: string;

  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: WindowSessionStorage
  ) { }

  public getToken(): string {
    if (!this.token) {
      this.token = sessionStorage.getItem(HEADER_TOKEN_NAME);
    }

    return this.token;
  }

  public setToken(value: string): void {
    this.token = value;
    sessionStorage.setItem(HEADER_TOKEN_NAME, value);
  }
}

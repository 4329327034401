import { Pipe, PipeTransform } from '@angular/core';
import { ActivationMethodEnum, CartAnalysis, CartAnalysisActivationMethodsEnum } from 'models';

@Pipe({
  name: 'inferOperatorsCartMessage'
})
export class InferOperatorsCartMessagePipe implements PipeTransform {

  transform(cartAnalysis: CartAnalysis, operators: string[]): any {
    if (!cartAnalysis) { return ''; }
    const MAXIMUM_VISIBLE_OPERATORS = 3;
    let message;
    if (cartAnalysis.activationMechanism === CartAnalysisActivationMethodsEnum.Both) {
      message = 'Offerta disponibile per nuovi clienti';
      if (!operators.length) {
        message += ' e per i clienti di tutti gli operatori';
      } else if (operators.length > MAXIMUM_VISIBLE_OPERATORS) {
        // return only the first element
        message += `, per i clienti ${operators[0]} e `;
      } else {
        // return the elements that are in the list
        message += `, per i clienti ${operators.slice(0, operators.length - 1).join(', ')} e ${operators[operators.length - 1]}`;
      }
    } else if (cartAnalysis.activationMechanism === CartAnalysisActivationMethodsEnum.New) {
      message = 'ENTITY.CART.OFFER_AVAILABLE_NEW_CUSTOMERS';
    } else {
      if (Object.keys(cartAnalysis.operatorsByPrice).length === 1) {
        // All have the same price
        message = `Offerta disponibile per `;
        if (operators.length > MAXIMUM_VISIBLE_OPERATORS) {
          // return only the first element
          message += `i clienti ${operators[0]} e `;
        } else if (operators.length === 1) {
          // return only the first element
          message += `i clienti ${operators[0]}`;
        } else {
          // return the elements that are in the list
          message += `i clienti ${operators.slice(0, operators.length - 1).join(', ')} e ${operators[operators.length - 1]}`;
        }
      } else {
        // Take the lowest price from the operator prices, and add it with the sim price from the cart
        const price = Math.min(...Object.keys(cartAnalysis.operatorsByPrice).map(r => parseFloat(r))) +
          cartAnalysis.simPrice.value;
        message = `Costo SIM e Attivazione ${price.toFixed(2).replace('.', ',')} € per`;
      }
    }
    return message;
  }

}

import { createFeatureSelector, createSelector } from '@ngrx/store';
const selectNavigationState = createFeatureSelector('navigation');
const ɵ0 = (state) => state.history;
export const selectHistory = createSelector(selectNavigationState, ɵ0);
const ɵ1 = (state) => state.currentStepId;
export const selectCurrentStepId = createSelector(selectNavigationState, ɵ1);
const ɵ2 = (state) => state.currentMacroStepId;
export const selectCurrentMacroStepId = createSelector(selectNavigationState, ɵ2);
const ɵ3 = (state) => state.nextStepId;
export const selectNextStepId = createSelector(selectNavigationState, ɵ3);
const ɵ4 = (state) => state.previousStepId;
export const selectPreviousStepId = createSelector(selectNavigationState, ɵ4);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };

import produce from 'immer';

import { DynamicEntityKey, StaticEntityKey } from 'models';
import { EntityActionsUnion, EntityActionTypes } from './entity.actions';
import { EntityState, initialEntityState } from './entity.state';

export function entityReducer(state = initialEntityState, action: EntityActionsUnion): EntityState {
  switch (action.type) {
    case EntityActionTypes.StoreStaticEntity: {
      const { key, value } = (action);

      return produce(state, (draftState: EntityState) => {
        draftState.static = {
          ...draftState.static,
          [key]: value
        };
      });
    }
    case EntityActionTypes.StoreDynamicEntity: {
      const { key, value } = action;

      return produce(state, (draftState: EntityState) => {
        draftState.dynamic = {
          ...draftState.dynamic,
          [key]: value
        };
      });
    }
    case EntityActionTypes.StoreValue: {
      const { key, value } = action;

      if (Object.values(StaticEntityKey).includes(key)) {
        return produce(state, (draftState: EntityState) => {
          draftState.static[key] = value;
        });
      } else if (Object.values(DynamicEntityKey).includes(key)) {
        return produce(state, (draftState: EntityState) => {
          if (!draftState.dynamic[key]) {
            console.warn(`WARN: Entity ${key} not received from back-end!`);
            draftState.dynamic[key] = { value: {}, constraint: {}, options: {} };
          }
          if (value && value.constraint) {
            draftState.dynamic[key] = value;
          } else {
            draftState.dynamic[key].value = value;
          }
        });
      } else {
        console.warn('Unknown entity', key);
        return state;
      }
    }
    default:
      return state;
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';

import { BasketData, EntityKey } from 'models';
import { BasketService } from '../../../../app-shared/services/basket/basket.service';
import { generateRequiredEntitiesKeysFromStepId, parseBasketData } from '../process.helpers';

import * as BasketActions from '../actions/basket.actions';

@Injectable()
export class BasketEffects {

  getEntitiesData$ = createEffect(() => this.actions$.pipe(
    ofType(BasketActions.getEntitiesData),
    concatMap(({ entities }) => {
      if (entities.length) {
        return this.basketService.getEntitiesData(entities);
      }

      console.warn('No entities to read, skipping basket request');
      return EMPTY;
    }),
    switchMap((entitiesBasketData: BasketData) => parseBasketData(entitiesBasketData))
  ));

  getStepData$ = createEffect(() => this.actions$.pipe(
    ofType(BasketActions.getStepData),
    map(({ stepId, ignoredEntitiesKeys }) => {
      const entitiesKeys = generateRequiredEntitiesKeysFromStepId(stepId, ignoredEntitiesKeys);
      return BasketActions.getEntitiesData({ entities: entitiesKeys });
    })
  ));

  getMultipleStepData$ = createEffect(() => this.actions$.pipe(
    ofType(BasketActions.getMultipleStepData),
    map(({ stepIds, ignoredEntitiesKeys }) => {
      const entitiesToGet: Set<EntityKey> = new Set();
      stepIds.forEach(stepId => {
        generateRequiredEntitiesKeysFromStepId(stepId, ignoredEntitiesKeys).forEach(entityKey => {
          entitiesToGet.add(entityKey);
        });
      });

      return BasketActions.getEntitiesData({ entities: Array.from(entitiesToGet) });
    })
  ));

  postEntitiesData$ = createEffect(() => this.actions$.pipe(
    ofType(BasketActions.postEntitiesData),
    concatMap(({ data }) => {
      if (Object.keys(data).length) {
        return this.basketService.postEntitiesData(data);
      } else {
        console.warn('No entities to write, skipping basket request');
        return EMPTY;
      }
    }),
    switchMap(() => EMPTY)
  ));

  constructor(
    private actions$: Actions,
    private basketService: BasketService
  ) {}
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AnalyticsService } from 'services';


@Component({
  selector: 'sb-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: [ './not-found.component.scss' ]
})
export class NotFoundComponent implements OnInit {
  @Output() backClick = new EventEmitter();

  constructor(private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.analyticsService.sendGenericError('Pagina non trovata');
  }

  goToPreviousStep() {
    this.backClick.emit();
  }
}

import {DOCUMENT, registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {ofType} from '@ngrx/effects';
import {ROUTER_NAVIGATED, RouterNavigatedAction} from '@ngrx/router-store';
import {TranslateService} from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import {isIE} from 'helpers';

import {InternalPromoInfo, PROMO_TYPES, ValidationError} from 'models';
import { Subscription } from 'rxjs';
import {buffer, debounceTime, distinctUntilChanged, filter, tap, withLatestFrom} from 'rxjs/operators';
import {
  AnalyticsService,
  EnvironmentService,
  ErrorsService,
  OfferPromoService,
  PaypalService,
  ScrollService
} from 'services';
import {ModalType} from '../app-shared/models/modal.interface';

import {AppStoreFacadeService} from './facades/app-store-facade.service';

import * as SpinnerActions from './store/process-feature-store/actions/spinner.actions';
import {RouterState} from './store/route-feature-store';


@Component({
  selector: 'sb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // animations: [ routeAnimations ]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'very';

  public shouldShowSpinner = true;
  public shouldShowBrand: boolean;

  private subscriptions = new Subscription();
  private promos: InternalPromoInfo[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private appStoreFacadeService: AppStoreFacadeService,
    private changeDetectorRef: ChangeDetectorRef,
    private environmentService: EnvironmentService,
    private translateService: TranslateService,
    private scrollService: ScrollService,
    private errorsService: ErrorsService,
    private analyticsService: AnalyticsService,
    private paypalService: PaypalService,
    private offerPromoService: OfferPromoService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.configureSpinner();
    this.configureBrand();
    this.configureAutoscroll();
    this.configureCrossValidationErrorsModals();
    this.configureOfferPromo();

    this.setupLocale();
    this.setupSentry();
    this.setupBrowser();
  }

  ngAfterViewInit(): void {
    this.setupAnalytics();
    this.setupPaypal();
    /*OLD CODE if (!localStorage.getItem('windtre')) {
      OLD CODE this.appStoreFacadeService.navigation.navigateToWarningPage({
        OLD CODE title: `error-maintenance_title`,
        OLD CODE description: `error-maintenance_description`,
        OLD CODE note: `error-maintenance_note`
      OLD CODE });
    OLD CODE }*/
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getRouteAnimation(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  private setupLocale() {
    const defaultLanguage = 'it';

    // Angular pipes i18n
    registerLocaleData(localeIt, defaultLanguage);

    // NGX Translate
    this.translateService.setDefaultLang(defaultLanguage);
    this.translateService.use(defaultLanguage);
  }

  private setupSentry() {
    const { url } = this.environmentService.getSentryConfiguration();

    if (url) {
      Sentry.init({
        dsn: url
      });
    }
  }

  private setupBrowser() {
    const ieCssClass = 'is-ie';

    if (isIE()) {
      this.document.body.className = `${ this.document.body.className } ${ ieCssClass }`;
      this.router.navigate(['./old-browser']);
    }
  }

  private setupAnalytics() {
    const { gtmKey } = this.environmentService.getAnalyticsConfiguration();
    if (gtmKey) {
      this.analyticsService.appendScripts(gtmKey);
    }
  }

  private setupPaypal() {
    const paypalConfiguration = this.environmentService.getPaypalConfiguration();
    this.paypalService.appendScripts(paypalConfiguration);
    /* OLD CODE this.subscriptions.add(
      OLD CODE this.appStoreFacadeService.entity.paymentAvailableMethods$
      OLD CODE   .pipe(first(Boolean))
      OLD CODE   .subscribe(({ methods }: PaymentAvailableMethods) => {
      OLD CODE     if (methods.includes(PaymentMethodEnum.PayPal)) {
OLD CODE
      OLD CODE     }
     OLD CODE    })
   OLD CODE  ); */
  }

  private configureSpinner() {
    const minimumSpinnerDuration = 500;
    const isEqualSpinnerActionStatus = ({ status: prevState }, { status: curState }) => prevState === curState;

    this.subscriptions.add(
      this.appStoreFacadeService.actions$
        .pipe(
          ofType(SpinnerActions.updateSpinnerStatus),
          distinctUntilChanged(isEqualSpinnerActionStatus),
          tap(({ status: isActive }) => {
            if (isActive && !this.shouldShowSpinner) {
              // Activate spinner as soon as possible (without debounce)
              this.shouldShowSpinner = true;
              this.changeDetectorRef.detectChanges();
            }
          }),
          debounceTime(minimumSpinnerDuration),
        )
        .subscribe(({ status: isActive }) => {
          // De-activate spinner minimumSpinnerDuration after
          this.shouldShowSpinner = isActive;
          this.changeDetectorRef.detectChanges();
        })
    );
  }

  private configureAutoscroll() {
    this.subscriptions.add(
      this.appStoreFacadeService.actions$
        .pipe(ofType<RouterNavigatedAction<RouterState.RouterStateUrl>>(ROUTER_NAVIGATED))
        .subscribe(() => {
          this.scrollService.scrollToTop();
        })
    );
  }

  private configureBrand() {
    const hiddenBrandPages = ['error', 'not-found'];

    this.subscriptions.add(
      this.appStoreFacadeService.route.url$
        .pipe(filter(Boolean))
        .subscribe((url: string) => {
          this.shouldShowBrand = !(hiddenBrandPages.some(hiddenBrandPage => url.includes(hiddenBrandPage)));
        })
    );
  }

  private configureCrossValidationErrorsModals() {
    const crossValidationErrors$ = this.appStoreFacadeService.errors.crossValidationErrors$;

    this.subscriptions.add(
      crossValidationErrors$
        .pipe(
          // collects output values until crossValidationErrors$ observable emits (will emit 250ms after first error)
          // this will ensure buffer to accumulate cross validation errors from current step
          buffer(crossValidationErrors$.pipe(debounceTime(50))),
        )
        .subscribe((validationErrors: ValidationError[]) => {
          const otpErrors = validationErrors.find(error => (error.message || error.path).includes('otp') );
          if (otpErrors) {
            return;
          }
          const errorsHTML = this.errorsService.transformValidationErrorsToHTML(validationErrors);
          this.appStoreFacadeService.errors.openErrorModal(errorsHTML);
        })
    );
  }

  private configureOfferPromo() {
    this.subscriptions.add(
      this.appStoreFacadeService.entity.offerPromoInfo$
        .pipe(
            withLatestFrom(
              this.appStoreFacadeService.entity.offer$,
              this.appStoreFacadeService.entity.couponInfo$
            ),
            filter(args => args.every(Boolean))
        ).subscribe(([ offerPromoEntity, offer, couponInfo ]) => {
          if (!offerPromoEntity || !offer) {
            return;
          }
          // Verify if offer promo info has changed to show the modal.
          const newPromos = this.offerPromoService.elaborateOfferPromoInfo(offerPromoEntity, offer, couponInfo);
          const getDiscount = (promos) => {
            return promos.filter(r => r.promoType === PROMO_TYPES.DISCOUNT).pop();
          };
          const actualDiscount = getDiscount(newPromos);
          const prevDiscount = getDiscount(this.promos);
          this.promos = newPromos;
          const isSwitchedOn = false;
          if (
            (actualDiscount && !prevDiscount) ||
            (actualDiscount && prevDiscount && JSON.stringify(prevDiscount) !== JSON.stringify(actualDiscount))
          ) {
            if (isSwitchedOn) {
              this.appStoreFacadeService.modals.openModal(ModalType.PromoInfo, { message: actualDiscount.info });
              this.analyticsService.sendDiscountInfo(actualDiscount);
            }
          }
        })
    );
  }
}


import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Address, DynamicEntityKey, SimType, StepId } from 'models';
import { map, withLatestFrom } from 'rxjs/operators';
import { StepAddressEntityData, StepAddressValue } from '../../../../../app-shared/models/steps/step-address.interface';
import { SimTypeEnum } from '../../../../../app-shared/models/steps/step-sim.interface';
import { EntityStoreFacadeService } from '../../../../facades/entity-store-facade/entity-store-facade.service';
import { NavigationActions, NavigationHelpers } from '../../../navigation-feature-store';
import * as FlowActions from '../../actions/flow.actions';

@Injectable()
export class StepAddressEffects {

  private filterByStep = NavigationHelpers.filterByStepFactory(this.entityStoreFacadeService.store$);

  goNext$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.goNext),
    this.filterByStep(StepId.Address),
    withLatestFrom(
      this.entityStoreFacadeService.step.addressValue$,
      this.entityStoreFacadeService.entity.simType$
    ),
    map(([ , stepData, simType ]: [ any, StepAddressValue, SimType ]) => {
      const { customerResidenceAddress, customerDeliveryAddress } = stepData;
      const inferFullStreet = (deliveryAddress: Address) => {
        return deliveryAddress.at ?
          `${deliveryAddress.street} - ${deliveryAddress.at}` :
          `${deliveryAddress.street}`;
      };

      const normalizeAddress = address => (address || '').trim()
        .replace(/-/gi, ' ')
        .replace(/’/gi, '\'')
        .replace(/`/gi, '\'')
        .replace(/\s\s\s/gi, ' ')
        .replace(/\s\s/gi, ' ');

      const transformAddress = (address: Address): Address => {
        return {
          ...address,
          street: address.street ? normalizeAddress(address.street) : address.street,
          at: address.at ? normalizeAddress(address.at) : address.at
        };
      };

      const dataPayload: StepAddressEntityData = {
        [DynamicEntityKey.CustomerResidenceAddress]: transformAddress(customerResidenceAddress),
        [DynamicEntityKey.CustomerDeliveryAddress]: {
          ...transformAddress(customerDeliveryAddress),
          fullStreet: inferFullStreet(transformAddress(customerDeliveryAddress))
        }
      };
      const simTypeValue = (simType as any).value || simType || '';
      if (simTypeValue.type !== SimTypeEnum.Standard) {
        // Don't pass delivery address in case of e-sim or idel
        dataPayload[DynamicEntityKey.CustomerDeliveryAddress] = undefined;
      }
      return FlowActions.postStepData({ stepId: StepId.Address, data: dataPayload });
    })
  ));

  restoreStepFromStorage$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.restoreStepFromStorage),
    this.filterByStep(StepId.Address),
    map(({ storage }: any) => {
      this.entityStoreFacadeService.entity.storeEntityValue(DynamicEntityKey.CustomerIdentity, storage.customerIdentity);
      this.entityStoreFacadeService.entity.storeEntityValue(DynamicEntityKey.CustomerDeliveryAddress, storage.deliveryAddress);
      this.entityStoreFacadeService.entity.storeEntityValue(DynamicEntityKey.CustomerResidenceAddress, storage.residenceAddress);
      this.entityStoreFacadeService.entity.storeEntityValue(DynamicEntityKey.SimType, storage.simType);
      return null;
    }),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private entityStoreFacadeService: EntityStoreFacadeService
  ) {}
}

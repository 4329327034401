<div class="error-page">
  <sb-header [showCart]="false" title=""></sb-header>
  <div class="error-page__container">
    <div class="error-page__content">
      <div class="error-page__title">
        {{'STEP.ERROR.TITLE' | translate}}
      </div>
      <div class="error-page__subtitle" [innerHTML]="'STEP.ERROR.SUBTITLE' | translate"></div>
      <div class="error-page__content--with-icons"></div>
      <div class="wt-text-base-s error__note" *ngIf="textNote$ | async">
        {{textNote$ | async}}
      </div>
    </div>
  </div>
</div>
<sb-creation-buttons></sb-creation-buttons>

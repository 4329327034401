import produce from 'immer';
import isEmpty from 'lodash/isEmpty';

import { StepId } from 'models';
import {
  NavigationActionsTypes,
  NavigationActionsUnion
} from './navigation.actions';
import { initialNavigationState, NavigationState } from './navigation.state';


const inferNextStepId = (currentStepId: StepId, history: StepId[]) => {
  const currentStepIdIndex = history.indexOf(currentStepId);
  return currentStepIdIndex !== -1 ? history[currentStepIdIndex + 1] : null;
};

const inferPreviousStepId = (currentStepId: StepId, history: StepId[]) => {
  const currentStepIdIndex = history.indexOf(currentStepId);
  return currentStepIdIndex !== -1 ? history[currentStepIdIndex - 1] : null;
};

export function navigationReducer(state = initialNavigationState, action: NavigationActionsUnion): NavigationState {
  switch (action.type) {
    case NavigationActionsTypes.SetNavigationHistory: {
      const currentStepId = state.currentStepId;
      const newHistory = action.steps || [];

      return produce(state, (draftState: NavigationState) => {
        let historyValue = newHistory;

        if (!newHistory.includes(currentStepId)) {
          historyValue = [ ...newHistory, currentStepId ];
        }

        draftState.history = historyValue;
        draftState.nextStepId = inferNextStepId(currentStepId, draftState.history);
        draftState.previousStepId = inferPreviousStepId(currentStepId, draftState.history);
      });
    }
    case NavigationActionsTypes.PushToNavigationHistory: {
      const nextStepId = action.step;
      const history = state.history.includes(nextStepId) ?
        state.history : [ ...state.history, action.step ];

      return {
        ...state,
        history,
        nextStepId,
        previousStepId: state.history[state.history.length - 1]
      };
    }
    case NavigationActionsTypes.SetNavigationCurrentStepId: {
      const newCurrentStepId = action.stepId;

      return produce(state, (draftState: NavigationState) => {
        if (isEmpty(state.history)) {
          // Set current step as the first in case history is empty
          draftState.history = [ newCurrentStepId ];
        } else if (!state.history.includes(newCurrentStepId)) {
          // Add current step to directory
          draftState.history = [ ...state.history, newCurrentStepId ];
        }

        draftState.currentStepId = newCurrentStepId;
        draftState.nextStepId = inferNextStepId(newCurrentStepId, state.history);
        draftState.previousStepId = inferPreviousStepId(newCurrentStepId, state.history);
      });
    }
    case NavigationActionsTypes.SetNavigationCurrentMacroStepId: {
      return {
        ...state,
        currentMacroStepId: action.macroStepId
      };
    }
    default:
      return state;
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionId } from 'models';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { AnalyticsService, ScrollService } from 'services';
import { ModalType } from '../../app-shared/models/modal.interface';
import { AppStoreFacadeService } from '../facades/app-store-facade.service';

@Injectable()
export class RequestsInterceptor implements HttpInterceptor {

  private static requestCount = 0;
  private static appStoreFacadeService: AppStoreFacadeService;
  private static scrollService: ScrollService;
  private static analyticsService: AnalyticsService;
  private static ignoredUrlPatterns = [
    new RegExp('videoriconoscimento'),
    new RegExp('utils/cities'),
    new RegExp(`actionId=${ActionId.cities}`),
    new RegExp(`identifier=${ActionId.cities}`),
    new RegExp(`actionId=${ActionId.getPaypalOrderId}`),
    new RegExp(`identifier=${ActionId.getPaypalOrderId}`),
    new RegExp(`actionId=${ActionId.getPaypalToken}`),
    new RegExp(`identifier=${ActionId.getPaypalToken}`),
    new RegExp(`actionId=${ActionId.bankInfo}`),
    new RegExp(`identifier=${ActionId.bankInfo}`),
    new RegExp(`actionId=${ActionId.creditCardInfo}`),
    new RegExp(`identifier=${ActionId.creditCardInfo}`),
    new RegExp(`actionId=${ActionId.verifyOperatorUpdate}`),
    new RegExp(`identifier=${ActionId.verifyOperatorUpdate}`),
    new RegExp(`actionId=${ActionId.getPrecontractLink}`),
    new RegExp(`identifier=${ActionId.getPrecontractLink}`)
  ];

  constructor(appStoreFacadeService: AppStoreFacadeService, scrollService: ScrollService, analyticsService: AnalyticsService) {
    RequestsInterceptor.appStoreFacadeService = appStoreFacadeService;
    RequestsInterceptor.scrollService = scrollService;
    RequestsInterceptor.analyticsService = analyticsService;
  }

  private static onSuccess() {
    // Ignore
  }

  private static onError(req: any) {
    const { status, error: errorResponse, headers } = req;

    switch (status) {
      case 403: {
        RequestsInterceptor.appStoreFacadeService.navigation.navigateToErrorPage('Sessione scaduta');
        break;
      }
      case 503: {
        RequestsInterceptor.appStoreFacadeService.navigation.navigateToWarningPage({
          title: `error-maintenance_title`,
          description: `error-maintenance_description`,
          note: `error-maintenance_note`
        });
        break;
      }
      case 422: {
        RequestsInterceptor.appStoreFacadeService.errors.addValidationErrors(errorResponse.errorDiagnostic || []);
        // ARES-2033: Verify error flow reset
        const hasResetFlowError = !!(errorResponse.errorDiagnostic || []).find(r => r.path === 'reset-flow.error');
        if (hasResetFlowError) {
          RequestsInterceptor.appStoreFacadeService.modals.openModal(ModalType.FlowError);
        }
        // Verify ICCID Error
        const hasICCIDError = !!(errorResponse.errorDiagnostic || []).find(r => r.path === 'activation-mnp-info.iccid');
        if (hasICCIDError) {
          RequestsInterceptor.appStoreFacadeService.errors.openIccidErrorModal();
        }
        // Verify FDO ICCID Error
        const hasFdoICCIDErrors = (errorResponse.errorDiagnostic || []).find(r => r.path === 'fdo-iccid.iccid');
        if (hasFdoICCIDErrors) {
          RequestsInterceptor.appStoreFacadeService.errors.openVRErrorModal(hasFdoICCIDErrors.message);
        }
        // Verify Paypal Error
        const hasPaypalError = !!(errorResponse.errorDiagnostic || []).find(r => r.path === 'paypal_authorization_failed');
        if (hasPaypalError) {
          RequestsInterceptor.appStoreFacadeService.navigation.setQueryParams({
            textNote: 'STEP.PAYMENT-METHOD.PAYPAL_ERROR'
          });
        }
        // Verify ARES-646: H3G message
        const hasH3GError = !!(errorResponse.errorDiagnostic || []).find(r => r.path === 'h3g_iccid_error');
        if (hasH3GError) {
          RequestsInterceptor.appStoreFacadeService.errors.openH3GErrorModal();

        }

        const campaignError = (errorResponse.errorDiagnostic || []).find(r => r.path === 'campaign.error');
        if (campaignError) {
          RequestsInterceptor.appStoreFacadeService.errors.openErrorModal(
            campaignError.message, 'icon-warning', ''
          );
        }

        const couponError = (errorResponse.errorDiagnostic || []).find(r => r.path === 'coupon-info.code');
        if (couponError) {
          RequestsInterceptor.appStoreFacadeService.errors.openErrorModal(
            'COUPON.CODE_ERROR.GENERIC', 'icon-warning', 'Oops!'
          );
        }

        const temporaryError = (errorResponse.errorDiagnostic || []).find(r => (r.path || '').toLowerCase() === 'temporary.error');
        const messageTemporaryError = (errorResponse.errorDiagnostic || [])
          .find(r => (r.message || '').toLowerCase() === 'temporary.error');
        if (temporaryError) {
          RequestsInterceptor.appStoreFacadeService.errors.openErrorModal(
            'MODALS.ERROR.TEMPORARY', 'temporary-error-modal', '');
        }


        RequestsInterceptor.scrollService.scrollToFirstInvalidInput();
        RequestsInterceptor.analyticsService.sendFormError((errorResponse.errorDiagnostic || []).map(err => {
          return {
            field: err.path,
            description: err.message || err.code
          };
        }));
        break;
      }
      case 500:
        if (errorResponse &&
          errorResponse.errorDiagnostic &&
          typeof errorResponse.errorDiagnostic === 'string' &&
          errorResponse.errorDiagnostic.indexOf('warning|') > -1) {
          const errorType = errorResponse.errorDiagnostic.split('warning|')[1];
          RequestsInterceptor.appStoreFacadeService.navigation.navigateToWarningPage({
            title: `${errorType}_title`,
            description: `${errorType}_description`,
            note: `${errorType}_note`
          });
        } else {
          // OLD CODE const transactionId = headers && headers.get('x-widi-ttid');
          const textNote = 'Status 500'; // OLD CODE transactionId ? `transactionId: ${ transactionId }` : null;
          RequestsInterceptor.appStoreFacadeService.navigation.navigateToErrorPage(textNote);
        }
        break;
      default: {
        if (!status) {
          // Capture a very special type of error. Paypal after-payment Ambassador CORS error.
          const envConf = (window as any).__env;
          if ((req.url.includes(envConf.api.host)) && req.url.includes('payment-method')) {
            const errorType = 'post_payment_error';
            RequestsInterceptor.appStoreFacadeService.navigation.navigateToWarningPage({
              title: `${errorType}_title`,
              description: `${errorType}_description`,
              note: `${errorType}_note`
            });
          }
        } else {
          // OLD CODE const transactionId = headers && headers.get('x-widi-ttid');
          const info = errorResponse &&
            errorResponse.errorDiagnostic &&
            typeof errorResponse.errorDiagnostic === 'string' &&
            errorResponse.errorDiagnostic || '';
          const textNote = `Status ${status} - ${info}`; // OLD CODE transactionId ? `transactionId: ${ transactionId }` : null;
          RequestsInterceptor.appStoreFacadeService.navigation.navigateToErrorPage(textNote);
        }

      }
    }
  }

  private static onFinalize() {
    RequestsInterceptor.requestCount -= 1;

    if (RequestsInterceptor.requestCount === 0) {
      console.warn('FALSE FINALIZE ZERO');
      RequestsInterceptor.appStoreFacadeService.spinner.updateSpinnerStatus(false);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { urlWithParams } = req;

    if (RequestsInterceptor.ignoredUrlPatterns.some((ignoredUrlPattern) => Boolean(urlWithParams.match(ignoredUrlPattern)))) {
      return next.handle(req);
    }

    if (RequestsInterceptor.requestCount === 0) {
      console.warn(`TRUE REQUEST ${req.url}`);
      RequestsInterceptor.appStoreFacadeService.spinner.updateSpinnerStatus(true);
    }

    RequestsInterceptor.requestCount += 1;

    return next.handle(req)
      .pipe(
        tap(
          RequestsInterceptor.onSuccess,
          RequestsInterceptor.onError
        ),
        finalize(
          RequestsInterceptor.onFinalize
        )
      );
  }
}

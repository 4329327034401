import { createAction, props } from '@ngrx/store';
import { PaymentMethod, PaypalTransactionInfo } from 'models';

export enum PaymentTransactionTypes {
  SavePaypalTransactionInfo = '[Payment] Save Paypal Transaction info',
}

export const savePaypalTransactionInfo = createAction(
  PaymentTransactionTypes.SavePaypalTransactionInfo,
  props<{ paypalTransactionInfo: PaypalTransactionInfo, paymentMethod: PaymentMethod }>()
);

export type PaymentTransactionActionsUnion = ReturnType<
  | typeof savePaypalTransactionInfo
  >;

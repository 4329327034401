import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private valueSource = new BehaviorSubject<any>(null);
  currentValue = this.valueSource.asObservable();

  changeValue(newValue: any) {
    this.valueSource.next(newValue);
  }
}

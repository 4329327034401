import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Cart, InternalPromoInfo, Offer, OfferExtras, PROMO_TYPES} from 'models';
import { Subscription } from 'rxjs';
import { OfferPromoService } from 'services';
import { StepsBarItem } from 'ui-kit-lib';
import { SimTypeEnum } from '../../../../../app-shared/models/steps/step-sim.interface';
import { WINDOW } from '../../../../providers/browser.provider';

@Component({
  selector: 'sb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {

  @Input() steps: StepsBarItem[];
  @Input() activeStep: StepsBarItem;
  @Input() showCart = true;
  @Input() showStepsLabels: boolean;
  @Input() showStepActiveLabel: boolean;
  @Input() title: string;
  @Input() offer: Offer;
  @Input() offerAlternative!: Offer;
  @Input() offerExtras!: OfferExtras;
  @Input() hasDoublePO!: boolean;
  @Input() cart: Cart;
  @Input() promos: InternalPromoInfo[];
  @Input() simType: SimTypeEnum = SimTypeEnum.Standard;
  @Input() redirectUrl: string;
  @Output() cartIsOpen = new EventEmitter();
  @Input() availableOperators: string[];
  @Input() availableActivationMethods: string[];

  @ViewChild('headerTemplate', { static: false }) header: TemplateRef<any>;

  public modalIsOpen: boolean;
  private overlayRef: OverlayRef;
  private subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get headerTitle() {
    return this.title || (this.activeStep && this.activeStep.label);
  }

  get shouldBeVisible(): boolean {
    return !this.modalIsOpen;
  }

  public onLogoClick(): void {
    if (this.redirectUrl) {
      this.window.location.href = this.redirectUrl;
    }
  }

  public toggleCollapse($event) {
    if ($event && $event.preventDefault) {
      $event.preventDefault();
    }
    if (this.modalIsOpen) {
      this.overlayRef.detach();
    } else {
      const positionStrategy = this.overlayPositionBuilder
        .global().top();

      this.overlayRef = this.overlay.create({
        positionStrategy,
        hasBackdrop: true,
        scrollStrategy: this.overlay.scrollStrategies.block(),
        panelClass: 'sb-cart-modal'
      });

      const templateCart = new TemplatePortal(this.header, this.viewContainerRef);
      this.overlayRef.attach(templateCart);

      this.subscriptions.add(this.overlayRef.backdropClick().subscribe(() => {
        this.overlayRef.detach();
        this.modalIsOpen = false;
      }));
    }
    this.modalIsOpen = !this.modalIsOpen;
    this.cartIsOpen.emit(this.modalIsOpen);
  }

  get promoCSSClasses() {
    // tslint:disable-next-line:prefer-template
    return this.promos && this.promos.length ? 'show-ribbon ' +
      this.promos.map(promo => {
        if (promo.promoType === PROMO_TYPES.ADDON) {
          return `promo-${promo.promoType.toLowerCase()} promo-${promo.promoType.toLowerCase()}-${promo.type.toLowerCase()}`;
        } else {
          return `promo-${promo.promoType.toLowerCase()}`;
        }
      }).join(' ') : '';
  }

  get promoCSSRibbon() {
    return this.promos && this.promos.length ? this.offerPromoService.inferRibbonColor(this.promos) : '';
  }

  get promoAddon() {
    const promoAddon = this.promos && this.promos.length ? this.promos.find(promo => promo.promoType === PROMO_TYPES.ADDON) : null;
    if (promoAddon) {
      return promoAddon.type === 'GIGAX2' ? null : promoAddon;
    }
    return null;
  }

  get promoExclusiveDiscount() {
    const promoExclusiveDiscount = this.promos && this.promos.length ?
      this.promos.find(promo => promo.promoType === PROMO_TYPES.EXCLUSIVE_DISCOUNT) : null;
    if (promoExclusiveDiscount) {
      return this.offer.productId === 'PO_Very_010423_DO_AVM_GA';
    }
  }

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private offerPromoService: OfferPromoService,
    @Inject(WINDOW) private window: Window
  ) { }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { CustomSerializer } from './route.serializer';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('route', routerReducer),
    StoreRouterConnectingModule.forRoot({ stateKey: 'route', serializer: CustomSerializer }),
  ]
})
export class RouteFeatureStoreModule {}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import isEmpty from 'lodash/isEmpty';

import { ValidationError } from 'models';
import { ValidatorsService } from '../validators/validators.service';

@Injectable()
export class ErrorsService {

  constructor(
    private validatorsService: ValidatorsService,
    private translateService: TranslateService
  ) {}

  private transformValidationErrorToHTML(validationError: ValidationError): string {
    const registeredValidationErrorKey = this.validatorsService.registerValidationErrorAndGetKey(validationError);
    return this.translateService.instant(registeredValidationErrorKey);
  }

  public transformValidationErrorsToHTML(validationError: ValidationError[] = []): string {
    if (isEmpty(validationError)) {
      return '-';
    }

    const requireList = validationError.length > 1;

    let errorElements = requireList ? validationError.reduce(
      (prev, cur) => `${ prev }<li>${ this.transformValidationErrorToHTML(cur) }</li>`,
      ''
    ) : this.transformValidationErrorToHTML(validationError[0]);

    errorElements = requireList ? `<ul>${ errorElements }</ul>` : errorElements;

    return errorElements;
  }
}

import { createAction, props } from '@ngrx/store';

import { EntityKey, EntityParsed, EntityValue } from 'models';

export enum EntityActionTypes {
  StoreStaticEntity = '[Entity] StoreStaticEntity',
  StoreDynamicEntity = '[Entity] StoreDynamicEntity',
  StoreValue = '[Entity] StoreValue',
}

export const storeStaticEntity = createAction(
  EntityActionTypes.StoreStaticEntity,
  props<{ key: EntityKey, value: EntityValue }>()
);

export const storeDynamicEntity = createAction(
  EntityActionTypes.StoreDynamicEntity,
  props<{ key: EntityKey, value: EntityParsed }>()
);

export const storeValue = createAction(
  EntityActionTypes.StoreValue,
  props<{ key: EntityKey, value: EntityValue }>()
);

export type EntityActionsUnion = ReturnType<
  | typeof storeStaticEntity
  | typeof storeDynamicEntity
  | typeof storeValue
  >;


export enum AnalyticsEventTypes {
  BRAND_CLICK = 'click_passa_a_very',
  CHECKOUT = 'checkout',
  CLICK_CTA = 'eventCTA',
  ERROR = 'errore',
  CART_VIEW = 'cart_view',
  CONSENT_VIEW = 'consensi_mostra',
  CUSTOM_EVENT = 'customEvent',
  CUSTOM_PAGE = 'custom_page',
  EXPERIENCE_RATE = 'valuta_esperienza',
  NEW_PAGE = 'New-Page',
  PROMO_VIEW = 'promoView',
  SHIPPING_ADDRESS_EQUAL = 'indirizzo_spedizione_coincide',
  TRACK_TRANSACTION = 'trackTrans'
}

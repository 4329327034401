import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MacroStepId } from 'models';

import { ErrorComponent } from './components/error/error.component';
import { InitComponent } from './components/init/init.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { OldBrowserComponent } from './components/old-browser/old-browser.component';
import { WarningComponent } from './components/warning/warning.component';

const routes: Routes = [
  {
    path: `${ MacroStepId.Initialization }/:token`,
    component: InitComponent
  },
  {
    path: MacroStepId.Process,
    data: { animation: MacroStepId.Process },
    loadChildren: () => import('./modules/process/process.module').then(mod => mod.ProcessModule),
  },
  {
    path: MacroStepId.Success,
    data: { animation: MacroStepId.Success },
    loadChildren: () => import('./modules/success/success.module').then(mod => mod.SuccessModule),
  },
  {
    path: MacroStepId.Recognition,
    data: { animation: MacroStepId.Recognition },
    loadChildren: () => import('./modules/recognition/recognition.module').then(mod => mod.RecognitionModule),
  },
  {
    path: MacroStepId.Error,
    data: { animation: MacroStepId.Error },
    component: ErrorComponent,
  },
  {
    path: MacroStepId.Warning,
    component: WarningComponent,
  },
  {
    path: MacroStepId.OldBrowser,
    component: OldBrowserComponent
  },
  {
    path: MacroStepId.NotFound,
    data: { animation: MacroStepId.NotFound },
    component: NotFoundComponent,
  },
  {
    path: '',
    redirectTo: MacroStepId.Recognition,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: MacroStepId.NotFound
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

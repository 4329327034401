<div [ngClass]="{
  'position-cart': isInCart,
  'multiple-promos': promos && promos.length > 1
}">
  <ng-container *ngFor="let promo of promos">
    <div class="wt-text-base-l wt-margin-bottom-6 promo-box promo-banner promo-{{promo.promoType.toLowerCase()}} promo-{{promo.promoType.toLowerCase()}}-{{(promo.type || 'generic').toLowerCase()}}">
      <sb-box-info [additionalCSSClasses]="(isInCart ? 'cart-promo ' : '') + 'success-box-big promo-' + ((promo.promoType.toLowerCase()) + '-' + (promo.type || 'generic').toLowerCase())" [transparent]="isInCart" [iconType]="(promo.icon || 'promo-' + promo.promoType.toLowerCase())">
        <div class="promo-box-info">
          <div class="promo-box-title wt-text-bold-m" [innerHTML]="(promo.message || 'PROMO-' + promo.promoType.toUpperCase() + '.BANNER') | translate"></div>
        </div>
      </sb-box-info>
    </div>
  </ng-container>
</div>

import { Injectable } from '@angular/core';
import { NavigationExtras, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MacroStepId, StepId } from 'models';
import { RootState } from '../../store';
import { NavigationActions, NavigationSelectors } from '../../store/navigation-feature-store';

@Injectable()
export class NavigationStoreFacadeService {
  constructor(public store$: Store<RootState.RootState>) { }

  get navigation() {
    return {
      history$: ((): Observable<StepId[]> => this.store$.select(NavigationSelectors.selectHistory))(),
      currentStepId$: ((): Observable<StepId> => this.store$.select(NavigationSelectors.selectCurrentStepId))(),
      nextStepId$: ((): Observable<StepId> => this.store$.select(NavigationSelectors.selectNextStepId))(),
      previousStepId$: ((): Observable<StepId> => this.store$.select(NavigationSelectors.selectPreviousStepId))(),

      // Step helpers
      setCurrentMacroStepId: (macroStepId: MacroStepId) =>
        this.store$.dispatch(NavigationActions.setNavigationCurrentMacroStepId({ macroStepId })),
      setCurrentStepId: (stepId: StepId) =>
        this.store$.dispatch(NavigationActions.setNavigationCurrentStepId({ stepId })),
      setQueryParams: (queryParams: Params) =>
        this.store$.dispatch(NavigationActions.setQueryParams({ queryParams })),
      // Dumb navigation
      navigateTo: (commands: any[], extras?: NavigationExtras) =>
        this.store$.dispatch(NavigationActions.navigateTo({ commands, extras })),
      navigateToNext: () =>
        this.store$.dispatch(NavigationActions.navigateToNext()),
      navigateToPrevious: () =>
        this.store$.dispatch(NavigationActions.navigateToPrevious()),
      navigateToErrorPage: (textNote?: string) =>
        this.store$.dispatch(NavigationActions.navigateToErrorPage({ errorMessage: textNote })),
      navigateToWarningPage: (error: any) =>
          this.store$.dispatch(NavigationActions.navigateToWarningPage({ errorMessage: error })),

      // Smart navigation
      goNext: () => this.store$.dispatch(NavigationActions.goNext()),
      goPrevious: () => this.store$.dispatch(NavigationActions.goPrevious()),
      restoreStepFromStorage: (storage?: any) => this.store$.dispatch(NavigationActions.restoreStepFromStorage({ storage })),
      goCancel: () => this.store$.dispatch(NavigationActions.goCancel())
    };
  }
}

import {
  ShippingMethod
} from '../entity-dynamic.interface';
import { DynamicEntityKey } from '../entity.interface';

export enum ShippingMethodEnum {
  Shop = 'shop',
  Home = 'home'
}

export interface StepShippingValue {
  shippingMethod: ShippingMethod;
}

export interface ShippingMethodType {
  id: string;
  title: string;
}

export interface StepShippingMethodEntityData {
  [DynamicEntityKey.ShippingMethod]?: ShippingMethod;
}

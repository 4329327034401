export enum ActionsId {
  pdcPreview = 'PdcPreview'
}

export interface City {
  fullName: string;
  city: string;
  district: string;
  zipCode: string;
  region: string;
  availableDeliveries: string[];
}

export interface ResponseCities {
  cities: City[];
}

export interface ResponseBankInfo {
  holder: string;
  bankName: string;
}

export interface ResponseCouponInfo {
  code: string;
}

export interface ResponseMgmInfo {
  code: string;
}

export interface ResponsePrecontractInfo {
  filenameLink?: string;
  link?: string;
}

export interface ResponseCreditCardInfo {
  holder: string;
  isValid: boolean;
}

export interface MnpOtpInfo {
  validityMin: number;
  maxSendRetry: number;
  maxSendFrequency: string;
}
export interface ResponseOtpInfo {
  otpInfo: MnpOtpInfo;
  error: OtpError;
}
export interface OtpError {
  code: string;
  description: string;
}

export enum ActionId {
  bankInfo = 'checkIban',
  cities = 'findCities',
  creditCardInfo = 'checkCreditCard',
  encodeTaxCode = 'encodeTaxCode',
  getPrecontractLink = 'preContractInfo',
  getPaypalOrderId = 'getPaypalOrderId',
  getPaypalToken = 'getPaypalToken',
  getVrLink = 'getVrLink',
  insertMgmInfo = 'insertMgmInfo',
  readBarcode = 'barcodeDetection',
  verifyOperatorUpdate = 'verifyOperatorUpdate',
  checkCoupon = 'checkCoupon',
  requestOtp = 'requestMnpOtp',
  toggleAddon = 'toggleAddon',
}

export const AVAILABLE_COUNTRIES = [
  {
    name: 'AFGHANISTAN',
    id: 'Z200'
  },
  {
    name: 'ALBANIA',
    id: 'Z100'
  },
  {
    name: 'ALGERIA',
    id: 'Z301'
  },
  {
    name: 'ALTOVOLTA',
    id: 'Z354'
  },
  {
    name: 'ANDORRA',
    id: 'Z101'
  },
  {
    name: 'ANGOLA',
    id: 'Z302'
  },
  {
    name: 'ANGUILLA',
    id: 'Z529'
  },
  {
    name: 'ANTIGUA E BARBUDA',
    id: 'Z532'
  },
  {
    name: 'ANTILLE BRITANNICHE',
    id: 'Z500'
  },
  {
    name: 'ANTILLE OLANDESI',
    id: 'Z501'
  },
  {
    name: 'ARABIA MERIDIONALE FEDERAZIONE',
    id: 'Z201'
  },
  {
    name: 'ARABIA MERIDIONALE PROTETTORATO',
    id: 'Z202'
  },
  {
    name: 'ARABIA SAUDITA',
    id: 'Z203'
  },
  {
    name: 'ARGENTINA',
    id: 'Z600'
  },
  {
    name: 'ARMENIA',
    id: 'Z137'
  },
  {
    name: 'AUSTRALIA',
    id: 'Z700'
  },
  {
    name: 'AUSTRIA',
    id: 'Z102'
  },
  {
    name: 'AZERBAIGIAN',
    id: 'Z141'
  },
  {
    name: 'BAHAMA',
    id: 'Z502'
  },
  {
    name: 'BAHREIN',
    id: 'Z204'
  },
  {
    name: 'BANGLADESH',
    id: 'Z249'
  },
  {
    name: 'BARBADOS',
    id: 'Z522'
  },
  {
    name: 'BASUTOLAND-SUD AFRICA BRITANNICO',
    id: 'Z303'
  },
  {
    name: 'BECIUANIA-SUD AFRICA BRITANNICO',
    id: 'Z304'
  },
  {
    name: 'BELGIO',
    id: 'Z103'
  },
  {
    name: 'BELIZE',
    id: 'Z512'
  },
  {
    name: 'BENIN',
    id: 'Z314'
  },
  {
    name: 'BERMUDA',
    id: 'Z400'
  },
  {
    name: 'BHUTAN',
    id: 'Z205'
  },
  {
    name: 'BIELORUSSIA',
    id: 'Z139'
  },
  {
    name: 'BIRMANIA',
    id: 'Z206'
  },
  {
    name: 'BOLIVIA',
    id: 'Z601'
  },
  {
    name: 'BOPHUTHATSWANA',
    id: 'Z364'
  },
  {
    name: 'BOSNIA ERZEGOVINA',
    id: 'Z153'
  },
  {
    name: 'BOTSWANA',
    id: 'Z358'
  },
  {
    name: 'BRASILE',
    id: 'Z602'
  },
  {
    name: 'BRUNEI',
    id: 'Z207'
  },
  {
    name: 'BULGARIA',
    id: 'Z104'
  },
  {
    name: 'BURUNDI',
    id: 'Z305'
  },
  {
    name: 'CAMBOGIA',
    id: 'Z208'
  },
  {
    name: 'CAMERUN',
    id: 'Z306'
  },
  {
    name: 'CANADA',
    id: 'Z401'
  },
  {
    name: 'CAPO VERDE',
    id: 'Z307'
  },
  {
    name: 'CAROLINE',
    id: 'Z701'
  },
  {
    name: 'CAYMAN',
    id: 'Z530'
  },
  {
    name: 'CECOSLOVACCHIA',
    id: 'Z105'
  },
  {
    name: 'CHRISTMAS',
    id: 'Z702'
  },
  {
    name: 'CIAD',
    id: 'Z309'
  },
  {
    name: 'CILE',
    id: 'Z603'
  },
  {
    name: 'CINA',
    id: 'Z210'
  },
  {
    name: 'CIPRO',
    id: 'Z211'
  },
  {
    name: 'CISKEI',
    id: 'Z367'
  },
  {
    name: 'CITTA DEL VATICANO',
    id: 'Z106'
  },
  {
    name: 'COCOS',
    id: 'Z212'
  },
  {
    name: 'COLOMBIA',
    id: 'Z604'
  },
  {
    name: 'COMORE',
    id: 'Z310'
  },
  {
    name: 'CONGO',
    id: 'Z311'
  },
  {
    name: 'CONGO BELGA',
    id: 'Z370'
  },
  {
    name: 'COOK',
    id: 'Z703'
  },
  {
    name: 'COREA DEL NORD',
    id: 'Z214'
  },
  {
    name: 'COREA DEL SUD',
    id: 'Z213'
  },
  {
    name: 'COSTA D AVORIO',
    id: 'Z313'
  },
  {
    name: 'COSTA RICA',
    id: 'Z503'
  },
  {
    name: 'CROAZIA',
    id: 'Z149'
  },
  {
    name: 'CUBA',
    id: 'Z504'
  },
  {
    name: 'DANIMARCA',
    id: 'Z107'
  },
  {
    name: 'DIPENDENZE AUSTRALIANE',
    id: 'Z900'
  },
  {
    name: 'DIPENDENZE BRITANNICHE',
    id: 'Z901'
  },
  {
    name: 'DIPENDENZE CANADESI',
    id: 'Z800'
  },
  {
    name: 'DIPENDENZE FRANCESI',
    id: 'Z902'
  },
  {
    name: 'DIPENDENZE NEOZELANDESI',
    id: 'Z903'
  },
  {
    name: 'DIPENDENZE NORVEGESI ANTARTICHE',
    id: 'Z904'
  },
  {
    name: 'DIPENDENZE NORVEGESI ARTICHE',
    id: 'Z801'
  },
  {
    name: 'DIPENDENZE SOVIETICHE',
    id: 'Z802'
  },
  {
    name: 'DIPENDENZE STATUNITENSI',
    id: 'Z905'
  },
  {
    name: 'DIPENDENZE SUDAFRICANE',
    id: 'Z906'
  },
  {
    name: 'DOMINICA',
    id: 'Z526'
  },
  {
    name: 'DOMINICANA REPUBBLICA',
    id: 'Z505'
  },
  {
    name: 'ECUADOR',
    id: 'Z605'
  },
  {
    name: 'EGITTO',
    id: 'Z336'
  },
  {
    name: 'EL SALVADOR',
    id: 'Z506'
  },
  {
    name: 'EMIRATI ARABI UNITI',
    id: 'Z215'
  },
  {
    name: 'ERITREA',
    id: 'Z368'
  },
  {
    name: 'ESTONIA',
    id: 'Z144'
  },
  {
    name: 'ETIOPIA',
    id: 'Z315'
  },
  {
    name: 'FAER OER',
    id: 'Z108'
  },
  {
    name: 'FIGI',
    id: 'Z704'
  },
  {
    name: 'FILIPPINE',
    id: 'Z216'
  },
  {
    name: 'FINLANDIA',
    id: 'Z109'
  },
  {
    name: 'FRANCIA',
    id: 'Z110'
  },
  {
    name: 'GABON',
    id: 'Z316'
  },
  {
    name: 'GAMBIA',
    id: 'Z317'
  },
  {
    name: 'GAZA',
    id: 'Z218'
  },
  {
    name: 'GEORGIA',
    id: 'Z136'
  },
  {
    name: 'GERMANIA',
    id: 'Z111'
  },
  {
    name: 'GHANA',
    id: 'Z318'
  },
  {
    name: 'GIAMAICA',
    id: 'Z507'
  },
  {
    name: 'GIAPPONE',
    id: 'Z219'
  },
  {
    name: 'GIBILTERRA',
    id: 'Z113'
  },
  {
    name: 'GIBUTI',
    id: 'Z361'
  },
  {
    name: 'GILBERT E ELLICE',
    id: 'Z705'
  },
  {
    name: 'GIORDANIA',
    id: 'Z220'
  },
  {
    name: 'GRAN BRETAGNA',
    id: 'Z114'
  },
  {
    name: 'GRECIA',
    id: 'Z115'
  },
  {
    name: 'GRENADA',
    id: 'Z524'
  },
  {
    name: 'GROENLANDIA',
    id: 'Z402'
  },
  {
    name: 'GUADALUPA',
    id: 'Z508'
  },
  {
    name: 'GUAM',
    id: 'Z706'
  },
  {
    name: 'GUATEMALA',
    id: 'Z509'
  },
  {
    name: 'GUAYANA',
    id: 'Z606'
  },
  {
    name: 'GUAYANA FRANCESE',
    id: 'Z607'
  },
  {
    name: 'GUINEA',
    id: 'Z319'
  },
  {
    name: 'GUINEA BISSAU',
    id: 'Z320'
  },
  {
    name: 'GUINEA EQUATORIALE',
    id: 'Z321'
  },
  {
    name: 'HAITI',
    id: 'Z510'
  },
  {
    name: 'HONDURAS',
    id: 'Z511'
  },
  {
    name: 'HONG KONG',
    id: 'Z221'
  },
  {
    name: 'IFNI',
    id: 'Z323'
  },
  {
    name: 'INDIA',
    id: 'Z222'
  },
  {
    name: 'INDONESIA',
    id: 'Z223'
  },
  {
    name: 'IRAN',
    id: 'Z224'
  },
  {
    name: 'IRAQ',
    id: 'Z225'
  },
  {
    name: 'IRIAN OCCIDENTALE',
    id: 'Z707'
  },
  {
    name: 'IRLANDA',
    id: 'Z116'
  },
  {
    name: 'ISLANDA',
    id: 'Z117'
  },
  {
    name: 'ISOLE CILENE',
    id: 'Z721'
  },
  {
    name: 'ISOLE MAURIZIO',
    id: 'Z332'
  },
  {
    name: 'ISRAELE',
    id: 'Z226'
  },
  {
    name: 'ITALIA',
    id: 'I000'
  },
  {
    name: 'JUGOSLAVIA',
    id: 'Z118'
  },
  {
    name: 'KAZAKISTAN',
    id: 'Z152'
  },
  {
    name: 'KENYA',
    id: 'Z322'
  },
  {
    name: 'KIRGHIZISTAN',
    id: 'Z142'
  },
  {
    name: 'KIRIBATI',
    id: 'Z731'
  },
  {
    name: 'KOSOVO',
    id: 'Z160'
  },
  {
    name: 'KUWAIT',
    id: 'Z227'
  },
  {
    name: 'LA REUNION',
    id: 'Z324'
  },
  {
    name: 'LAOS',
    id: 'Z228'
  },
  {
    name: 'LESOTHO',
    id: 'Z359'
  },
  {
    name: 'LETTONIA',
    id: 'Z145'
  },
  {
    name: 'LIBANO',
    id: 'Z229'
  },
  {
    name: 'LIBERIA',
    id: 'Z325'
  },
  {
    name: 'LIBIA',
    id: 'Z326'
  },
  {
    name: 'LIECHTENSTEIN',
    id: 'Z119'
  },
  {
    name: 'LITUANIA',
    id: 'Z146'
  },
  {
    name: 'LUSSEMBURGO',
    id: 'Z120'
  },
  {
    name: 'MACAO',
    id: 'Z231'
  },
  {
    name: 'MACEDONIA',
    id: 'Z148'
  },
  {
    name: 'MACQUARIE',
    id: 'Z708'
  },
  {
    name: 'MADAGASCAR',
    id: 'Z327'
  },
  {
    name: 'MALAWI',
    id: 'Z328'
  },
  {
    name: 'MALAYSIA',
    id: 'Z230'
  },
  {
    name: 'MALDIVE',
    id: 'Z232'
  },
  {
    name: 'MALI',
    id: 'Z329'
  },
  {
    name: 'MALTA',
    id: 'Z121'
  },
  {
    name: 'MALVINE=FALKLAND',
    id: 'Z609'
  },
  {
    name: 'MAN',
    id: 'Z122'
  },
  {
    name: 'MARCUS',
    id: 'Z709'
  },
  {
    name: 'MARIANNE',
    id: 'Z710'
  },
  {
    name: 'MAROCCO',
    id: 'Z330'
  },
  {
    name: 'MARSHALL',
    id: 'Z711'
  },
  {
    name: 'MARTINICA',
    id: 'Z513'
  },
  {
    name: 'MAURITANIA',
    id: 'Z331'
  },
  {
    name: 'MAYOTTE',
    id: 'Z360'
  },
  {
    name: 'MESSICO',
    id: 'Z514'
  },
  {
    name: 'MICRONESIA',
    id: 'Z735'
  },
  {
    name: 'MIDWAY',
    id: 'Z712'
  },
  {
    name: 'MOLDAVIA',
    id: 'Z140'
  },
  {
    name: 'MONACO',
    id: 'Z123'
  },
  {
    name: 'MONGOLIA',
    id: 'Z233'
  },
  {
    name: 'MONTENEGRO',
    id: 'Z159'
  },
  {
    name: 'MONTSERRAT',
    id: 'Z531'
  },
  {
    name: 'MOZAMBICO',
    id: 'Z333'
  },
  {
    name: 'NAMIBIA',
    id: 'Z300'
  },
  {
    name: 'NAURU',
    id: 'Z713'
  },
  {
    name: 'NEPAL',
    id: 'Z234'
  },
  {
    name: 'NICARAGUA',
    id: 'Z515'
  },
  {
    name: 'NIGER',
    id: 'Z334'
  },
  {
    name: 'NIGERIA',
    id: 'Z335'
  },
  {
    name: 'NIUE=SAVAGE',
    id: 'Z714'
  },
  {
    name: 'NORFOLK (ISOLE E ISOLE DEL MAR DEI CORALLI)',
    id: 'Z715'
  },
  {
    name: 'NORMANNE',
    id: 'Z124'
  },
  {
    name: 'NORVEGIA',
    id: 'Z125'
  },
  {
    name: 'NUOVA CALEDONIA',
    id: 'Z716'
  },
  {
    name: 'NUOVA GUINEA',
    id: 'Z718'
  },
  {
    name: 'NUOVA ZELANDA',
    id: 'Z719'
  },
  {
    name: 'NUOVE EBRIDI',
    id: 'Z717'
  },
  {
    name: 'NYASALAND',
    id: 'Z369'
  },
  {
    name: 'OMAN',
    id: 'Z235'
  },
  {
    name: 'PAESI BASSI',
    id: 'Z126'
  },
  {
    name: 'PAKISTAN',
    id: 'Z236'
  },
  {
    name: 'PALAU REPUBBLICA',
    id: 'Z734'
  },
  {
    name: 'PANAMA',
    id: 'Z516'
  },
  {
    name: 'PANAMA ZONA DEL CANALE',
    id: 'Z517'
  },
  {
    name: 'PAPUA NUOVA GUINEA',
    id: 'Z730'
  },
  {
    name: 'PAPUASIA',
    id: 'Z720'
  },
  {
    name: 'PARAGUAY',
    id: 'Z610'
  },
  {
    name: 'PERU',
    id: 'Z611'
  },
  {
    name: 'PITCAIRN',
    id: 'Z722'
  },
  {
    name: 'POLINESIA FRANCESE',
    id: 'Z723'
  },
  {
    name: 'POLONIA',
    id: 'Z127'
  },
  {
    name: 'PORTOGALLO',
    id: 'Z128'
  },
  {
    name: 'PUERTO RICO',
    id: 'Z518'
  },
  {
    name: 'QATAR',
    id: 'Z237'
  },
  {
    name: 'REPUBBLICA CECA',
    id: 'Z156'
  },
  {
    name: 'REPUBBLICA CENTRAFRICANA',
    id: 'Z308'
  },
  {
    name: 'REPUBBLICA SUDAFRICANA',
    id: 'Z347'
  },
  {
    name: 'ROMANIA',
    id: 'Z129'
  },
  {
    name: 'RUANDA',
    id: 'Z238'
  },
  {
    name: 'RUSSIA',
    id: 'Z154'
  },
  {
    name: 'SAHARA MERIDIONALE',
    id: 'Z362'
  },
  {
    name: 'SAHARA SETTENTRIONALE',
    id: 'Z363'
  },
  {
    name: 'SAHARA SPAGNOLO',
    id: 'Z339'
  },
  {
    name: 'SAINT KITTS E NEVIS=SAINT CHRISTOPHER E NEVIS',
    id: 'Z533'
  },
  {
    name: 'SAINT LUCIA',
    id: 'Z527'
  },
  {
    name: 'SAINT PIERRE ET MIQUELON',
    id: 'Z403'
  },
  {
    name: 'SAINT VINCENT E GRANADINE',
    id: 'Z528'
  },
  {
    name: 'SALOMONE',
    id: 'Z724'
  },
  {
    name: 'SAMOA',
    id: 'Z726'
  },
  {
    name: 'SAMOA AMERICANE',
    id: 'Z725'
  },
  {
    name: 'SAN MARINO',
    id: 'Z130'
  },
  {
    name: 'SANT\'ELENA',
    id: 'Z340'
  },
  {
    name: 'SAO TOME\' E PRINCIPE',
    id: 'Z341'
  },
  {
    name: 'SEICELLE',
    id: 'Z342'
  },
  {
    name: 'SENEGAL',
    id: 'Z343'
  },
  {
    name: 'SERBIA',
    id: 'Z158'
  },
  {
    name: 'SERBIA E MONTENEGRO',
    id: 'Z157'
  },
  {
    name: 'SIERRA LEONE',
    id: 'Z344'
  },
  {
    name: 'SIKKIM',
    id: 'Z239'
  },
  {
    name: 'SINGAPORE',
    id: 'Z248'
  },
  {
    name: 'SIRIA',
    id: 'Z240'
  },
  {
    name: 'SLOVACCHIA',
    id: 'Z155'
  },
  {
    name: 'SLOVENIA',
    id: 'Z150'
  },
  {
    name: 'SOMALIA',
    id: 'Z345'
  },
  {
    name: 'SPAGNA',
    id: 'Z131'
  },
  {
    name: 'SRI LANKA',
    id: 'Z209'
  },
  {
    name: 'STATI UNITI D\'AMERICA',
    id: 'Z404'
  },
  {
    name: 'SUD SUDAN',
    id: 'Z907'
  },
  {
    name: 'SUDAN',
    id: 'Z348'
  },
  {
    name: 'SURINAME',
    id: 'Z608'
  },
  {
    name: 'SVEZIA',
    id: 'Z132'
  },
  {
    name: 'SVIZZERA',
    id: 'Z133'
  },
  {
    name: 'SWAZILAND',
    id: 'Z349'
  },
  {
    name: 'TAGIKISTAN',
    id: 'Z147'
  },
  {
    name: 'TAIWAN',
    id: 'Z217'
  },
  {
    name: 'TANGANICA',
    id: 'Z350'
  },
  {
    name: 'TANZANIA',
    id: 'Z357'
  },
  {
    name: 'TERRITORI PALESTINESI',
    id: 'Z161'
  },
  {
    name: 'THAILANDIA',
    id: 'Z241'
  },
  {
    name: 'TIMOR',
    id: 'Z242'
  },
  {
    name: 'TOGO',
    id: 'Z351'
  },
  {
    name: 'TOKELAU',
    id: 'Z727'
  },
  {
    name: 'TONGA',
    id: 'Z728'
  },
  {
    name: 'TRANSKEI',
    id: 'Z365'
  },
  {
    name: 'TRINIDAD E TOBAGO',
    id: 'Z612'
  },
  {
    name: 'TUNISIA',
    id: 'Z352'
  },
  {
    name: 'TURCHIA',
    id: 'Z243'
  },
  {
    name: 'TURKEMENISTAN',
    id: 'Z151'
  },
  {
    name: 'TURKMENISTAN',
    id: 'Z258'
  },
  {
    name: 'TURKS E CAICOS',
    id: 'Z519'
  },
  {
    name: 'TUVALU',
    id: 'Z732'
  },
  {
    name: 'UCRAINA',
    id: 'Z138'
  },
  {
    name: 'UGANDA',
    id: 'Z353'
  },
  {
    name: 'UNGHERIA',
    id: 'Z134'
  },
  {
    name: 'URSS',
    id: 'Z135'
  },
  {
    name: 'URUGUAY',
    id: 'Z613'
  },
  {
    name: 'UZBEKISTAN',
    id: 'Z143'
  },
  {
    name: 'VANUATU',
    id: 'Z733'
  },
  {
    name: 'VENDA',
    id: 'Z366'
  },
  {
    name: 'VENEZUELA',
    id: 'Z614'
  },
  {
    name: 'VERGINI BRITANNICHE',
    id: 'Z520'
  },
  {
    name: 'VIETNAM',
    id: 'Z244'
  },
  {
    name: 'WALLIS E FUTUNA',
    id: 'Z729'
  },
  {
    name: 'YEMEN',
    id: 'Z246'
  },
  {
    name: 'YEMEN REPUBBLICA DEMOCRATICA POPOLARE',
    id: 'Z250'
  },
  {
    name: 'ZAIRE',
    id: 'Z312'
  },
  {
    name: 'ZAMBIA',
    id: 'Z355'
  },
  {
    name: 'ZANZIBAR',
    id: 'Z356'
  },
  {
    name: 'ZIMBABWE',
    id: 'Z337'
  }
];

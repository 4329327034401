import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MacroStepId, StepId } from 'models';
import { NavigationState } from './navigation.state';

const selectNavigationState = createFeatureSelector<NavigationState>('navigation');

export const selectHistory = createSelector(
  selectNavigationState,
  (state: NavigationState): StepId[] => state.history
);

export const selectCurrentStepId = createSelector(
  selectNavigationState,
  (state: NavigationState): StepId => state.currentStepId
);

export const selectCurrentMacroStepId = createSelector(
  selectNavigationState,
  (state: NavigationState): MacroStepId => state.currentMacroStepId
);

export const selectNextStepId = createSelector(
  selectNavigationState,
  (state: NavigationState): StepId => state.nextStepId
);
export const selectPreviousStepId = createSelector(
  selectNavigationState,
  (state: NavigationState): StepId => state.previousStepId
);

import {
  PaymentMethod,
  PaymentMethodConstraint
} from '../entity-dynamic.interface';
import { DynamicEntityKey } from '../entity.interface';
import { ImageInterface } from '../image.interface';

export enum PaymentMethodEnum {
  CreditCard = 'credit-card',
  PayPal = 'paypal',
  NoVSC = 'novsc',
  PayByLink = 'pay-by-link',
  Mock = 'mock'
}

export interface PaymentMethodType {
  id: string;
  title: string;
  images?: ImageInterface[];
}

export interface StepPaymentMethodValue {
  paymentMethod: PaymentMethod;
}

export interface StepPaymentMethodConstraint {
  paymentMethod: PaymentMethodConstraint;
}

export interface StepPaymentMethodEntityData {
  [DynamicEntityKey.PaymentMethod]?: PaymentMethod;
}

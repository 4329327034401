import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { DynamicEntityKey, StepId } from 'models';
import { map, withLatestFrom } from 'rxjs/operators';
import { StepContactsEntityData, StepContactsValue } from '../../../../../app-shared/models/steps/step-contacts.interface';
import {StepSummaryValue} from '../../../../../app-shared/models/steps/step-summary.interface';
import { EntityStoreFacadeService } from '../../../../facades/entity-store-facade/entity-store-facade.service';
import { NavigationActions, NavigationHelpers } from '../../../navigation-feature-store';
import * as FlowActions from '../../actions/flow.actions';

@Injectable()
export class StepSummaryEffects {

  private filterByStep = NavigationHelpers.filterByStepFactory(this.entityStoreFacadeService.store$);


  goNext$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.goNext),
    this.filterByStep(StepId.Summary),
    withLatestFrom(this.entityStoreFacadeService.step.summaryValue$),
    map(([ , stepData ]: [ any, StepSummaryValue ]) => {
      // const { customerContacts, generalConsents } = stepData;
      // const dataPayload: StepContactsEntityData = {
      //   [DynamicEntityKey.CustomerContacts]: {
      //     ...customerContacts,
      //     email: (customerContacts.email || '').toLowerCase(),
      //     confirmEmail: (customerContacts.confirmEmail || '').toLowerCase()
      //   },
      //   [DynamicEntityKey.GeneralConsents]: generalConsents,
      //   // [DynamicEntityKey.MarketingConsents]: marketingConsents
      // };

      return FlowActions.postStepData({ stepId: StepId.Summary, data: {} });
    })
  ));

  restoreStepFromStorage$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.restoreStepFromStorage),
    this.filterByStep(StepId.Contacts),
    map(({ storage }: any) => {
      this.entityStoreFacadeService.entity.storeEntityValue(DynamicEntityKey.CustomerContacts, storage.customerContacts);
      this.entityStoreFacadeService.entity.storeEntityValue(DynamicEntityKey.SimType, storage.simType);
      this.entityStoreFacadeService.entity.storeEntityValue(DynamicEntityKey.GeneralConsents, storage.generalConsents);
      this.entityStoreFacadeService.entity.storeEntityValue(DynamicEntityKey.MarketingConsents, storage.marketingConsents);
      return null;
    }),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private entityStoreFacadeService: EntityStoreFacadeService
  ) {}
}

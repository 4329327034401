import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { isFuture, isPast, isToday, isWithinRange, subMilliseconds } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { INPUT_BASE_ERROR_PREFIX } from 'ui-kit-lib';

import { DocumentTypeId, EntityConstraint, EntityConstraintType, ValidationError } from 'models';
import { AppStoreFacadeService } from '../../../app/facades/app-store-facade.service';
import { DateDiffModes, DateDiffUnitOfMeasure, DatesService } from '../dates/dates.service';


@Injectable()
export class ValidatorsService {

  constructor(
    private appStoreFacadeService: AppStoreFacadeService,
    private translateService: TranslateService,
  ) { }


  private static TAXCODE_REGEX = /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/;
  private static TAXCODE_OMO_REGEX = /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9A-Za-z]{3}[A-Za-z]{1}$/;

  private static ITALIAN_ID_REGEX = /^([A-Za-z]{2}[0-9]{7})$|^((c|C)[A-Za-z]{1}[0-9]{5}[A-Za-z]{2})$|^([0-9]{7}[A-Za-z]{2})$/;
  // tslint:disable-next-line:max-line-length
  private static ITALIAN_DRIVING_LICENSE_REGEX = /^([A-Za-z]{2}[0-9]{7}[A-Za-z]{1})$|^((U1|u1)[A-Za-z0-9]{7}[A-Za-z]{1})$|^((U1|u1)[B-HJ-NPR-Zb-hj-npr-z]{1}[0-9]{6}[A-Za-z]{1})$/;
  private static ITALIAN_PASSPORT = /^([A-Za-z]{2}[0-9]{7})$/;

  private static checkValidEmail = (emailAddress) => {
    // tslint:disable-next-line:max-line-length
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(emailAddress);
  }

  public static parseValidationErrorMessage(validationErrorMessage: string): { key: string, value: string } {
    const tokens = validationErrorMessage.split('|');

    switch (tokens.length) {
      case 1: {
        console.warn('Unexpected validation message formatting', validationErrorMessage);
        const [value] = tokens;
        return { key: value, value };
      }
      case 2: {
        const [key, value] = tokens;
        return { key, value };
      }
      default: {
        console.warn('Wrong validation error message formatting', validationErrorMessage);
        return null;
      }
    }
  }

  public static address(key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = (control.value || '').trim();
      const invalid = value.match(/[^a-zA-ZÀ-ú0-9\s,’`\'\.\/\\-]/gi);
      return invalid ? { [key ? key : EntityConstraintType.Address]: { value: control.value } } : null;
    };
  }

  public static cidOrPec(key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = (control.value || '').trim();
      const CID_LENGTH = 7;
      const isValidEmail = !!(ValidatorsService.checkValidEmail((`${control.value}`).trim()));
      const isValidCid = value.length === CID_LENGTH && value.match(/^[a-zA-Z0-9]{7}$/gi);
      const invalid = !(isValidCid || isValidEmail);
      return invalid ? { [key ? key : EntityConstraintType.CidOrPec]: { value: control.value } } : null;
    };
  }

  private static documentValue(key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const group = control.parent as FormGroup;
      const value = control.value || '';
      const documentType = group.get('type').value;
      let invalid;
      switch (documentType) {
        case DocumentTypeId.IdentityCard:
          invalid = !ValidatorsService.ITALIAN_ID_REGEX.test(value);
          break;
        case DocumentTypeId.DrivingLicense:
          invalid = !ValidatorsService.ITALIAN_DRIVING_LICENSE_REGEX.test(value);
          break;
        case DocumentTypeId.Passport:
          invalid = !ValidatorsService.ITALIAN_PASSPORT.test(value);
          break;

        default:
          break;
      }

      return invalid ? { [key ? key : EntityConstraintType.DocumentValue]: { value: control.value } } : null;
    };
  }

  private static documentDate(key?: string): ValidatorFn {
    const DAY_IN_MS = 24 * 60 * 60 * 1000;
    const YEAR_IN_MS = 365 * DAY_IN_MS;
    const SAFE_MARGIN = 12 * DAY_IN_MS;
    // Original value for SAFE_MARGIN was: 45 for activation, 5 for delivery.
    // Current value is 12 for unspecified reasons

    return (control: AbstractControl): { [key: string]: any } | null => {
      const group = control.parent as FormGroup;

      const isInvalidFormat = (date: string) =>
        !date.match(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/);

      const dateValue = DatesService.fromUIToDate((control.value || '').trim());
      const documentType = group.get('type').value;
      let durationInMillis = 0;
      switch (documentType) {
        case DocumentTypeId.IdentityCard:
        case DocumentTypeId.Passport:
        case DocumentTypeId.ForeignPassport:
          durationInMillis = (YEAR_IN_MS * 12) + (3 * DAY_IN_MS) - SAFE_MARGIN; // Count leap years;
          break;
        default:
          durationInMillis = YEAR_IN_MS * 120;
          break;
      }
      const leftDateBoundary = subMilliseconds(DatesService.today(), durationInMillis);
      const rightDateBoundary = DatesService.today();
      const isWithinBoundaries = isWithinRange(dateValue, leftDateBoundary, rightDateBoundary);
      const invalid = isInvalidFormat(control.value || '') || !isWithinBoundaries;
      return invalid ? { [key ? key : EntityConstraintType.DocumentDate]: { value: control.value } } : null;
    };
  }

  public static email(key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const invalid = !(ValidatorsService.checkValidEmail((`${control.value}`).trim()));
      return invalid ? { [key ? key : EntityConstraintType.Email]: { value: control.value } } : null;
    };
  }

  public static iban(key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = (control.value || '').trim();
      const invalid = value.length !== 27 || !value.match(/^(IT[0-9]{2}[A-Z][0-9]{10}[A-Za-z0-9]{12})$/);
      return invalid ? { [key ? key : EntityConstraintType.Iban]: { value: control.value } } : null;
    };
  }

  public static iccidNoPrefix(key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = (control.value || '').trim();
      const PREFIX = 893988;
      const ICCID_LENGTH = 19 - PREFIX.toString().length;
      const invalid = value.length !== ICCID_LENGTH;
      const valid = !invalid;
      return !valid ? { [key ? key : EntityConstraintType.Iccid]: { value: control.value } } : null;
    };
  }

  public static iccid(key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = (control.value || '').trim();
      const ICCID_LENGTH = 19;
      const invalid = value.length !== ICCID_LENGTH || !value.startsWith('8939');
      // tslint:disable-next-line:max-line-length
      const timOperatorICCID = new RegExp(/893901\d{9}\s\d{2}\w|893901\d{9}\w{4}|8939010\d{9}\s\d{2}|8939010\d{9}\w{3}|893901000[1-9]\d{8}\w|89390100000\d{8}|893901000\d{10}/);
      const valid = !invalid || value.match(timOperatorICCID);
      return !valid ? { [key ? key : EntityConstraintType.Iccid]: { value: control.value } } : null;
    };

  }

  public static equalTo(isEqualToKey: string, key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const group = control.parent as FormGroup;
      if (group) {
        const otherControl = group.get(isEqualToKey);
        const transform = r => (r || '').toLowerCase();
        const isEmptyValue = !(control.value || '').trim();
        if (isEmptyValue) {
          return { [key ? key : EntityConstraintType.NotEmpty]: { value: control.value } };
        }
        const invalid =  (transform(otherControl.value) !== transform(control.value));
        return invalid ? { [key ? key : EntityConstraintType.EqualTo]: { value: control.value } } : null;
      }
      return null;
    };
  }

  public static isFuture(key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const dateValue = DatesService.fromUIToDate(control.value);
      const invalid = !isFuture(dateValue);
      return invalid ? { [key ? key : EntityConstraintType.Future]: { value: control.value } } : null;
    };
  }

  public static isFutureOrPresent(key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const dateValue = DatesService.fromUIToDate(control.value);
      const invalid = !(isFuture(dateValue) || isToday(dateValue));
      return invalid ? { [key ? key : EntityConstraintType.FutureOrPresent]: { value: control.value } } : null;
    };
  }

  public static isPast(key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const dateValue = DatesService.fromUIToDate(control.value);
      const invalid = !isPast(dateValue);
      return invalid ? { [key ? key : EntityConstraintType.Past]: { value: control.value } } : null;
    };
  }

  public static isPastOrPresent(durationInMillis?: number, key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const dateValue = DatesService.fromUIToDate(control.value);

      let isPastWithDuration = true;
      if (durationInMillis) {
        const leftDateBoundary = subMilliseconds(DatesService.today(), durationInMillis);
        const rightDateBoundary = DatesService.today();
        isPastWithDuration = isWithinRange(dateValue, leftDateBoundary, rightDateBoundary);
      }

      const invalid = !isPastWithDuration || !(isPast(dateValue) || isToday(dateValue));
      return invalid ? { [key ? key : EntityConstraintType.PastOrPresent]: { value: control.value } } : null;
    };
  }

  public static max(val, key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const invalid = !isNil((Validators.max(Number(val))(control)));
      return invalid ? { [key ? key : EntityConstraintType.Max]: { value: control.value } } : null;
    };
  }

  public static min(val, key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const invalid = !isNil((Validators.min(Number(val))(control)));
      return invalid ? { [key ? key : EntityConstraintType.Min]: { value: control.value } } : null;
    };
  }

  public static notBlank(key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = (control.value || '').trim();
      const invalid = value.length === 0;
      return invalid ? { [key ? key : EntityConstraintType.NotBlank]: { value: control.value } } : null;
    };
  }

  public static notEmpty(key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const invalid = isEmpty(control.value);
      return invalid ? { [key ? key : EntityConstraintType.NotEmpty]: { value: control.value } } : null;
    };
  }

  public static notFutureDate(key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const dateValue = DatesService.fromUIToDate(control.value);
      const invalid = isFuture(dateValue);
      return invalid ? { [key ? key : EntityConstraintType.NotFutureDate]: { value: control.value } } : null;
    };
  }

  public static notPastDate(mode: DateDiffModes, unit: DateDiffUnitOfMeasure, duration: number, key?: string): ValidatorFn {
    const VALUES_IN_MILLISECONDS = {
      DAY: 24 * 60 * 60 * 1000,
      MONTH: 30 * 24 * 60 * 60 * 1000,
      YEAR: 12 * 30 * 24 * 60 * 60 * 1000
    };

    return (control: AbstractControl): { [key: string]: any } | null => {
      const dateValue = DatesService.fromUIToDate((control.value || '').trim());
      let durationInMillis = 0;
      let isWithinBoundaries = true;
      switch (unit) {
        case DateDiffUnitOfMeasure.MILLISECONDS:
          durationInMillis = duration;
          break;
        case DateDiffUnitOfMeasure.DAYS:
          durationInMillis = duration * VALUES_IN_MILLISECONDS.DAY;
          break;
        case DateDiffUnitOfMeasure.MONTHS:
          durationInMillis = duration * VALUES_IN_MILLISECONDS.MONTH;
          break;
        case DateDiffUnitOfMeasure.YEARS:
          durationInMillis = duration * VALUES_IN_MILLISECONDS.YEAR;
          break;
      }
      if (durationInMillis) {
        const leftDateBoundary = subMilliseconds(DatesService.today(), durationInMillis);
        const rightDateBoundary = DatesService.today();
        isWithinBoundaries = isWithinRange(dateValue, leftDateBoundary, rightDateBoundary);
      }
      const invalid = durationInMillis ? !isWithinBoundaries : isPast(dateValue);
      return invalid ? { [key ? key : EntityConstraintType.NotPastDate]: { value: control.value } } : null;
    };
  }

  public static notNull(key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const invalid = isNil(control.value);
      return invalid ? { [key ? key : EntityConstraintType.NotNull]: { value: control.value } } : null;
    };
  }

  public static notStartsWith(value: string, key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const controlValue = control.value;

      const invalid = (controlValue || '').startsWith(value);
      return invalid ? { [key ? key : EntityConstraintType.NotStartsWith]: { value: control.value } } : null;
    };
  }

  public static requiredTrue(key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const invalid = !isNil((Validators.requiredTrue(control)));
      return invalid ? { [key ? key : EntityConstraintType.AssertTrue]: { value: control.value } } : null;
    };
  }

  public static size(min: number, max: number, key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valueLength = control.value && control.value.length;

      const invalid = !(valueLength >= min && valueLength <= max);
      return invalid ? { [key ? key : EntityConstraintType.Size]: { value: control.value } } : null;
    };
  }

  public static startsWith(value: string, key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const controlValue = control.value;

      const invalid = !(controlValue || '').startsWith(value);
      return invalid ? { [key ? key : EntityConstraintType.StartsWith]: { value: control.value } } : null;
    };
  }

  public static taxcode(key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value || '';

      const invalid = !(ValidatorsService.TAXCODE_REGEX.test(value) || ValidatorsService.TAXCODE_OMO_REGEX.test(value));
      return invalid ? { [key ? key : EntityConstraintType.Taxcode]: { value: control.value } } : null;
    };
  }

  public static textual(options: any = {}, key?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const controlValue = control.value || '';
      let invalid = false;

      // CASE 1: no options
      if (!(options.alphabetic || options.specialChars || options.digits || options.blank)) {
        // Make it alphabetic with space and apostrophe
        const alphabeticWithExtraChars = new RegExp(/^[a-zA-ZÀ-ú\s\'’`]+$/, 'gi');
        invalid = invalid || !controlValue.match(alphabeticWithExtraChars);
      } else {
        // CASE 2: at least on option is selected
        if (!options.alphabetic) {
          invalid = invalid || controlValue.match(/[A-Za-zÀ-ú]/gi);
        }
        if (!options.digits) {
          invalid = invalid || controlValue.match(/[0-9]/gi);
        }
        if (!options.blank) {
          invalid = invalid || controlValue.match(/[\s]/gi);
        }
        if (!options.specialChars) {
          invalid = invalid || controlValue.match(/[^\w\s]/gi);
        }
      }
      return invalid ? { [key ? key : EntityConstraintType.Textual]: { value: control.value } } : null;
    };
  }

  private transformConstraintToValidator(constraint: EntityConstraint): ValidatorFn {
    const { type, params, key } = constraint;

    if (key) {
      const labelKey = `${INPUT_BASE_ERROR_PREFIX}${key}`;
      const labelValue = constraint.params.message;

      if (labelValue) {
        this.appStoreFacadeService.translation.setTranslation(
          this.appStoreFacadeService.translation.getCurrentLang(),
          { [labelKey]: labelValue }
        );
      } else {
        delete constraint.key;
      }
    }

    const composedValidators = [];
    switch (type) {
      case EntityConstraintType.Address: {
        composedValidators.push(ValidatorsService.address(key));
        break;
      }
      case EntityConstraintType.AssertTrue: {
        composedValidators.push(ValidatorsService.requiredTrue(key));
        break;
      }
      case EntityConstraintType.CidOrPec: {
        composedValidators.push(ValidatorsService.cidOrPec(key));
        break;
      }
      case EntityConstraintType.DocumentDate: {
        composedValidators.push(ValidatorsService.documentDate(key));
        break;
      }
      case EntityConstraintType.DocumentValue: {
        composedValidators.push(ValidatorsService.documentValue(key));
        break;
      }
      case EntityConstraintType.Email: {
        composedValidators.push(ValidatorsService.email(key));
        break;
      }
      case EntityConstraintType.EqualTo: {
        composedValidators.push(ValidatorsService.equalTo(params.value, key));
        break;
      }
      case EntityConstraintType.Future: {
        composedValidators.push(ValidatorsService.isFuture(key));
        break;
      }
      case EntityConstraintType.FutureOrPresent: {
        composedValidators.push(ValidatorsService.isFutureOrPresent(key));
        break;
      }
      case EntityConstraintType.Iban: {
        composedValidators.push(ValidatorsService.iban(key));
        break;
      }
      case EntityConstraintType.Iccid: {
        composedValidators.push(ValidatorsService.iccid(key));
        break;
      }
      case EntityConstraintType.IccidNoPrefix: {
        composedValidators.push(ValidatorsService.iccidNoPrefix(key));
        break;
      }
      case EntityConstraintType.Max: {
        composedValidators.push(ValidatorsService.max(Number(params.value), key));
        break;
      }
      case EntityConstraintType.Min: {
        composedValidators.push(ValidatorsService.min(Number(params.value), key));
        break;
      }
      case EntityConstraintType.NotBlank: {
        composedValidators.push(ValidatorsService.notBlank(key));
        break;
      }
      case EntityConstraintType.NotEmpty: {
        composedValidators.push(ValidatorsService.notEmpty(key));
        break;
      }
      case EntityConstraintType.NotFutureDate: {
        composedValidators.push(ValidatorsService.notFutureDate(key));
        break;
      }
      case EntityConstraintType.NotPastDate: {
        const { mode, unit, value } = params;
        composedValidators.push(ValidatorsService.notPastDate(
          mode as DateDiffModes, unit as DateDiffUnitOfMeasure, value as unknown as number, key));
        break;
      }
      case EntityConstraintType.NotNull: {
        composedValidators.push(ValidatorsService.notNull(key));
        break;
      }
      case EntityConstraintType.NotStartsWith: {
        const { value } = params;
        composedValidators.push(ValidatorsService.notStartsWith(value, key));
        break;
      }
      case EntityConstraintType.Past: {
        composedValidators.push(ValidatorsService.isPast(key));
        break;
      }
      case EntityConstraintType.PastOrPresent: {
        const { duration } = params;
        composedValidators.push(ValidatorsService.isPastOrPresent(Number(duration), key));
        break;
      }
      case EntityConstraintType.Size: {
        const { min, max } = params;
        composedValidators.push(ValidatorsService.size(Number(min), Number(max), key));
        break;
      }
      case EntityConstraintType.StartsWith: {
        const { value } = params;
        composedValidators.push(ValidatorsService.startsWith(value, key));
        break;
      }
      case EntityConstraintType.Taxcode: {
        composedValidators.push(ValidatorsService.taxcode(key));
        break;
      }
      case EntityConstraintType.Textual: {
        composedValidators.push(ValidatorsService.textual(params, key));
        break;
      }
      default:
        // Use null validator for unknown types
        composedValidators.push(Validators.nullValidator);
    }

    return Validators.compose(composedValidators);
  }

  public transformConstraintsToValidators(constraints: EntityConstraint[]): ValidatorFn[] {
    return (constraints || []).map((contstraint) => this.transformConstraintToValidator(contstraint));
  }

  public registerValidationErrorAndGetKey(validationError: ValidationError): string {
    if (Object.values(EntityConstraintType).includes(validationError.code)) {
      // Validators are managed by their own and labels are already defined
      return validationError.code;
    }

    const parsedMessage = ValidatorsService.parseValidationErrorMessage(validationError.message);
    const labelKey = validationError.path ? `${INPUT_BASE_ERROR_PREFIX}${parsedMessage.key}` : parsedMessage.key;
    const labelValue = parsedMessage.value;

    const existingTranslation = this.translateService.instant(labelKey);
    if (existingTranslation === labelKey) {
      // No translation found, will add a new one
      this.appStoreFacadeService.translation.setTranslation(
        this.appStoreFacadeService.translation.getCurrentLang(),
        { [labelKey]: labelValue }
      );
    }

    return parsedMessage.key;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inferFreeLabel'
})
export class InferFreeLabelPipe implements PipeTransform {

  transform(value: any, price: any, plural?: boolean): any {
    if (price === 0) {
      return plural ? `ENTITY.CART.PRICE.LABEL.FREE_PLURAL` : `ENTITY.CART.PRICE.LABEL.FREE`;
    }
    return value;
  }

}

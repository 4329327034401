import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { PaymentPaypalConfiguration, PaypalConfiguration } from 'models';
import { AppStoreFacadeService } from '../../../app/facades/app-store-facade.service';
import { NavigationStoreFacadeService } from '../../../app/facades/navigation-store-facade/navigation-store-facade.service';
import { WINDOW } from '../../../app/providers/browser.provider';
import { ExecService } from '../exec/exec.service';

@Injectable({
  providedIn: 'root'
})
export class PaypalService {
  private scriptsAppended = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private appStoreFacadeService: AppStoreFacadeService,
    private execService: ExecService,
    private navigationStoreFacadeService: NavigationStoreFacadeService
  ) { }

  public appendScripts(paypalConfiguration?: PaypalConfiguration) {
    if (this.scriptsAppended) {
      console.warn('Paypal scripts already present');
      return;
    }

    const headScriptNode = document.createElement('script');
    headScriptNode.setAttribute('src', `https://www.paypal.com/sdk/js?client-id=${paypalConfiguration.clientId}&currency=EUR&locale=it_IT&intent=authorize`);
    headScriptNode.setAttribute('id', 'ppurl');
    this.document.head.appendChild(headScriptNode);
  }

  public switchPaypalUrlScript(smartTopUp: boolean, paypalConfiguration: PaypalConfiguration) {
    document.getElementById('ppurl').remove();
    const headScriptNode = document.createElement('script');
    if (smartTopUp) {
      headScriptNode.setAttribute('src', `https://www.paypal.com/sdk/js?client-id=${paypalConfiguration.clientId}&currency=EUR&locale=it_IT&intent=tokenize&vault=true`);
    } else {
      headScriptNode.setAttribute('src', `https://www.paypal.com/sdk/js?client-id=${paypalConfiguration.clientId}&currency=EUR&locale=it_IT&intent=authorize`);
    }
    headScriptNode.setAttribute('id', 'ppurl');
    this.document.head.appendChild(headScriptNode);
  }

  public createPaypalButtons(options: PaymentPaypalConfiguration, container?: string) {
    console.warn('you are in createPaypalButtons');
    const window = this.window as any;
    const appStoreFacadeService = this.appStoreFacadeService;
    const navigationStoreFacadeService = this.navigationStoreFacadeService;
    const execService = this.execService;

    const domElement = container || 'body';

    if (!(window.paypal && document.querySelectorAll(domElement).length)) {
      setTimeout(() => this.createPaypalButtons(options, container), 175);
      return;
    }

    window.paypal.Buttons({
      style: {
        layout:  'vertical',
        color:   'blue',
        shape:   'rect',
        label:   'pay'
      },
      createOrder: (data, actions) => {
        console.warn('TRUE PAYPAL');
        appStoreFacadeService.spinner.updateSpinnerStatus(true);
        return new Promise(resolve => {
          // execService.getPaypalOrderId()
          execService.getPaypalToken()
            .subscribe((resp: any) => {
              console.warn('resp createPaypalButtons ', resp);
              if (!(resp && resp.token)) {
                console.warn('FALSE PAYPAL');
                appStoreFacadeService.spinner.updateSpinnerStatus(false);
                navigationStoreFacadeService.navigation.setQueryParams({
                  textNote: 'STEP.PAYMENT-METHOD.PAYPAL_ERROR'
                });
                // OLD CODE appStoreFacadeService.navigation.navigateToErrorPage();
                return null;
              }
              return resolve(resp.token);
            });
        });
      },
      onCancel(data) {
        console.warn('FALSE CANCEL PAYPAL');
        appStoreFacadeService.spinner.updateSpinnerStatus(false);
        // Show a cancel page, or return to cart
        // data is { paypalOrderId }
        // console.log('CANCELED');
      },
      onError(err) {
        console.warn('FALSE ERROR PAYPAL');
        appStoreFacadeService.spinner.updateSpinnerStatus(false);
        // Show an error page here, when an error occurs
        // OLD CODE TODO: WHAT TO DO HERE? SHOW A MODAL?
        // console.log(err);
        // console.log(JSON.stringify(err));
        navigationStoreFacadeService.navigation.setQueryParams({
          textNote: 'STEP.PAYMENT-METHOD.PAYPAL_ERROR'
        });
        // OLD CODE appStoreFacadeService.navigation.navigateToErrorPage();
      },
      onApprove: (data, actions) => {
        console.warn('FALSE APPROVE PAYPAL');
        console.warn('check data', data);
        appStoreFacadeService.spinner.updateSpinnerStatus(false);
        // actions.order.get();
        // actions.order.get().then(function(details) {});
        // Capture the funds from the transaction
        appStoreFacadeService.step.storePaypalPaymentTransaction({
          orderId: data.orderID,
          payerId: data.payerID
        });
      }
    }).render(domElement);
  }

  public createPaypalSTUButtons(options: PaymentPaypalConfiguration, container?: string) {
    console.warn('you are in createPaypalSTUButtons');
    const window = this.window as any;
    const appStoreFacadeService = this.appStoreFacadeService;
    const navigationStoreFacadeService = this.navigationStoreFacadeService;
    const execService = this.execService;

    const domElement = container || 'body';

    if (!(window.paypal && document.querySelectorAll(domElement).length)) {
      setTimeout(() => this.createPaypalSTUButtons(options, container), 175);
      return;
    }

    window.paypal.Buttons({
      style: {
        layout:  'vertical',
        color:   'blue',
        shape:   'rect',
        label:   'pay'
      },
      createBillingAgreement: (data, actions) => {
        console.warn('TRUE PAYPAL');
        appStoreFacadeService.spinner.updateSpinnerStatus(true);
        return new Promise(resolve => {
          execService.getPaypalToken()
            .subscribe((resp: any) => {
              console.warn('resp createBillingAgreement ', resp);
              if (!(resp && resp.token)) {
                console.warn('FALSE PAYPAL');
                appStoreFacadeService.spinner.updateSpinnerStatus(false);
                navigationStoreFacadeService.navigation.setQueryParams({
                  textNote: 'STEP.PAYMENT-METHOD.PAYPAL_ERROR'
                });
                // OLD CODE appStoreFacadeService.navigation.navigateToErrorPage();
                return null;
              }
              return resolve(resp.token);
            });
        });
      },
      onCancel(data) {
        console.warn('FALSE CANCEL PAYPAL');
        appStoreFacadeService.spinner.updateSpinnerStatus(false);
        // Show a cancel page, or return to cart
        // data is { paypalOrderId }
        // console.log('CANCELED');
      },
      onError(err) {
        console.warn('FALSE ERROR PAYPAL');
        appStoreFacadeService.spinner.updateSpinnerStatus(false);
        // Show an error page here, when an error occurs
        // OLD CODE TODO: WHAT TO DO HERE? SHOW A MODAL?
        // console.log(err);
        // console.log(JSON.stringify(err));
        navigationStoreFacadeService.navigation.setQueryParams({
          textNote: 'STEP.PAYMENT-METHOD.PAYPAL_ERROR'
        });
        // OLD CODE appStoreFacadeService.navigation.navigateToErrorPage();
      },
      onApprove: (data, actions) => {
        console.warn('FALSE APPROVE PAYPAL');
        console.warn('check data billing agreement -> ', data);
        appStoreFacadeService.spinner.updateSpinnerStatus(false);
        // actions.order.get();
        // actions.order.get().then(function(details) {});
        // Capture the funds from the transaction
        // appStoreFacadeService.step.storePaypalBillingAgreement({
        //   billingToken: data.billingToken
        // });
        appStoreFacadeService.step.storePaypalPaymentTransaction({
          billingToken: data.billingToken,
          orderId: data.orderID,
          payerId: data.payerID
        });
      }
    }).render(domElement);
  }
}

import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class DataService {
    constructor() {
        this.valueSource = new BehaviorSubject(null);
        this.currentValue = this.valueSource.asObservable();
    }
    changeValue(newValue) {
        this.valueSource.next(newValue);
    }
}
DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });

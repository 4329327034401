import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { AuthService } from '../../../../app-shared/services/auth/auth.service';
import * as HeaderActions from '../actions/header.actions';


@Injectable()
export class HeaderEffects {

  setToken$ = createEffect(() => this.actions$.pipe(
    ofType(HeaderActions.setToken),
    tap(action => {
      this.authService.setToken(action.token);
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private authService: AuthService
  ) {}
}

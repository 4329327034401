import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { BasketEffects } from './effects/basket.effects';
import { FlowEffects } from './effects/flow.effects';
import { HeaderEffects } from './effects/header.effects';
import { ProcessEffects } from './effects/process.effects';
import { StepActivationOtpEffects } from './effects/steps/step-activation-otp.effects';
import { StepActivationEffects } from './effects/steps/step-activation.effects';
import { StepAddressEffects } from './effects/steps/step-address.effects';
import {StepConsentsEffects} from './effects/steps/step-consents.effects';
import { StepContactsEffects } from './effects/steps/step-contacts.effects';
import {StepCouponEffects} from './effects/steps/step-coupon.effects';
import { StepCustomerEffects } from './effects/steps/step-customer.effects';
import { StepDigitalOnboardingIccidEffects } from './effects/steps/step-digital-onboarding-iccid.effects';
import { StepDocumentEffects } from './effects/steps/step-document.effects';
import { StepOfferExtrasEffects } from './effects/steps/step-offer-extras.effects';
import { StepPaymentMethodEffects } from './effects/steps/step-payment-method.effects';
import { StepShippingMethodEffects } from './effects/steps/step-shipping-method.effects';
import { StepSimTypeEffects } from './effects/steps/step-sim-type.effects';
import {StepSummaryEffects} from './effects/steps/step-summary.effects';
import { StepWinbackEffects } from './effects/steps/step-winback.effects';
import { TranslationEffects } from './effects/translation.effects';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([
      BasketEffects,
      FlowEffects,
      HeaderEffects,
      ProcessEffects,
      StepActivationEffects,
      StepActivationOtpEffects,
      StepAddressEffects,
      StepContactsEffects,
      StepCustomerEffects,
      StepDocumentEffects,
      StepPaymentMethodEffects,
      StepShippingMethodEffects,
      StepSimTypeEffects,
      StepOfferExtrasEffects,
      StepSummaryEffects,
      StepDigitalOnboardingIccidEffects,
      StepWinbackEffects,
      StepConsentsEffects,
      StepCouponEffects,
      TranslationEffects
    ])
  ]
})
export class ProcessFeatureStoreModule {}

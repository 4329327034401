import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { DynamicEntityKey, FunnelInfo, StepId } from 'models';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  StepFDOIccidValue,
  StepFDOIccidValueEntityData,
} from '../../../../../app-shared/models/steps/step-digital-onboarding.interface';
import { EntityStoreFacadeService } from '../../../../facades/entity-store-facade/entity-store-facade.service';
import { NavigationActions, NavigationHelpers } from '../../../navigation-feature-store';
import * as FlowActions from '../../actions/flow.actions';

@Injectable()
export class StepDigitalOnboardingIccidEffects {

  private filterByStep = NavigationHelpers.filterByStepFactory(this.entityStoreFacadeService.store$);

  goNext$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.goNext),
    this.filterByStep(StepId.FDOIccid),
    withLatestFrom(this.entityStoreFacadeService.step.fdoIccidValue$),
    map(([ , stepData ]: [ any, StepFDOIccidValue ]) => {
      const { fdoIccid } = stepData;
      const dataPayload: StepFDOIccidValueEntityData = {
        [DynamicEntityKey.FdoIccid]: {
          ...fdoIccid,
        }
      };
      return FlowActions.postStepData({ stepId: StepId.FDOIccid, data: dataPayload });
    })
  ));



  restoreStepFromStorage$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.restoreStepFromStorage),
    this.filterByStep(StepId.FDOIccid),
    map(({ storage }: any) => {
      this.entityStoreFacadeService.entity.storeEntityValue(DynamicEntityKey.FdoIccid, storage.fdoIccid);
      return null;
    }),
  ), { dispatch: false });

  goPrevious$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.goPrevious),
    this.filterByStep(StepId.FDOIccid),
    withLatestFrom(
      this.entityStoreFacadeService.entity.funnelInfo$,
    ),
    map(([ , funnelInfo ]: [ any, FunnelInfo ]) => {
      const { returnUrl } = funnelInfo;
      return NavigationActions.navigateTo({ commands: [ returnUrl ], extras: null, external: true });
    })
  ));

  constructor(
    private actions$: Actions,
    private entityStoreFacadeService: EntityStoreFacadeService
  ) {}
}


export interface Price {
  value: number;
  frequency: Frequency;
  currency: string;
  previous?: number;
  repetitions?: number;
  description?: string;
}

export enum Frequency {
  None = '',
  Monthly = 'monthly',
  UnaTantum = 'una-tantum',
  FirstTimeOnly = 'first-time-only'
}

export enum TotalPriceEnum {
  Total = 'total',
  Offer = 'offer',
  SimActivation = 'simActivation',
  Shipping = 'shipping',
  Sim = 'sim',
  Activation = 'activation',
  Addon = 'addon',
}

export interface CartAnalysis {
  activationMechanism: CartAnalysisActivationMethodsEnum;
  simPrice: Price;
  operatorsByPrice: any;
}

export enum CartAnalysisActivationMethodsEnum {
  Mnp = 'Mnp',
  New = 'New',
  Both = 'Both'
}


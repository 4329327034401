import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inferUnlimitedLabel'
})
export class InferUnlimitedLabelPipe implements PipeTransform {
  transform(gb, minutes, sms): any {

    let html = '';
    let unlimited;
    let sameValue;
    const getInfoBoxHTML = (mainInfo, secondaryInfo) => `<div class="sb-cart-partial-offer-info__box">
        <div class=" sb-cart-partial-offer-info__main-info">${mainInfo}</div>
        <div class=" sb-cart-partial-offer-info__secondary-info">${secondaryInfo}</div>
        </div><div class="separator"></div>`;

    const LABELS = {
      GB: 'Giga',
      MINUTES: 'Minuti',
      SMS: 'SMS',
      MINUTES_SMS: 'Minuti e SMS',
      UNLIMITED: 'illimitati'
    };

    if (gb != null) {
      if (gb > 0) {
        html += getInfoBoxHTML(gb, LABELS.GB);
      } else if (gb === 0) {
        html += getInfoBoxHTML(LABELS.UNLIMITED, LABELS.GB);
      }
    }

    if (minutes != null) {
      if (minutes > 0) {
        if (minutes === sms) {
          sameValue = true;
          html += getInfoBoxHTML(minutes, LABELS.MINUTES_SMS);
        } else {
          html += getInfoBoxHTML(minutes, LABELS.MINUTES);
        }
      } else if (minutes === 0 && sms === 0) {
        unlimited = true;
        html += getInfoBoxHTML(LABELS.UNLIMITED, LABELS.MINUTES_SMS);
      } else if (minutes === 0 && !unlimited) {
        html += getInfoBoxHTML(LABELS.UNLIMITED, LABELS.MINUTES);
      }
    }

    if (sms != null) {
      if (sms > 0 && !sameValue) {
        html += getInfoBoxHTML(sms, LABELS.SMS);
      } else if (minutes === 0 && sms === 0 && !unlimited) {
        html += getInfoBoxHTML(LABELS.UNLIMITED, LABELS.MINUTES_SMS);
      } else if (sms === 0 && !unlimited) {
        html += getInfoBoxHTML(LABELS.UNLIMITED, LABELS.SMS);
      }
    }
    return html;
  }

}

import {
  ActivationMethod, ActivationMethodConstraint,
  ActivationMnpInfo, ActivationMnpInfoConstraint
} from '../entity-dynamic.interface';
import { DynamicEntityKey } from '../entity.interface';
import { ImageInterface } from '../image.interface';

export enum ActivationMethodEnum {
  Mnp = 'mnp',
  New = 'new'
}

export interface ActivationMethodType {
  method: string;
  title: string;
  images: ImageInterface[];
}

export interface StepActivationValue {
  activationMethod: ActivationMethod;
  activationMnpInfo: ActivationMnpInfo;
}

export interface StepActivationValueConstraint {
  activationMethod: ActivationMethodConstraint;
  activationMnpInfo: ActivationMnpInfoConstraint;
}

export interface StepActivationEntityData {
  [DynamicEntityKey.ActivationMethod]?: ActivationMethod;
  [DynamicEntityKey.ActivationMnpInfo]?: ActivationMnpInfo;
}

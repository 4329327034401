import { Injectable } from '@angular/core';
import { endOfMonth, format, parse, startOfDay } from 'date-fns';

export enum DateDiffModes {
  ABSOLUTE = 'ABSOLUTE',
  RELATIVE = 'RELATIVE'
}

export enum DateDiffUnitOfMeasure {
  DAYS = 'DAYS',
  MILLISECONDS = 'MILLISECONDS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS'
}


@Injectable()
export class DatesService {
  public static DATE_ISO_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss[Z]';
  public static DATE_UI_FORMAT = 'DD/MM/YYYY';
  public static DATE_UI_CREDIT_CARD_FORMAT = 'MM/YY';
  public static DATE_UI_SPLITTER = '/';

  public static today(): Date {
    return startOfDay(new Date());
  }

  public static fromUIToDate(uiDate: string): Date {
    if (!uiDate) {
      return null;
    }

    const parts = uiDate.split(DatesService.DATE_UI_SPLITTER);

    if (parts.length === 2) {
      return DatesService.fromUICreditCardToDate(uiDate);
    }

    const [day, month, year] = parts;
    return startOfDay(new Date(Date.UTC(Number(year), (Number(month) - 1), Number(day))));
  }

  public static fromUICreditCardToDate(uiDate: string): Date {
    if (!uiDate) {
      return null;
    }

    const [month, year] = uiDate.split(DatesService.DATE_UI_SPLITTER);

    return endOfMonth(new Date(Date.UTC(Number(year.length === 2 ? `20${year}` : year), (Number(month) - 1), 1)));
  }

  public static fromDateToISO(date: Date): string {
    if (!date) {
      return null;
    }

    return format(date, DatesService.DATE_ISO_FORMAT);
  }

  public static fromDateToUI(date: Date): string {
    if (!date) {
      return null;
    }

    return format(date, DatesService.DATE_UI_FORMAT);
  }

  public static fromDateToUICreditCard(date: Date): string {
    if (!date) {
      return null;
    }

    return format(date, DatesService.DATE_UI_CREDIT_CARD_FORMAT);
  }

  public static fromISOToDate(isoDate: string): Date {
    if (!isoDate) {
      return null;
    }

    return startOfDay(parse(isoDate));
  }
}

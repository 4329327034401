import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { RootState } from '../store';
import { EntityStoreFacadeService } from './entity-store-facade/entity-store-facade.service';
import { NavigationStoreFacadeService } from './navigation-store-facade/navigation-store-facade.service';
import { ProcessFeatureStoreFacadeService } from './process-feature-store-facade/process-feature-store-facade.service';

@Injectable()
export class AppStoreFacadeService {
  constructor(
    public store$: Store<RootState.RootState>,
    public actions$: Actions,
    // OLD CODE TODO Discuss if it is worth to move child facades into feature-stores modules
    private businessStoreFacadeService: ProcessFeatureStoreFacadeService,
    private entityStoreFacadeService: EntityStoreFacadeService,
    private navigationStoreFacadeService: NavigationStoreFacadeService,
  ) { }

  get route() {
    return this.businessStoreFacadeService.route;
  }

  get header() {
    return this.businessStoreFacadeService.header;
  }

  get flow() {
    return this.businessStoreFacadeService.flow;
  }

  get navigation() {
    return this.navigationStoreFacadeService.navigation;
  }

  get entity() {
    return this.entityStoreFacadeService.entity;
  }

  get step() {
    return this.entityStoreFacadeService.step;
  }

  get cart() {
    return this.businessStoreFacadeService.cart;
  }

  get translation() {
    return this.businessStoreFacadeService.translation;
  }

  get spinner() {
    return this.businessStoreFacadeService.spinner;
  }

  get errors() {
    return this.businessStoreFacadeService.errors;
  }

  get modals() {
    return this.businessStoreFacadeService.modals;
  }
}

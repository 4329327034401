<div class="sb-box-info {{additionalCSSClasses}} {{transparent ? 'sb-box-info--transparent' : ''}} {{autowidth ? 'sb-box-info--autowidth' : ''}}">
  <div class="sb-box-info__image">
    <img alt="{{iconType}}" src="/assets/images/{{iconType.includes('.') ? iconType : iconType + '.svg'}}" class="sb-box-info__icon {{iconType}}">
  </div>
  <ng-container *ngIf="infoText">
    <div class="sb-box-info__text wt-text-base-l" [innerHTML]="infoText"></div>
  </ng-container>
  <ng-container *ngIf="!infoText">
    <ng-content></ng-content>
  </ng-container>
</div>

import { createFeatureSelector, createSelector } from '@ngrx/store';
const defaultRouterStateUrl = {
    url: null,
    params: null,
    queryParams: null
};
const selectRouterState = createFeatureSelector('route');
const ɵ0 = (state) => (state && state.state) || defaultRouterStateUrl;
const selectState = createSelector(selectRouterState, ɵ0);
const ɵ1 = (state) => state.params;
export const selectParams = createSelector(selectState, ɵ1);
const ɵ2 = (state) => state.queryParams;
export const selectQueryParams = createSelector(selectState, ɵ2);
const ɵ3 = (state) => state.url;
export const selectUrl = createSelector(selectState, ɵ3);
const ɵ4 = (state) => state.componentUrlPath;
export const selectComponentUrlPath = createSelector(selectState, ɵ4);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };

import { EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';

import { Address, EntityOptionsGroup } from 'models';


export const parseEntityKeyToPropertyName = (entityKey: string) =>
  entityKey
    .replace(/([-][a-z])/ig, ($1) => $1.toUpperCase().replace('-', ''))
    .replace(/(\[)/ig, '.')
    .replace(/(])/ig, '')
;

export const isNotEmpty = (data: any) => !isEmpty(data);

export const markFromGroupControlsAsTouchedAndDirty = (formGroup: FormGroup): void => {
  Object.values(formGroup.controls || {}).forEach(control => {
    control.markAsTouched();
    control.markAsDirty();

    // Emit fake status change to trigger input component change detection
    (control.statusChanges as EventEmitter<any>).emit(control.status);

    if ((control as FormGroup).controls) {
      markFromGroupControlsAsTouchedAndDirty(control as FormGroup);
    }
  });
};

/**
 * Filters object by removing nullable properties
 */
export const removeNullableProperties = (rawData: any): any => {
  let patchData = {};

  Object.keys(rawData || {}).forEach((dataItemKey: string) => {
    if (rawData[dataItemKey]) {
      patchData = {
        ...patchData,
        [dataItemKey]: rawData[dataItemKey]
      };
    }
  });

  return patchData;
};

export const enhanceFormGroupWithOptions = (formGroup: FormGroup, optionsGroup: EntityOptionsGroup): FormGroup => {
  Object.keys(optionsGroup || {}).forEach((controlKey: string) => {
    const formControl = formGroup.get(controlKey);

    if (formControl) {
      Object.keys(optionsGroup[controlKey] || {}).forEach((optionKey: string) => {
        switch (optionKey) {
          case 'disabled': {
            if (optionsGroup[controlKey][optionKey] === true) {
              formControl.disable();
            } else {
              formControl.enable();
            }
            break;
          }
          case 'IMPOSSIBLE_FOR_SONAR':
            formControl.disable();
            break;
          case 'IMPOSSIBLE_FOR_SONARCUBE':
            formControl.enable();
            break;
          default:
          // Ignore
        }
      });

    } else {
      console.warn(`Unknown ${controlKey} form control key`, formGroup);
    }
  });
  return formGroup;
};

export const inferDisabledFormGroupControlsKeys = (optionsGroup: EntityOptionsGroup): string[] => {
  const disabledFormControlsKeys = [];

  Object.keys(optionsGroup).forEach((entityKey) => {
    const entityValue = removeNullableProperties(optionsGroup[entityKey]);

    if (!isEmpty(entityValue)) {
      Object.keys(entityValue).forEach((entityPropertyKey) => {
        if (entityValue[entityPropertyKey].disabled) {
          disabledFormControlsKeys.push(`${entityKey}.${entityPropertyKey}`);
        }
      });
    }
  });

  return disabledFormControlsKeys;
};

export const isAddressEqualTo = (firstAddress: Address, secondAddress: Address) => {
  const secondAddressIsNull = !secondAddress || isEmpty(removeNullableProperties(secondAddress));
  return secondAddressIsNull || isEqual(firstAddress, secondAddress);
};

export const inferAddressValue = (address: Address) => isEmpty(removeNullableProperties(address)) ? null : address;

export const applyFunctionToFormGroupControls = (
  formGroup: FormGroup,
  functionToApply: (abstractControl) => void,
  formControlsKeys: string[] = []
): void => {
  formControlsKeys.forEach(formControlsKey => {
    const formControl = formGroup.get(formControlsKey);

    if (formControl) {
      functionToApply(formControl);
    }
  });
};

export const enableAlwaysDisabledFormControls = (form: FormGroup, disabledFormControlsKeys: string[]): void => {
  applyFunctionToFormGroupControls(
    form,
    (formControl: FormControl) => formControl.enable(),
    disabledFormControlsKeys
  );
};

export const getFormValueWithDisabledFields = (form: FormGroup, disabledFormControlsKeys: string[]): any => {
  const valueWithDisabled = form.value;

  disabledFormControlsKeys.forEach((key) => {
    const valueOfDisabled = form.get(key).value;
    set(valueWithDisabled, key, valueOfDisabled);
  });

  return valueWithDisabled;
};

export interface FormErrorInterface {
  field: string;
  description: string;
}

export interface FormGroupControls {
  [key: string]: AbstractControl;
}

export function getFormValidationErrors(controls: FormGroupControls): FormErrorInterface[] {
  let errors: FormErrorInterface[] = [];
  Object.keys(controls).forEach(key => {
    const control = controls[ key ];
    if (control instanceof FormGroup) {
      errors = errors.concat(getFormValidationErrors(control.controls));
    }
    const controlErrors: ValidationErrors = controls[ key ].errors;
    if (controlErrors !== null) {
      Object.keys(controlErrors).forEach(keyError => {
        errors.push({
          field: key,
          description: keyError
        });
      });
    }
  });
  return errors;
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./old-browser.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../modules/shared/components/header/header.component.ngfactory";
import * as i3 from "../../modules/shared/components/header/header.component";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "../../../app-shared/services/offer-promo/offer-promo.service";
import * as i6 from "../../providers/browser.provider";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./old-browser.component";
var styles_OldBrowserComponent = [i0.styles];
var RenderType_OldBrowserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OldBrowserComponent, data: {} });
export { RenderType_OldBrowserComponent as RenderType_OldBrowserComponent };
export function View_OldBrowserComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "error-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "sb-header", [["title", ""]], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 180224, null, 0, i3.HeaderComponent, [i4.Overlay, i1.ViewContainerRef, i4.OverlayPositionBuilder, i5.OfferPromoService, i6.WINDOW], { showCart: [0, "showCart"], title: [1, "title"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "error-page__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "error-page__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "error-page__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "error-page__subtitle"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "error-page__content--with-icons"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = false; var currVal_1 = ""; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("STEP.OLD-BROWSER.TITLE")); _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("STEP.OLD-BROWSER.SUBTITLE")); _ck(_v, 8, 0, currVal_3); }); }
export function View_OldBrowserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sb-old-browser", [], null, null, null, View_OldBrowserComponent_0, RenderType_OldBrowserComponent)), i1.ɵdid(1, 49152, null, 0, i8.OldBrowserComponent, [], null, null)], null, null); }
var OldBrowserComponentNgFactory = i1.ɵccf("sb-old-browser", i8.OldBrowserComponent, View_OldBrowserComponent_Host_0, {}, {}, []);
export { OldBrowserComponentNgFactory as OldBrowserComponentNgFactory };

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DynamicEntityKey, StaticEntityKey } from 'models';
export const selectEntityState = createFeatureSelector('entity');
const ɵ0 = (state) => state.static;
// Entity selectors cache
const selectStaticEntities = createSelector(selectEntityState, ɵ0);
const ɵ1 = (state) => state.dynamic;
const selectDynamicEntities = createSelector(selectEntityState, ɵ1);
const createDynamicEntitySelector = (key) => {
    return createSelector(selectDynamicEntities, (dynamicEntities) => dynamicEntities[key]);
};
const ɵ2 = createDynamicEntitySelector;
const createStaticEntitySelector = (key) => {
    return createSelector(selectStaticEntities, (staticEntities) => staticEntities[key]);
};
const ɵ3 = createStaticEntitySelector;
const entitySelectors = {};
export const getEntitySelectorByKey = (key) => {
    if (!entitySelectors[key]) {
        if (Object.values(StaticEntityKey).includes(key)) {
            entitySelectors[key] = createStaticEntitySelector(key);
        }
        else if (Object.values(DynamicEntityKey).includes(key)) {
            entitySelectors[key] = createDynamicEntitySelector(key);
        }
        else {
            console.warn('Unknown entity', key);
        }
    }
    return entitySelectors[key];
};
export { ɵ0, ɵ1, ɵ2, ɵ3 };

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import {
  ActionId,
  ActionsId,
  City,
  ResponseBankInfo,
  ResponseCities, ResponseCouponInfo,
  ResponseCreditCardInfo, ResponseMgmInfo, ResponseOtpInfo, ResponsePrecontractInfo, TestOfferConfiguration
} from 'models';
import {AppStoreFacadeService} from '../../../app/facades/app-store-facade.service';
import { BarcodeValue, RecognitionUrl } from '../../../app/modules/recognition/models/recognition.interface';
import {DatesService} from '../dates/dates.service';
import { EnvironmentService } from '../environment/environment.service';

@Injectable()
export class ExecService {
  private readonly apiEndpoint: string;

  constructor(
    private http: HttpClient,
    private appStoreFacadeService: AppStoreFacadeService,
    private environmentService: EnvironmentService,
    private translateService: TranslateService
  ) {
    const apiConfiguration = this.environmentService.getApiConfiguration();
    this.apiEndpoint = `${ apiConfiguration.host }${
      apiConfiguration.endpoint.exec
    }`;
  }

  private static parseCitiesResponse = (response: ResponseCities): City[] => ((response && response.cities) || []);

  public getCityNamesFromZipCode(zipCode: string): Observable<City[]> {
    const queryData = { zipCode };
    return this.getActionData(queryData, ActionId.cities).pipe(map(ExecService.parseCitiesResponse));
  }

  public verifyOperatorUpdate(operatorId: string): Observable<any> {
    const queryData = { operatorId };
    return this.getActionData(queryData, ActionId.verifyOperatorUpdate);
  }

  public getCitiesFromCityName(cityName: string): Observable<City[]> {
    const queryData = { city: cityName, discriminator: 'city' };

    return this.http
      .post<any>(this.environmentService.getCitiesListUrl(), queryData)
      .pipe(map(ExecService.parseCitiesResponse));
    // .pipe(catchError(() => of(null)));
    // return this.getActionData(queryData, ActionId.cities).pipe(map(ExecService.parseCitiesResponse));
  }

  public getBankInfoFromIban(iban: string): Observable<ResponseBankInfo> {
    const queryData = { iban };
    return this.getActionData(queryData, ActionId.bankInfo);
  }

  public getPrecontractInfo(productId: string): Observable<ResponsePrecontractInfo> {
    const queryData = {
      productId,
      ropz: productId
    };

    function getWithPorkaround(): Observable<ResponsePrecontractInfo> {
      const pork = {
        PO_Very_160220_GA: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_DEA_Test_GA: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_DEA_Test_MVNO_B: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_160220_MNP1: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_160220_MNP2: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_VeryDB_080320_GA: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_2.49.pdf',
        PO_VeryDB_080320_MNP: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_2.49.pdf',
        PO_VeryDB_060720_GA: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_3.49.pdf',
        PO_VeryDB_060720_MNP: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_3.49.pdf',
        PO_Very_160320_GA: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_060720_GA: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_7.99.pdf',
        PO_Very_160320_MNP1: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_4.99.pdf',
        PO_Very_060720_MNP1: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_6.99.pdf',
        PO_Very_160320_MNP2: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_11.99.pdf',
        PO_Very_160320_MNP3: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_11.99.pdf',
        PO_Very_060720_MNP2: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_13.99.pdf',
        PO_Very_060720_MNP3: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_13.99.pdf',
        PO_Very_160320_MNP4: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_160320_MNPLocal: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_210920_MNP4Local: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_210920_MNP5Local: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_060720_MNP4: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_060720_MNP4_local: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_300320_GA: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_4.99___Nuovi_numeri.pdf',
        PO_Very_230420_MNP1: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_230420_MNP2: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_DEA_Test_Major: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_DEA_MNP_Minor_B: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_040221_A_PART_GA: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_040221_B_PART_GA: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_040221_A_PART_MNP1: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_040221_B_PART_MNP1: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_040221_A_PART_MNP2: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_040221_B_PART_MNP2: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_220221_A_MNP1: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_220221_B_MNP1: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_210121_MNP1: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_699_MNP.pdf',
        PO_Very_190421_MNP_WB: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_050521_MNP1: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_599_MNP.pdf',
        PO_Very_100521_MNP1: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_050521_GA: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_VeryDB_050521_GA: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_VeryDB_050521_MNP: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_210920_MNP2: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_130521_GA: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_130521_MNP1: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_170521_MNP: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_040621_F_MNP1: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_040621_F_MNP2: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_040621_F_MNP3: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_040621_A_GA: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_599_GA.pdf',
        PO_Very_040621_A_MNP2: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_1199_MNP.pdf',
        PO_Very_040621_A_MNP3: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_1199_MNP.pdf',
        PO_Very_040621_B_GA: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_799_nuovi_numeri.pdf',
        PO_Very_040621_B_MNP2: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_1399_MNP.pdf',
        PO_Very_040621_B_MNP3: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_1399_MNP.pdf',
        PO_VeryDB_040621_F_MNP1: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_VeryDB_170621_F_MNP_WB: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_010921_A_GA: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_010921_B_GA: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_DEA_TEST_100621_3_GA: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_DEA_Offer_Discount_B_MNP: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_DEA_MNP_Major: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_DEA_160220_MNP1: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_200921_S_GA: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_Special_799.pdf',
        PO_Very_200921_S_MNP1: 'https://verymobile.it/fileadmin/precontratti/public/verymobile/Sintesi_contrattuale_Very_Special_799.pdf',
        PO_Very_200921_S_MNP2: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_200921_S_MNP3: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_VeryDB_200921_S_GA: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_VeryDB_200921_S_MNP: 'https://verymobile.it/trasparenza-tariffaria/',
        PO_Very_200921_S_MNP_WB: 'https://verymobile.it/trasparenza-tariffaria/',
      };

      // Porkaround version
      return new Observable<ResponsePrecontractInfo>(observer => {
        observer.next({ filenameLink: (pork[productId] || null) });
        observer.complete();
      });
    }

    function getWithAction(): Observable<ResponsePrecontractInfo> {
      // get from localstorage
      try {
        const savedUrl = JSON.parse(localStorage.getItem(`precontract-${productId}`) || '{}');
        if (savedUrl.url && (((new Date()).getTime()) - savedUrl.timestamp) < (1000 * 60 * 60)) {
          return new Observable<ResponsePrecontractInfo>(observer => {
            observer.next({ filenameLink: savedUrl.url });
            observer.complete();
          });
        } else {
          localStorage.removeItem(`precontract-${productId}`);
        }
      } catch (ex) {
        if (typeof localStorage !== 'undefined') {
          localStorage.removeItem(`precontract-${productId}`);
        }
      }

      return this.getActionData(queryData, ActionId.getPrecontractLink).pipe(
        tap((resp: ResponsePrecontractInfo) => {
          console.warn(resp);
          if (resp && (resp.filenameLink || resp.link)) {
            try {
              localStorage.setItem(`precontract-${productId}`, JSON.stringify({
                timestamp: (new Date()).getTime(),
                url: resp.filenameLink || resp.link
              }));
            } catch (ex) {}
          }
        })
      );
    }

    function getWithDirectCall(): Observable<ResponsePrecontractInfo> {
      // get from localstorage
      try {
        const savedUrl = JSON.parse(localStorage.getItem(`precontract-${productId}`) || '{}');
        if (savedUrl.url && (((new Date()).getTime()) - savedUrl.timestamp) < (1000 * 60 * 60)) {
          return new Observable<ResponsePrecontractInfo>(observer => {
            observer.next({ filenameLink: savedUrl.url });
            observer.complete();
          });
        } else {
          localStorage.removeItem(`precontract-${productId}`);
        }
      } catch (ex) {
        if (typeof localStorage !== 'undefined') {
          localStorage.removeItem(`precontract-${productId}`);
        }
      }
      return new Observable<ResponsePrecontractInfo>(observer => {
        // @ts-ignore
        this.http.get<any>(`https://www.windtre.it/rest/api/precontratti/?canale=ecommerce&brand=verymobile&offerta=${productId}`)
          .pipe(
            tap((resp: any) => {
              const link = resp && resp.element && resp.element[0] && resp.element[0].detail && resp.element[0].detail.filename || '';
              if (link) {
                try {
                  localStorage.setItem(`precontract-${productId}`, JSON.stringify({
                    timestamp: (new Date()).getTime(),
                    url: resp.filenameLink
                  }));
                } catch (ex) {}
              }
            })
          ).subscribe(resp => {
          const link = resp && resp.element && resp.element[0] && resp.element[0].detail && resp.element[0].detail.filename || '';
          observer.next({ filenameLink: (link ? `https://www.windtre.it${link}` : null) });
          observer.complete();
        });
      });
    }

    // OLD CODE return getWithPorkaround.call(this);
    return getWithAction.call(this);
    // OLD CODE return getWithDirectCall.call(this);
  }

  public toggleAddon(addonId: string, toggle: boolean): Observable<any> {
    return this.getActionData({
      addonId,
      operation: toggle ? 'ADD' : 'REMOVE'
    }, ActionId.toggleAddon).pipe(catchError(() => of(null)));
  }

  public verifyActionError(res): boolean {
    // Centralized version to manage action error.
    if (res && res.error) {
      let errorMessage = res.error.split('|');
      let translationKey = null;
      if (errorMessage.length === 1) {
        errorMessage = errorMessage[0];
      } else {
        translationKey = errorMessage[0];
        errorMessage = errorMessage[1] || '';
      }
      // Wait for next cycle
      setTimeout(() => {
        if (translationKey) {
          const translatedKey = this.translateService.instant(translationKey);
          if (translatedKey === translationKey) {
            translationKey = null;
          }
        }
        this.appStoreFacadeService.errors.openErrorModal(translationKey || errorMessage);
      }, 0);
    }
    return res && res.error;
  }

  public setMgmInfo(code: string): Observable<ResponseMgmInfo> {
    const queryData = { code };
    return this.getActionData(queryData, ActionId.insertMgmInfo);
  }

  public checkCouponCode(code: string): Observable<ResponseCouponInfo> {
    const queryData = { code };
    return this.getActionData(queryData, ActionId.checkCoupon);
  }

  public getCreditCardInfoFromPan(pan: string): Observable<ResponseCreditCardInfo> {
    const queryData = { pan };
    return this.getActionData(queryData, ActionId.creditCardInfo);
  }

  private getActionData(queryData: any, listId: ActionId): Observable<any> {
    if (this.environmentService.isSingleActionEnabled()) {
      const params = new HttpParams().set('actionId', 'actionManager');
      params.set('identifier', listId);
      const newQueryData = {
        data: queryData,
        operationType: listId
      };
      return this.http
        .post<any>(this.apiEndpoint, newQueryData, { params })
        .pipe(catchError(() => of(null)));
    } else {
      const params = new HttpParams().set('actionId', listId);
      params.set('identifier', listId);
      return this.http
        .post<any>(this.apiEndpoint, queryData, { params })
        .pipe(catchError(() => of(null)));
    }
  }

  public getPaypalOrderId(): Observable<any> {
    return this.getActionData({}, ActionId.getPaypalOrderId).pipe(catchError(() => of(null)));
  }

  public getPaypalToken(): Observable<any> {
    return this.getActionData({}, ActionId.getPaypalToken).pipe(catchError(() => of(null)));
  }

  public getPdcPreview(): Observable<Blob> {
    const headers = new HttpHeaders().set('content-type', 'application/pdf');
    const params = new HttpParams().set('actionId', ActionsId.pdcPreview).set('content-type', 'application/pdf');
    const responseType = 'blob';

    return this.http
      .post(this.apiEndpoint, {}, { headers, params, responseType })
      .pipe(catchError(() => of(null)));
  }

  public getVrLink(iccid: string, taxCode: string): Observable<RecognitionUrl> {
    return this.getActionData({
      iccid,
      taxCode
    }, ActionId.getVrLink).pipe(catchError(() => of(null)));
  }

  public readBarcode(image: string): Observable<BarcodeValue> {
    return this.getActionData({
      image
    }, ActionId.readBarcode).pipe(catchError(() => of(null)));
  }

  public getNewFlow(productId: string, simType: string = '', options: any = {}, callback: any) {
    const offerConfig = this.environmentService.getTestOfferConfiguration() || ({} as unknown as TestOfferConfiguration);
    if (!productId || !offerConfig) {
      return;
    }
    fetch(offerConfig.url, {
      method: 'POST',
      body: JSON.stringify({
        Offer: Object.assign({}, {
          id: productId,
          simType: simType || 'standard',
          channel: 'ecommerce'
        }, options)
      }),
    }).then(res => res.json())
      .then(res => {
        if (callback) {
          callback(res);
        }
      });
  }

  public resetFlow(productId: string, simType: string = '', options: any = {}) {
    this.getNewFlow(productId, simType, options, (res) => {
      window.location.href = res.redirect;
    });
  }

  public encodeTaxCode(customerInfo: any): Observable<any> {
    const formattedValues = {
      ...customerInfo,
      birthDate: DatesService.fromDateToISO(DatesService.fromUIToDate(customerInfo.birthDate))
    };

    return this.http
      .post<any>(this.environmentService.getTaxCodeEncodeUrl(), formattedValues)
      .pipe(catchError(() => of(null)));

    /*
      return this.getActionData({
        customerInfo: formattedValues
      }, ActionId.encodeTaxCode).pipe(catchError(() => of(null)));
     */
  }

  public requestOtp(msisdn: string, operatorName: string): Observable<ResponseOtpInfo> {
    return this.getActionData({msisdn, operatorName}, ActionId.requestOtp).pipe(catchError(() => of(null)));
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BasketData, EntityKey, ValidationError } from 'models';
import { EnvironmentService } from '../environment/environment.service';

@Injectable()
export class BasketService {

  private readonly basketEndpoint: string;

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient
  ) {
    const apiConfiguration = this.environmentService.getApiConfiguration();
    this.basketEndpoint = `${ apiConfiguration.host }${ apiConfiguration.endpoint.basket }`;
  }

  getEntitiesData(entities: EntityKey[]): Observable<BasketData> {
    const options = { params: { entityIds: entities.join(',') } };
    return this.http.get<BasketData>(`${ this.basketEndpoint }/read`, options);
  }

  postEntitiesData(entitiesData: BasketData): Observable<void> {
    return this.http.post<void>(`${ this.basketEndpoint }/write`, entitiesData);
  }

  verifyEntitiesData(entitiesData: BasketData): Observable<(void | ValidationError[])> {
    return this.http.post<void | ValidationError[]>(`${ this.basketEndpoint }/verify`, entitiesData);
  }
}

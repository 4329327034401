import { Params } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RouterStateUrl } from './route.state';

const defaultRouterStateUrl = {
  url: null,
  params: null,
  queryParams: null
};

const selectRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('route');
const selectState = createSelector(
  selectRouterState,
  (state: RouterReducerState<RouterStateUrl>) => (state && state.state) || defaultRouterStateUrl
);

export const selectParams = createSelector(
  selectState,
  (state: RouterStateUrl): Params => state.params
);

export const selectQueryParams = createSelector(
  selectState,
  (state: RouterStateUrl): Params => state.queryParams
);

export const selectUrl = createSelector(
  selectState,
  (state: RouterStateUrl): string => state.url
);

export const selectComponentUrlPath = createSelector(
  selectState,
  (state: RouterStateUrl): string => state.componentUrlPath
);

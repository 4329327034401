import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DynamicEntityKey, EntityKey, StaticEntityKey } from 'models';
import { EntityState } from './entity.state';

export const selectEntityState = createFeatureSelector<EntityState>('entity');

// Entity selectors cache
const selectStaticEntities = createSelector(
  selectEntityState,
  (state: EntityState) => state.static
);
const selectDynamicEntities = createSelector(
  selectEntityState,
  (state: EntityState) => state.dynamic
);
const createDynamicEntitySelector = (key: DynamicEntityKey) => {
  return createSelector(
    selectDynamicEntities,
    (dynamicEntities) => dynamicEntities[key]
  );
};
const createStaticEntitySelector = (key: StaticEntityKey) => {
  return createSelector(
    selectStaticEntities,
    (staticEntities) => staticEntities[key]
  );
};

const entitySelectors = {};
export const getEntitySelectorByKey = (key: EntityKey) => {
  if (!entitySelectors[key]) {
    if (Object.values(StaticEntityKey).includes(key)) {
      entitySelectors[key] = createStaticEntitySelector(key as StaticEntityKey);
    } else if (Object.values(DynamicEntityKey).includes(key)) {
      entitySelectors[key] = createDynamicEntitySelector(key as DynamicEntityKey);
    } else {
      console.warn('Unknown entity', key);
    }
  }

  return entitySelectors[key];
};


import { Inject, Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { FormErrorInterface } from 'helpers';
import { SimTypeEnum } from '../../models/steps/step-sim.interface';

import {
  ActivationMethod,
  ActivationMnpInfo,
  Address,
  AnalyticsPayload,
  AnalyticsStepPayload,
  Cart, Contacts, Entity, FunnelInfo, InternalPromoInfo, MgmInfo,
  Offer, OfferExtras, OfferPromoInfo,
  PaymentMethod, PromoInfo,
  SimType,
  StepId,
  TotalPrice,
  TotalPriceEnum
} from 'models';
import { WINDOW } from '../../../app/providers/browser.provider';

import { RootState } from '../../../app/store/root.state';
import { EnvironmentService } from '../environment/environment.service';
import { OfferPromoService } from '../offer-promo/offer-promo.service';

import { AnalyticsEventTypes } from './analytics.helpers';

import { DOCUMENT } from '@angular/common';

@Injectable()
export class AnalyticsService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private environmentService: EnvironmentService,
    private offerPromoService: OfferPromoService,
    private translateService: TranslateService
  ) {
  }

  private scriptsAppended = false;

  private generateCartItemsPayload(state: RootState) {
    const offer = state.entity.static.offer as Offer;
    const offerAlternative = ((state.entity.static['offer-alternative'] || {}) as unknown as Offer);
    const hasDoublePO = ((state.entity.static['funnel-info'] || {}) as unknown as FunnelInfo).isStuOfferDoubleConfigEnabled || false;
    const stuIsActive = ((state.entity.dynamic['offer-extras'] || {value: {}}).value as unknown as OfferExtras).smartTopUp || false;
    const cart = state.entity.static.cart as Cart;
    const activationPrice = cart.totalPrices.find (t => t.type === TotalPriceEnum.SimActivation);
    const selectedOffer = hasDoublePO && stuIsActive ? offerAlternative : offer;

    if (!selectedOffer || !selectedOffer.price) {
      return [];
    }

    return [
      {
        id: selectedOffer.productId || selectedOffer.ropz,
        sub_price: this.convertNumberToAnalyticsValue(selectedOffer.price.value),
        name: selectedOffer.name,
        activation_price: activationPrice && activationPrice.price && this.convertNumberToAnalyticsValue(activationPrice.price.value)
      }
    ];
  }

  private generateOriginalProduct(stepId: StepId, state: RootState, forceStepId?: string) {
    let stepPayload = null;
    const offer = state.entity.static.offer as Offer;
    const offerAlternative = ((state.entity.static['offer-alternative'] || {}) as unknown as Offer);
    const hasDoublePO = ((state.entity.static['funnel-info'] || {}) as unknown as FunnelInfo).isStuOfferDoubleConfigEnabled || false;
    const stuIsActive = ((state.entity.dynamic['offer-extras'] || {value: {}}).value as unknown as OfferExtras).smartTopUp || false;
    const stepsArray = [StepId.FDOIccid, StepId.Contacts, StepId.Customer, StepId.Consents, StepId.Address];

    if (!hasDoublePO) {
      return null;
    }

    const isStepInArray = stepsArray.includes(stepId);
    const product = stuIsActive ? offerAlternative : offer;

    if (isStepInArray) {
      stepPayload = {
        original_product: product && product.price ? [
          {
            id: product.productId || product.ropz,
            name: product.name
          }
        ] : []
      };
      window.sessionStorage.setItem('original_product', JSON.stringify(stepPayload));
    } else {
      stepPayload = JSON.parse(window.sessionStorage.getItem('original_product') as string);
    }
    return stepPayload;
  }

  private convertNumberToAnalyticsValue =
    (n: number) => (n || 0).toFixed(2).toString().replace(',', '.')

  private inferPaymentMethod =
    (state: RootState): string =>
      ((state.entity.dynamic['payment-method'] || {value: {}}).value as unknown as PaymentMethod).method || undefined

  private inferSmartTopup =
    (state: RootState): any =>
      ((state.entity.dynamic['offer-extras'] || {value: {}}).value as unknown as OfferExtras).smartTopUp || false

  private inferAccessToken =
    (state: RootState): string =>
      ((state.entity.static['funnel-info'] || {}) as unknown as FunnelInfo).accessToken || undefined

  private inferAddressInfo =
    (state: RootState): any => {
      const deliveryAddress = (state.entity.dynamic['customer-delivery-address'] || {value: {}}).value as unknown as Address;
      return {
        shipping_city: deliveryAddress.city || undefined,
        shipping_province: deliveryAddress.district || undefined,
        shipping_co: deliveryAddress.at || undefined
      };
    }

  private inferEmail =
    (state: RootState): any => {
      const entity = (state &&
        state.entity &&
        state.entity.dynamic['customer-contacts'] || {value: {}}).value as unknown as Contacts;
      return entity.email || '';
    }

  private inferChannel = (state: RootState): string => {
    const channel = (state &&
      state.entity &&
      state.entity.static &&
      state.entity.static['funnel-info'] || {}).channel || '';
    return channel || 'ecommerce';
  }

  private inferActivationMethod =
    (state: RootState): string => {
      const availableActivationMethods = (state.entity.static['available-activation-methods'] || {methods: []}).methods || [];
      console.warn('availableActivationMethods ', availableActivationMethods);
      const selectedActivationMethod = ((state.entity.dynamic['activation-method'] ||
        {value: {}}).value as unknown as ActivationMethod).method || undefined;
      console.warn('selectedActivationMethod ', selectedActivationMethod);
      const activateMnpInfo = ((state.entity.dynamic['activation-mnp-info'] || {value: {}}).value as unknown as ActivationMnpInfo);
      if ((!selectedActivationMethod || typeof selectedActivationMethod === undefined) && availableActivationMethods.length === 1) {
        return availableActivationMethods[0];
      }
      return selectedActivationMethod;
    }

  private inferActivationMnpInfo =
    (state: RootState): ActivationMnpInfo =>
      ((state.entity.dynamic['activation-mnp-info'] || {value: {}}).value as unknown as ActivationMnpInfo)

  private inferRevenuePrice =
    (state: RootState): string =>
      this.convertNumberToAnalyticsValue((state.entity.static.cart as unknown as Cart).totalPrice.price.value || 0)

  private inferShippingPrice =
    (state: RootState): string => {
      const shippingPrice: TotalPrice =
        (state.entity.static.cart as unknown as Cart).totalPrices.find(p => p.type === TotalPriceEnum.Shipping) || null;
      return this.convertNumberToAnalyticsValue(shippingPrice ? shippingPrice.price.value : 0);
    }

  private inferMgmCode =
    (state: RootState): string => {
      const mgmCode = (((state.entity.dynamic['mgm-info']) || { value: {} })
          .value as unknown as MgmInfo).code || '';
      return mgmCode ? 'SI' : 'NO';
    }

  private inferFlowType =
    (state: RootState): string => {
      const simType: SimTypeEnum =
        ((((state.entity.dynamic['sim-type'] || {value: {}})
          .value as unknown as SimTypeEnum) || {}) as unknown as SimType).type;
      return (simType === SimTypeEnum.IdleSim) ? 'fulldigital' : simType;
    }

  private inferReportFlow =
    (state: RootState): string => {
      const simType: SimTypeEnum =
        ((((state.entity.dynamic['sim-type'] || {value: {}})
          .value as unknown as SimTypeEnum) || {}) as unknown as SimType).type;
      const backurl = ((state.entity.static['funnel-info'] || {}) as unknown as FunnelInfo).returnUrl || undefined;
      const reportflow = this.getFlowFromBackurl(backurl);
      if (reportflow) {
        if (this.window && this.window.localStorage) {
          this.window.localStorage.setItem('reportflow', reportflow);
        }
        return reportflow;
      } else if (this.window.localStorage.getItem('reportflow')) {
        return this.window.localStorage.getItem('reportflow');
      } else {
        return '';
      }
    }

  private inferPromoId = pr => {
    if (pr.type) {
      return `promo-${pr.promoType.toLowerCase()}-${pr.type.toLowerCase()}`;
    }
    return `promo-${pr.promoType.toLowerCase()}`;
  }

  private inferPromoDetails = pr => {
    const promoIcon = pr.icon || `promo-${this.inferPromoId(pr)}.svg`;

    return {
      id: this.inferPromoId(pr),
      creative: `assets/${promoIcon}`,
      position: '',
      name: this.translateService.instant(pr.message || `PROMO-${pr.promoType.toUpperCase()}.BANNER`)
    };
  }

  private inferPromoInfo =
    (state: RootState): any => {
      const promos = this.offerPromoService.elaborateOfferPromoInfo(
          (state.entity.static['offer-promo-info'] || { promos: [] }) as unknown as OfferPromoInfo,
          // tslint:disable-next-line:no-string-literal
          (state.entity.static['offer']) as unknown as Offer,
          (state.entity.dynamic['coupon-info']) as unknown as Entity
        );
      return {
        promo: promos.length ? 'SI' : 'NO',
        promo_type: promos.map(this.inferPromoId).join(','),
        promoDetails: promos.map(this.inferPromoDetails)
      };
    }

  private inferBooleanValueForAnalytics(value: boolean) {
    return !!value ? 'si' : 'no';
  }

  public sendToggleCartAnalytics(toggle: boolean, state: RootState) {
    this.pushData({
      event: `${AnalyticsEventTypes.CART_VIEW}_${this.inferBooleanValueForAnalytics(toggle)}`,
      ...this.inferPromoInfo(state)
    });
  }

  public sendToggleConsentsAnalytics(toggle: boolean) {
    this.pushData({
      event: `${AnalyticsEventTypes.CONSENT_VIEW}_${this.inferBooleanValueForAnalytics(toggle)}`
    });
  }

  public sendCheckSameAddressAnalytics(toggle: boolean) {
    this.pushData({
      event: `${AnalyticsEventTypes.SHIPPING_ADDRESS_EQUAL}_${this.inferBooleanValueForAnalytics(toggle)}`
    });
  }

  public sendBrandClick() {
    this.pushData({
      event: `${AnalyticsEventTypes.BRAND_CLICK}`
    });
  }

  public sendCustomPage(pageName: string, additionalOptions = {}) {
    this.pushData({
      event: `${AnalyticsEventTypes.CUSTOM_PAGE}`,
      page_name: pageName,
      ...additionalOptions
    });
  }

  public sendCustomEvent(additionalOptions = {}) {
    this.pushData({
      event: `${AnalyticsEventTypes.CUSTOM_EVENT}`,
      ...additionalOptions
    });
  }

  public sendExperienceRate(rate: string) {
    this.pushData({
      event: `${AnalyticsEventTypes.EXPERIENCE_RATE}`,
      vote: rate
    });
  }

  public sendGenericError(description?: string) {
    this.pushData({
      event: `${AnalyticsEventTypes.ERROR}`,
      error_type: 'generico',
      error_description: description || ''
    });
  }

  public sendFormError(errors: FormErrorInterface[]) {
    this.pushData({
      event: `${AnalyticsEventTypes.ERROR}`,
      error_type: 'form',
      error_description: errors.map(er => er.description.replace('wt-input-error-', '')),
      error_detail: errors.map(er => {
        return {
          field: er.field,
          description: er.description.replace('wt-input-error-', '')
        };
      })
    });
  }

  public sendOperatorError(operators?: string[]) {
    this.pushData({
      event: `${AnalyticsEventTypes.ERROR}`,
      error_type: 'errore provenienza',
      error_description: (operators || []).join(',')
    });
  }

  //  Payload GTM navigation step
  public generateAndSendStepPayload(stepId: StepId, state: RootState, forceStepId?: string): AnalyticsStepPayload {
    const eventName = (stepId === StepId.Success) ? AnalyticsEventTypes.TRACK_TRANSACTION : AnalyticsEventTypes.CHECKOUT;
    let stepPayload = null;
    switch (stepId) {
      // case StepId.Payment:
      // case StepId.Shipping:
      case StepId.Activation:
      case StepId.Customer:
      case StepId.Contacts:
      case StepId.Document:
      case StepId.Address:
      case StepId.ActivationOtp:
      case StepId.PaymentMethod:
      case StepId.FDOIccid:
      case StepId.Consents:
      case StepId.Winback:
      case StepId.OfferExtras:
      case StepId.Summary:
        stepPayload = {
          activation_type: this.inferActivationMethod(state)
        };
        break;
      case StepId.Success:
        stepPayload = {
          page_name: 'thankyou',
          codice_amico: this.inferMgmCode(state),
          flow_type: this.inferFlowType(state),
          activation_type: this.inferActivationMethod(state),
          report_flow: this.inferReportFlow(state),
          carrier_origin: this.inferActivationMnpInfo(state).operator === undefined ?
            window.localStorage.getItem('carrier-origin') : this.inferActivationMnpInfo(state).operator,
          purchase: {
            id: ((state.entity.static['order-summary'].orderId) ?
              (state.entity.static['order-summary'].orderId) :
              window.localStorage.getItem('order-id')),
            affiliation: 'Store online',
            revenue: this.inferRevenuePrice(state),
            tax: this.convertNumberToAnalyticsValue(0),
            shipping: this.inferShippingPrice(state)
          }
        };
        break;
      default:
        if (stepId) {
          console.warn(`Unknown step ${stepId}`);
        }
        return;
    }
    const { shipping_city, shipping_co, shipping_province } = this.inferAddressInfo(state);
    const channel = this.inferChannel(state);

    if (stepPayload) {
      this.pushData({
        event: eventName,
        page_name: forceStepId || stepId,
        flow_type: this.inferFlowType(state),
        report_flow: this.inferReportFlow(state),
        payment_method: this.inferPaymentMethod(state),
        ricarica_automatica: this.inferSmartTopup(state),
        ...this.generateOriginalProduct(stepId, state, forceStepId),
        products: this.generateCartItemsPayload(state),
        'gtm.accessToken': this.inferAccessToken(state),
        carrier_origin: this.inferActivationMnpInfo(state).operator === undefined ?
          window.localStorage.getItem('carrier-origin') : this.inferActivationMnpInfo(state).operator,
        ...this.inferPromoInfo(state),
        channel,
        shipping_city,
        shipping_co,
        shipping_province,
        ...stepPayload
      });
      this.sendEloquaPageView(forceStepId || stepId, this.inferEmail(state));
    }
  }

  private sendEloquaPageView(pageName: string, email?: string) {
    const window = this.window as any;
    if (!this.environmentService.isEloquaEnabled()) {
      window._elqQ_GUID = null;
      window.elqGetGuidCookieValue = () => '';
      return;
    }

    try {
      if (window._elqQ) {
        window._elqQ.push(['elqTrackPageView']);
        // OLD CODE window._elqQ.push(['elqTrackPageView', window.location.href]);
      }
      if (window.ORA) {
        const oraInfo = {
          'ora.z_pagename' : pageName,
          'ora.z_email': email
        };
        if (!oraInfo['ora.z_email']) {
          delete oraInfo['ora.z_email'];
        }
        window.ORA.click({
          data: oraInfo
        });
      }
    } catch (ex) {
      console.warn(ex.message);
    }
  }

  public appendScripts(gtmKey: string) {

    if (this.scriptsAppended) {
      console.warn('Analytics scripts already present');
      return;
    }

    if (!gtmKey) {
      console.warn('Empty analytics GTM key');
      return;
    }

    const window = this.window as any;

    if (window.location.href.includes('localhost')) {
    //  return;
    }

    window.dataLayer = window.dataLayer || [];
    this.pushData({ site_flow: 'ecommerce' });
    const headScriptNode = document.createElement('script');
    headScriptNode.setAttribute('data-cookieconsent', 'statistics');
    // tslint:disable-next-line:prefer-template
    headScriptNode.innerText = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':' +
      'new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],' +
      'j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;' +
      'j.src=\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);' +
      '})(window,document,\'script\',\'dataLayer\',\'' + gtmKey + '\')';
    this.document.head.appendChild(headScriptNode);

    const iframeScriptNode = document.createElement('iframe');
    iframeScriptNode.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${gtmKey}`);
    iframeScriptNode.setAttribute('height', '0');
    iframeScriptNode.setAttribute('width', '0');
    iframeScriptNode.setAttribute('style', 'display:none;visibility:hidden');
    const bodyScriptNode = document.createElement('noscript');
    bodyScriptNode.appendChild(iframeScriptNode);
    this.document.body.append(bodyScriptNode);
  }

  public pushData(payload: AnalyticsPayload) {
    const window = this.window as any;

    if (!window.dataLayer) {
      // OLD CODE window.dataLayer = [];
      console.warn('Missing analytics dataLayer');
      // OLD CODE console.warn('Simulating...');
      // OLD CODE console.warn(payload);
      return;
    }

    if (!window.fakeDataLayer) {
      window.fakeDataLayer = [];
    }

    if (!(window.Cookiebot && window.Cookiebot.consent && window.Cookiebot.consent.statistics)) {
      window.fakeDataLayer.push(payload);
    } else {
      if (window.fakeDataLayer.length) {
        // Push the old ones
        window.fakeDataLayer.forEach(el => {
          window.dataLayer.push(el);
        });
        window.fakeDataLayer = [];
      }
      window.dataLayer.push(payload);
    }
  }

  public sendVRPage(pageName: string) {
    this.pushData({
      event: AnalyticsEventTypes.NEW_PAGE,
      pageCategory: 'VideoIdentificazione',
      pageContent: pageName
    });
  }

  public sendStore(store: string) {
    this.pushData({
      event: AnalyticsEventTypes.CLICK_CTA,
      event_type: `Download app from ${store}`
    });
  }

  public sendVRClick() {
    this.pushData({
      event: AnalyticsEventTypes.CLICK_CTA,
      event_type: `video identificati`
    });
  }

  public sendPromoInfo(promos: InternalPromoInfo[]) {
    this.pushData({
      event: `${AnalyticsEventTypes.PROMO_VIEW}`,
      promotions: promos.map(this.inferPromoDetails)
    });
  }

  public sendDiscountInfo(promo: PromoInfo) {
    return this.sendCustomPage('/popup/visualizza-sconto', {
      promo: 'si',
      promo_type: promo.info
    });
  }

  public getFlowFromBackurl(url: string): string | null {
    try {
      const urlObj = new URL(url);
      const pathnamePart = urlObj.pathname.split('/');
      if (pathnamePart.length >= 2) {
        return pathnamePart[1];
      } else {
        return null;
      }
    } catch (error) {
      console.warn('Errore nel recupero backurl', error);
      return null;
    }
  }
}

<div class="error-page">
  <sb-header [showCart]="false" title=""></sb-header>
  <div class="error-page__container">
    <div class="error-page__content">
      <div class="error-page__title">
        {{(title || 'STEP.ERROR.TITLE') | translate}}
      </div>
      <div class="error-page__subtitle" [innerHTML]="(description || 'STEP.ERROR.SUBTITLE') | translate"></div>
      <div class="wt-text-base-s error__note wt-margin-top-4" *ngIf="note" [innerHTML]="note | translate"></div>
      <div class="error-page__buttons wt-margin-top-4" *ngIf="errorButtons">
        <ng-container *ngFor="let button of errorButtons">
          <ng-container *ngIf="button.type === 'primary'; else secondary">
            <wt-button wt-primary (click)="executeAction(button)" [cssClasses]="['wt-button-error']">
              <span >{{button.label}}</span>
            </wt-button>
          </ng-container>
          <ng-template #secondary>
            <wt-button (click)="executeAction(button)" [cssClasses]="['wt-button--inverse','wt-button-error']">
              <span >{{button.label}}</span>
            </wt-button>
          </ng-template>
        </ng-container>
      </div>
      <div class="error-page__content--with-icons"></div>
    </div>
  </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {PROMO_TYPES, PromoInfo} from 'models';
import {combineLatest, Subscription} from 'rxjs';
import {SimTypeEnum} from '../../../../../../app-shared/models/steps/step-sim.interface';
import {AppStoreFacadeService} from '../../../../../facades/app-store-facade.service';

@Component({
  selector: 'sb-cart-partial-price-and-delivery-info',
  templateUrl: './cart-partial-price-and-delivery-info.component.html',
  styleUrls: ['./cart-partial-price-and-delivery-info.component.scss']
})
export class CartPartialPriceAndDeliveryInfoComponent implements OnInit {
  @Input() cartItems: any;
  @Input() simType: SimTypeEnum;
  @Input() shouldShowOptions: boolean;
  @Input() total: any;
  protected subscriptions: Subscription = new Subscription();
  SimTypeEnumList = SimTypeEnum;
  public special = false;
  public productid: string;
  public exclusiveDiscount: PromoInfo;

  constructor(public appStoreFacadeService: AppStoreFacadeService) {}

  ngOnInit(): void {
    this.isExclusiveDiscountPromo();
    if (this.simType === SimTypeEnum.IdleSim || this.simType === SimTypeEnum.eSim) {
      this.cartItems = this.cartItems.filter(c => c.type !== 'shipping');
    }
  }

  inferSimActivationLabel() {
    switch (this.simType) {
      case SimTypeEnum.eSim:
        return 'ENTITY.CART.PRICE.TYPE.ESIMACTIVATION';
      case SimTypeEnum.IdleSim:
        return 'ENTITY.CART.PRICE.TYPE.ACTIVATION';
      case SimTypeEnum.Standard:
      default:
        return 'ENTITY.CART.PRICE.TYPE.SIMACTIVATION';
    }
  }

  inferShouldShowFrequency() {
    return this.total && this.total.price &&
      (this.total.price.frequency !== 'first-time-only' ||
       this.total.price.value !== (this.cartItems[0] && this.cartItems[0].price  && this.cartItems[0].price.value)
      );
  }

  inferIsPlural(itemType: string) {
    if (itemType !== 'simActivation') {
      return false;
    }
    switch (this.simType) {
      case SimTypeEnum.IdleSim:
        return false;
      case SimTypeEnum.eSim:
      case SimTypeEnum.Standard:
      default:
        return true;
    }
  }

  /**
   * porkaround per offerta Fritz
   *
   * se il PO è PO_Very_010423_DO_AVM_GA
   * e la offer type è EXCLUSIVE_DISCOUNT
   * ritorna true
   *
   */
  public isExclusiveDiscountPromo(): void {
    this.subscriptions.add(
      combineLatest(
        [
          this.appStoreFacadeService.entity.offer$,
          this.appStoreFacadeService.entity.offerPromoInfo$
        ]
      ).subscribe(([offer, offerPromo]) => {
        const promo = offerPromo;
        this.productid = offer.productId;
        this.exclusiveDiscount = offerPromo && offerPromo.promos.find(p => p.promoType === PROMO_TYPES.EXCLUSIVE_DISCOUNT);
        if (this.productid === 'PO_Very_010423_DO_AVM_GA' && this.exclusiveDiscount) {
          this.special = true;
        }
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';

import { StepDocumentEntityData, StepDocumentValue } from 'models';
import { DynamicEntityKey } from 'models';
import { StepId } from 'models';
import { DatesService } from '../../../../../app-shared/services/dates/dates.service';
import { EntityStoreFacadeService } from '../../../../facades/entity-store-facade/entity-store-facade.service';
import { NavigationActions, NavigationHelpers } from '../../../navigation-feature-store';
import * as FlowActions from '../../actions/flow.actions';

@Injectable()
export class StepDocumentEffects {

  private filterByStep = NavigationHelpers.filterByStepFactory(this.entityStoreFacadeService.store$);


  goNext$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.goNext),
    this.filterByStep(StepId.Document),
    withLatestFrom(this.entityStoreFacadeService.step.documentValue$),
    map(([ , documentStepData ]: [ any, StepDocumentValue ]) => {
      const { customerDocument } = documentStepData;
      const documentDataPayload: StepDocumentEntityData = {
        [DynamicEntityKey.CustomerDocument]: {
          ...customerDocument,
          number: (customerDocument.number || '').toString().toUpperCase(),
          issueDate: DatesService.fromDateToISO(DatesService.fromUIToDate(customerDocument.issueDate))
        }
      };

      return FlowActions.postStepData({ stepId: StepId.Document, data: documentDataPayload });
    })
  ));

  restoreStepFromStorage$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.restoreStepFromStorage),
    this.filterByStep(StepId.Document),
    map(({ storage }: any) => {
      this.entityStoreFacadeService.entity.storeEntityValue(DynamicEntityKey.CustomerDocument, storage.customerDocument);
      return null;
    }),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private entityStoreFacadeService: EntityStoreFacadeService
  ) {}
}

import { createAction, props } from '@ngrx/store';
import { PaymentMethod, PaypalBillingAgreementInfo } from 'models';

export enum PaymentBillingAgreementTypes {
  SavePaypalBillingAgreementInfo = '[Payment] Save Paypal Billing Agreement info',
}

export const savePaypalBillingAgreementInfo = createAction(
  PaymentBillingAgreementTypes.SavePaypalBillingAgreementInfo,
  props<{ paypalBillingAgreementInfo: PaypalBillingAgreementInfo, paymentMethod: PaymentMethod }>()
);

export type PaymentBillingAgreementActionsUnion = ReturnType<
  | typeof savePaypalBillingAgreementInfo
>;

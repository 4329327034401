import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { AnalyticsService } from 'services';

import { AppStoreFacadeService } from '../../facades/app-store-facade.service';


@Component({
  selector: 'sb-error',
  templateUrl: './warning.component.html',
  styleUrls: [ './warning.component.scss' ]
})
export class WarningComponent implements OnInit {

  public title: string;
  public description: string;
  public note: string;
  public errorButtons: any[];

  constructor(private appStoreFacadeService: AppStoreFacadeService,
              private analyticsService: AnalyticsService,
              private translateService: TranslateService) {}

  ngOnInit(): void {
    this.appStoreFacadeService.route.queryParams$
      .pipe(
        first(Boolean)
      ).subscribe(({ title, description, note }) => {
        this.title = title;
        this.note = note;
        this.description = description;
        if (title.includes('OTP_MAX_LIMIT_REQUEST_REACHED')) {
          this.errorButtons = [{
            label: 'Scopri di più',
            action: 'link',
            link: 'https://verymobile.it/supporto/diventare-cliente-very/portare-un-numero-in-very/a-cosa-serve-il-codice-otp',
            type: 'primary'
          }, {
            label: 'Chiudi',
            action: 'link',
            link: 'https://verymobile.it/',
            type: 'secondary'
          }];
        }
        try {
          this.analyticsService.sendGenericError(this.translateService.instant(description || 'STEP.ERROR.SUBTITLE'));
        } catch (ex) {
          console.warn(`Error while sending exception`);
          console.warn(ex.message);
        }
    });
  }


  public executeAction({ action, link }) {
    switch (action) {
      case 'link':
        window.location.href = link;
        break;
      default:
        alert(`Action not managed: ${action}`);
    }
  }
}

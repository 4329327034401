import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AnalyticsService } from 'services';

import { AppStoreFacadeService } from '../../facades/app-store-facade.service';


@Component({
  selector: 'sb-error',
  templateUrl: './error.component.html',
  styleUrls: [ './error.component.scss' ]
})
export class ErrorComponent implements OnInit {

  public textNote$: Observable<string>;

  constructor(private appStoreFacadeService: AppStoreFacadeService,
              private analyticsService: AnalyticsService,
              private translateService: TranslateService) {}

  ngOnInit(): void {
    this.textNote$ = this.appStoreFacadeService.route.queryParams$
      .pipe(filter(Boolean), map((queryParams: any) => queryParams.textNote));
    this.textNote$.subscribe(description => {
      try {
        this.analyticsService.sendGenericError(this.translateService.instant(description));
      } catch (ex) {
        console.warn(`Error while sending exception`);
        console.warn(ex.message);
      }
    });
  }
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { fadeAnimation, skipFirstRender } from 'animations';

@Component({
  selector: 'sb-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: [ './spinner.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    skipFirstRender,
    fadeAnimation,
  ]
})
export class SpinnerComponent implements OnChanges {
  @Input() isVisible: boolean;
  disableAnimation = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disableAnimation && changes.isVisible.currentValue === true) {
      this.disableAnimation = false;
    }
  }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Entity, InternalPromoInfo, Offer, OfferPromoInfo, PROMO_TYPES, RIBBON_TYPES } from 'models';
import { WINDOW } from '../../../app/providers/browser.provider';

@Injectable()
export class OfferPromoService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private translateService: TranslateService
  ) {
  }

  public elaborateOfferPromoInfo(promoInfo: OfferPromoInfo, offer: Offer, coupon?: Entity): InternalPromoInfo[] {
    const couponInfo = coupon && coupon.value && coupon.value.code || null;
    const promos: InternalPromoInfo[] = [];
    // Add other standard promos
    promoInfo.promos.forEach(promo => {
      switch (promo.promoType) {
        case PROMO_TYPES.CASHBACK:
        case PROMO_TYPES.XMAS:
          // no elaboration
          promos.push(promo);
          break;
        case PROMO_TYPES.ADDON:
          // Manage addon
          promos.push({
            ...promo,
            message: `PROMO-ADDON-${promo.type.toUpperCase()}.BANNER`,
            messageSuccessPage: `PROMO-ADDON-${promo.type.toUpperCase()}.THANK_YOU`,
            icon: `promo-addon-${promo.type.toLowerCase()}`
          });
          break;
        case PROMO_TYPES.DISCOUNT:
        case PROMO_TYPES.SUMMER_FLASH:
        case PROMO_TYPES.EXCLUSIVE_DISCOUNT:
        case PROMO_TYPES.FLASH:
        case PROMO_TYPES.SUPER_FLASH:
        case PROMO_TYPES.FLASH_BACK:
        case PROMO_TYPES.BLACK_FRIDAY:
        case PROMO_TYPES.GIGA_SPECIAL:
        case PROMO_TYPES.FLASH_XL:
        case PROMO_TYPES.FLASH_BACK_XL:
        case PROMO_TYPES.UNLIMITED:
          // Manage discount
          promos.push({
            ...promo,
            message: promo.info
          });
          break;
        case PROMO_TYPES.COUPON:
          promos.push({
            ...promo,
            message: couponInfo ?
              this.translateService.instant(`PROMO-COUPON.BANNER`, { coupon: couponInfo.toUpperCase() }) :
              'PROMO-COUPON.BANNER-GENERIC',
            messageSuccessPage: couponInfo ?
              this.translateService.instant(`PROMO-COUPON.THANK_YOU`, { coupon: couponInfo.toUpperCase() }) :
              'PROMO-COUPON.THANK_YOU-GENERIC'
          });
          break;
        default:
          console.warn('Promo not managed');
          console.warn(promo);
      }
    });

    // Manage double cashback
    if (this.shouldShowDoubleCashback(offer.productId || offer.ropz)) {
      promos.push({
        promoType: PROMO_TYPES.CASHBACK_DOUBLE,
        info: null
      });
    }

    // Manage Aglioween
    if (this.shouldShowHalloween(offer.productId || offer.ropz)) {
      promos.push({
        promoType: PROMO_TYPES.HALLOWEEN,
        info: null
      });
    }

    // Manage Promo Flash
    if (this.shouldShowFlash(offer.productId || offer.ropz)) {
      promos.push({
        promoType: PROMO_TYPES.FLASH,
        info: null,
        hideInSuccessPage: true
      });
    }

    // Manage activation fee
    if (this.shouldShowFreeActivation(offer.productId || offer.ropz)) {
      promos.push({
        promoType: PROMO_TYPES.ACTIVATION_FEE,
        info: null
      });
    }

    if (this.shouldShowXmas() && !promos.find(r => r.promoType === PROMO_TYPES.ADDON && r.type === 'XMAS')) {
      promos.push({
        promoType: PROMO_TYPES.XMAS,
        info: null
      });
    }
    return promos;
  }

  public inferRibbonColor(promos: InternalPromoInfo[]) {
    if (promos.length > 1) {
      return RIBBON_TYPES.DEFAULT;
    } else {
      let promoType = promos[0].promoType.toString();
      if (promos[0].promoType === PROMO_TYPES.ADDON.toString()) {
        promoType = `${promos[0].promoType.toString().toUpperCase()}_${promos[0].type.toUpperCase()}`;
      }
      return RIBBON_TYPES[promoType.replace(/-/gi, '_')];
    }
  }
  private shouldShowHalloween(offerProductId: string): boolean {
    const start = new Date(2022, 9, 27, 0, 1, 0);
    const end = new Date(2022, 10, 2, 23, 59, 59);
    const today = new Date();
    return (today >= start && today <= end && [
      'PO_Very_030822_GA',
      'PO_Very_030822_MNP1'
    ].includes(offerProductId));
  }

  private shouldShowFlash(offerProductId: string): boolean {
      const start = new Date(2023, 2, 1, 0, 1, 0);
      const end = new Date(2023, 5, 12, 23, 59, 59);
      const today = new Date();
      return (today >= start && today <= end && [
        'PO_DEA_GA',
        'PO_Very_010323_A_MNP1',
        'PO_Very_040621_F_MNP1',
        'PO_Very_010323_B_MNP1',
        'PO_Very_010323_A_MNP8',
        'PO_Very_010323_B_MNP8',
        'PO_Very_010423_A_GA',
        'PO_Very_010423_B_GA',
        'PO_Very_010423_C_GA',
        'PO_Very_180523_GA',
        'PO_Very_180523_MNP1',
        'PO_Very_180523_MNP8',
        'PO_Very_180523_MNP_WB'
      ].includes(offerProductId));
    }

  private shouldShowDoubleCashback(offerProductId: string): boolean {
    // ARES-1890: Faculty banner PORKAROUND
    return [
      'PO_Very_TEST_TEST_TEST_PROMO',
      'PO_Very_040221_A_PART_GA',
      'PO_Very_040221_B_PART_GA',
      'PO_Very_040221_A_PART_MNP1',
      'PO_Very_040221_B_PART_MNP1',
      'PO_Very_040221_A_PART_MNP2',
      'PO_Very_040221_A_PART_MNP3',
      'PO_Very_040221_B_PART_MNP2',
      'PO_Very_040221_B_PART_MNP3'
    ].includes(offerProductId);
  }

  isCurrentlyFreeActivation(offerProductId: string): boolean {
    // Promo from 26/04/21 to 03/06/2021
    const start = new Date(2021, 8, 1, 0, 1, 0);
    const end = new Date(2021, 8, 20, 23, 59, 59);
    const today = new Date();
    return today >= start && today <= end && [
      'PO_Very_210920_MNP4Local',
      'PO_Very_040621_F_MNP1'
    ].includes(offerProductId);
  }

  isNextFreeActivation(offerProductId: string): boolean {
    // Promo from 01/09/21 to 30/09/2021
    const start = new Date(2021, 8, 1, 0, 1, 0);
    const end = new Date(2021, 10, 3, 23, 59, 59);
    const today = new Date();
    return (today >= start && today <= end && [
      'PO_Very_050521_MNP1',
      'PO_Very_040621_A_GA',
      'PO_Very_040621_A_MNP2',
      'PO_Very_040621_A_MNP3',
      'PO_Very_210121_MNP1',
      'PO_Very_040621_B_GA',
      'PO_Very_040621_B_MNP2',
      'PO_Very_040621_B_MNP3',
      'PO_Very_200921_S_GA',
      'PO_Very_200921_S_MNP1',
      'PO_Very_200921_S_GA'
    ].includes(offerProductId));
  }

  private shouldShowFreeActivation(offerProductId: string): boolean {
    return this.isNextFreeActivation(offerProductId) || this.isCurrentlyFreeActivation(offerProductId);
  }

  private shouldShowXmas(): boolean {
    return false;
    const start = new Date(2022, 10, 1, 0, 1, 0);
    const end = new Date(2023, 0, 9, 23, 59, 59);
    const today = new Date();
    return (today >= start && today <= end) ||
      window.location.href.includes('mobile.int') ||
      window.location.href.includes('mobile.dev');
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { NavigationEffects } from './navigation.effects';
import { navigationReducer } from './navigation.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('navigation', navigationReducer),
    EffectsModule.forFeature([ NavigationEffects ])
  ]
})
export class NavigationFeatureStoreModule {}

import { EntityParsed, EntityValue } from 'models';

export interface EntityState {
  static: { [key: string]: EntityValue };
  dynamic: { [key: string]: EntityParsed };
}

export const initialEntityState: EntityState = {
  static: {},
  dynamic: {}
};

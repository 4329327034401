import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../app/providers/browser.provider";
import * as i3 from "../../../app/facades/app-store-facade.service";
import * as i4 from "../exec/exec.service";
import * as i5 from "../../../app/facades/navigation-store-facade/navigation-store-facade.service";
export class PaypalService {
    constructor(document, window, appStoreFacadeService, execService, navigationStoreFacadeService) {
        this.document = document;
        this.window = window;
        this.appStoreFacadeService = appStoreFacadeService;
        this.execService = execService;
        this.navigationStoreFacadeService = navigationStoreFacadeService;
        this.scriptsAppended = false;
    }
    appendScripts(paypalConfiguration) {
        if (this.scriptsAppended) {
            console.warn('Paypal scripts already present');
            return;
        }
        const headScriptNode = document.createElement('script');
        headScriptNode.setAttribute('src', `https://www.paypal.com/sdk/js?client-id=${paypalConfiguration.clientId}&currency=EUR&locale=it_IT&intent=authorize`);
        headScriptNode.setAttribute('id', 'ppurl');
        this.document.head.appendChild(headScriptNode);
    }
    switchPaypalUrlScript(smartTopUp, paypalConfiguration) {
        document.getElementById('ppurl').remove();
        const headScriptNode = document.createElement('script');
        if (smartTopUp) {
            headScriptNode.setAttribute('src', `https://www.paypal.com/sdk/js?client-id=${paypalConfiguration.clientId}&currency=EUR&locale=it_IT&intent=tokenize&vault=true`);
        }
        else {
            headScriptNode.setAttribute('src', `https://www.paypal.com/sdk/js?client-id=${paypalConfiguration.clientId}&currency=EUR&locale=it_IT&intent=authorize`);
        }
        headScriptNode.setAttribute('id', 'ppurl');
        this.document.head.appendChild(headScriptNode);
    }
    createPaypalButtons(options, container) {
        console.warn('you are in createPaypalButtons');
        const window = this.window;
        const appStoreFacadeService = this.appStoreFacadeService;
        const navigationStoreFacadeService = this.navigationStoreFacadeService;
        const execService = this.execService;
        const domElement = container || 'body';
        if (!(window.paypal && document.querySelectorAll(domElement).length)) {
            setTimeout(() => this.createPaypalButtons(options, container), 175);
            return;
        }
        window.paypal.Buttons({
            style: {
                layout: 'vertical',
                color: 'blue',
                shape: 'rect',
                label: 'pay'
            },
            createOrder: (data, actions) => {
                console.warn('TRUE PAYPAL');
                appStoreFacadeService.spinner.updateSpinnerStatus(true);
                return new Promise(resolve => {
                    // execService.getPaypalOrderId()
                    execService.getPaypalToken()
                        .subscribe((resp) => {
                        console.warn('resp createPaypalButtons ', resp);
                        if (!(resp && resp.token)) {
                            console.warn('FALSE PAYPAL');
                            appStoreFacadeService.spinner.updateSpinnerStatus(false);
                            navigationStoreFacadeService.navigation.setQueryParams({
                                textNote: 'STEP.PAYMENT-METHOD.PAYPAL_ERROR'
                            });
                            // OLD CODE appStoreFacadeService.navigation.navigateToErrorPage();
                            return null;
                        }
                        return resolve(resp.token);
                    });
                });
            },
            onCancel(data) {
                console.warn('FALSE CANCEL PAYPAL');
                appStoreFacadeService.spinner.updateSpinnerStatus(false);
                // Show a cancel page, or return to cart
                // data is { paypalOrderId }
                // console.log('CANCELED');
            },
            onError(err) {
                console.warn('FALSE ERROR PAYPAL');
                appStoreFacadeService.spinner.updateSpinnerStatus(false);
                // Show an error page here, when an error occurs
                // OLD CODE TODO: WHAT TO DO HERE? SHOW A MODAL?
                // console.log(err);
                // console.log(JSON.stringify(err));
                navigationStoreFacadeService.navigation.setQueryParams({
                    textNote: 'STEP.PAYMENT-METHOD.PAYPAL_ERROR'
                });
                // OLD CODE appStoreFacadeService.navigation.navigateToErrorPage();
            },
            onApprove: (data, actions) => {
                console.warn('FALSE APPROVE PAYPAL');
                console.warn('check data', data);
                appStoreFacadeService.spinner.updateSpinnerStatus(false);
                // actions.order.get();
                // actions.order.get().then(function(details) {});
                // Capture the funds from the transaction
                appStoreFacadeService.step.storePaypalPaymentTransaction({
                    orderId: data.orderID,
                    payerId: data.payerID
                });
            }
        }).render(domElement);
    }
    createPaypalSTUButtons(options, container) {
        console.warn('you are in createPaypalSTUButtons');
        const window = this.window;
        const appStoreFacadeService = this.appStoreFacadeService;
        const navigationStoreFacadeService = this.navigationStoreFacadeService;
        const execService = this.execService;
        const domElement = container || 'body';
        if (!(window.paypal && document.querySelectorAll(domElement).length)) {
            setTimeout(() => this.createPaypalSTUButtons(options, container), 175);
            return;
        }
        window.paypal.Buttons({
            style: {
                layout: 'vertical',
                color: 'blue',
                shape: 'rect',
                label: 'pay'
            },
            createBillingAgreement: (data, actions) => {
                console.warn('TRUE PAYPAL');
                appStoreFacadeService.spinner.updateSpinnerStatus(true);
                return new Promise(resolve => {
                    execService.getPaypalToken()
                        .subscribe((resp) => {
                        console.warn('resp createBillingAgreement ', resp);
                        if (!(resp && resp.token)) {
                            console.warn('FALSE PAYPAL');
                            appStoreFacadeService.spinner.updateSpinnerStatus(false);
                            navigationStoreFacadeService.navigation.setQueryParams({
                                textNote: 'STEP.PAYMENT-METHOD.PAYPAL_ERROR'
                            });
                            // OLD CODE appStoreFacadeService.navigation.navigateToErrorPage();
                            return null;
                        }
                        return resolve(resp.token);
                    });
                });
            },
            onCancel(data) {
                console.warn('FALSE CANCEL PAYPAL');
                appStoreFacadeService.spinner.updateSpinnerStatus(false);
                // Show a cancel page, or return to cart
                // data is { paypalOrderId }
                // console.log('CANCELED');
            },
            onError(err) {
                console.warn('FALSE ERROR PAYPAL');
                appStoreFacadeService.spinner.updateSpinnerStatus(false);
                // Show an error page here, when an error occurs
                // OLD CODE TODO: WHAT TO DO HERE? SHOW A MODAL?
                // console.log(err);
                // console.log(JSON.stringify(err));
                navigationStoreFacadeService.navigation.setQueryParams({
                    textNote: 'STEP.PAYMENT-METHOD.PAYPAL_ERROR'
                });
                // OLD CODE appStoreFacadeService.navigation.navigateToErrorPage();
            },
            onApprove: (data, actions) => {
                console.warn('FALSE APPROVE PAYPAL');
                console.warn('check data billing agreement -> ', data);
                appStoreFacadeService.spinner.updateSpinnerStatus(false);
                // actions.order.get();
                // actions.order.get().then(function(details) {});
                // Capture the funds from the transaction
                // appStoreFacadeService.step.storePaypalBillingAgreement({
                //   billingToken: data.billingToken
                // });
                appStoreFacadeService.step.storePaypalPaymentTransaction({
                    billingToken: data.billingToken,
                    orderId: data.orderID,
                    payerId: data.payerID
                });
            }
        }).render(domElement);
    }
}
PaypalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaypalService_Factory() { return new PaypalService(i0.ɵɵinject(i1.DOCUMENT), i0.ɵɵinject(i2.WINDOW), i0.ɵɵinject(i3.AppStoreFacadeService), i0.ɵɵinject(i4.ExecService), i0.ɵɵinject(i5.NavigationStoreFacadeService)); }, token: PaypalService, providedIn: "root" });

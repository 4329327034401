import { AnimationDriver, ɵWebAnimationsDriver, } from '@angular/animations/browser';

import { isFirefox, isIE } from 'helpers';

export function animationProvider(): any {
  const noop = AnimationDriver.NOOP;
  const driver = new ɵWebAnimationsDriver();

  return isIE() || isFirefox() ? noop : driver;
}

export const ANIMATION_ENGINE_PROVIDER = { provide: AnimationDriver, useFactory: animationProvider };

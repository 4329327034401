import { Pipe, PipeTransform } from '@angular/core';
import { TotalPrice, TotalPriceEnum } from 'models';

@Pipe({
  name: 'inferCartItems'
})
export class InferCartItemsPipe implements PipeTransform {
  transform(cartItems: TotalPrice[]): TotalPrice[] {
    return cartItems.filter((cartItem: TotalPrice) =>
      cartItem.type !== TotalPriceEnum.Sim && cartItem.type !== TotalPriceEnum.Activation);
  }
}

export const MINIMUM_CITY_LENGTH = 3;
export const MAXIMUM_CVV_CODE_LENGTH = 4;
export const MAXIMUM_ZIP_CODE_LENGTH = 5;
export const MAXIMUM_TAX_CODE_LENGTH = 16;
export const MAXIMUM_VAT_NUMBER_LENGTH = 11;
export const MAXIMUM_CREDITCARD_NUMBER_LENGTH = 25;
export const MAXIMUM_ICCID_LENGTH = 19;
export const MAXIMUM_MOBILE_NUMBER_LENGTH = 12;
export const MAXIMUM_ADDRESS_AT_LENGTH = 16;
export const MAXIMUM_ADDRESS_STREET_LENGTH = 37;
export const MAXIMUM_ADDRESS_NUMBER_LENGTH = 10;
export const VERYMOBILE_ICCID_PREFIX = 893988;
export const MAXIMUM_ICCID_LENGTH_NOPREFIX = MAXIMUM_ICCID_LENGTH - VERYMOBILE_ICCID_PREFIX.toString().length;

export const MAXIMUM_NAME_LENGTH = 40;
export const MAXIMUM_OTP_LENGTH = 6;
export const MAXIMUM_EMAIL_LENGTH = 55;
export const MAXIMUM_DOCUMENT_VALUE_LENGTH = 55;

export const MAXIMUM_ORDER_ID_LENGTH = 11;
export const MAXIMUM_CHECK_SIM_ICCID_RECOGNITION = 22;
export const MAXIMUM_COUPON_LENGTH = 8;

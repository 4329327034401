/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/spinner/spinner.component.ngfactory";
import * as i3 from "./components/spinner/spinner.component";
import * as i4 from "./components/modals/modals.component.ngfactory";
import * as i5 from "./components/modals/modals.component";
import * as i6 from "@ngrx/effects";
import * as i7 from "@angular/forms";
import * as i8 from "../app-shared/services/exec/exec.service";
import * as i9 from "../app-shared/services/analytics/analytics.service";
import * as i10 from "./facades/app-store-facade.service";
import * as i11 from "../app-shared/services/basket/basket.service";
import * as i12 from "@angular/router";
import * as i13 from "./app.component";
import * as i14 from "@angular/common";
import * as i15 from "../app-shared/services/environment/environment.service";
import * as i16 from "@ngx-translate/core";
import * as i17 from "../app-shared/services/scroll/scroll.service";
import * as i18 from "../app-shared/services/errors/errors.service";
import * as i19 from "../app-shared/services/paypal/paypal.service";
import * as i20 from "../app-shared/services/offer-promo/offer-promo.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "funnel-checkout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "sb-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(2, 573440, null, 0, i3.SpinnerComponent, [], { isVisible: [0, "isVisible"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "sb-modals", [], null, [["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("document:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).handleKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ModalsComponent_0, i4.RenderType_ModalsComponent)), i1.ɵdid(4, 245760, null, 0, i5.ModalsComponent, [i6.Actions, i7.FormBuilder, i8.ExecService, i9.AnalyticsService, i10.AppStoreFacadeService, i11.BasketService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "main", [["class", "funnel-checkout__main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, [["outlet", 4]], 0, i12.RouterOutlet, [i12.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldShowSpinner; _ck(_v, 2, 0, currVal_0); _ck(_v, 4, 0); _ck(_v, 7, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sb-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4440064, null, 0, i13.AppComponent, [i14.DOCUMENT, i10.AppStoreFacadeService, i1.ChangeDetectorRef, i15.EnvironmentService, i16.TranslateService, i17.ScrollService, i18.ErrorsService, i9.AnalyticsService, i19.PaypalService, i20.OfferPromoService, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("sb-root", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };

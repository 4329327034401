import * as BasketActions from './actions/basket.actions';
import * as FlowActions from './actions/flow.actions';
import * as HeaderActions from './actions/header.actions';
import * as ModalActions from './actions/modal.actions';
import * as SpinnerActions from './actions/spinner.actions';
import * as TranslationActions from './actions/translation.actions';
import * as CartSelectors from './selectors/cart.selectors';
import * as OfferSelectors from './selectors/offer.selectors';

const ProcessFeatureActions = {
  BasketActions,
  FlowActions,
  HeaderActions,
  ModalActions,
  SpinnerActions,
  TranslationActions,
};
const ProcessFeatureSelectors = {
  CartSelectors,
  OfferSelectors
};

export { ProcessFeatureStoreModule } from './process-feature-store.module';
export { ProcessFeatureActions, ProcessFeatureSelectors };

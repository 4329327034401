import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inferCurrencyLabel'
})
export class InferCurrencyLabelPipe implements PipeTransform {

  transform(value: any): any {
    return value && value.replace(/\s€/, '€');
  }

}

import { EntityConstraintGroup, EntityOptionsGroup, EntityValueGroup, StepData } from 'models';

const inferStepGroup = (stepData: StepData, groupKey: string): EntityValueGroup => {
  const entityKeys = Object.keys(stepData || {});
  return entityKeys.reduce((prev, curr) => ({ ...prev, [curr]: stepData[curr][groupKey] }), {});
};

export const inferStepValueGroup = (stepData: StepData): EntityValueGroup => inferStepGroup(stepData, 'value');
export const inferStepConstraintGroup = (stepData: StepData): EntityConstraintGroup => inferStepGroup(stepData, 'constraint');
export const inferStepOptionsGroup = (stepData: StepData): EntityOptionsGroup => inferStepGroup(stepData, 'options');


import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { parseEntityKeyToPropertyName } from 'helpers';
import { EntityConstraintGroup, ValidationError } from 'models';
import { ValidatorsService } from '../validators/validators.service';

@Injectable()
export class FormsService {
  constructor(
    private fb: FormBuilder,
    private validatorsService: ValidatorsService
  ) {}

  public enhanceFormGroupWithValidationError(formGroup: FormGroup, validationError: ValidationError) {
    const { path } = validationError;
    const parsedPath = parseEntityKeyToPropertyName(path);
    const formControl = formGroup.get(parsedPath);

    if (!formControl) {
      console.warn('Form control not found', parsedPath);
      return;
    }

    const registeredValidationErrorKey = this.validatorsService.registerValidationErrorAndGetKey(validationError);
    formControl.setErrors({ [registeredValidationErrorKey]: true });
  }

  public enhanceFormGroupWithConstraints(formGroup: FormGroup, constraintGroup: EntityConstraintGroup): FormGroup {
    Object.keys(constraintGroup || {}).forEach((controlKey: string) => {
      const formControl = formGroup.get(controlKey);

      if (formControl) {
        const newValidators = this.validatorsService.transformConstraintsToValidators(constraintGroup[controlKey]);
        // If there is some validator applied to the field in form service, we add it, instead of replacing it
        formControl.setValidators((formControl.validator ? [ formControl.validator, ...newValidators ] : newValidators));
        formControl.updateValueAndValidity({ emitEvent: false });
      } else {
        console.warn(`Unknown ${ controlKey } form control key`, formGroup);
      }
    });

    return formGroup;
  }

  public generateCustomerForm() {
    return this.fb.group({
      customerIdentity: this.fb.group({
        firstName: this.fb.control(undefined),
        lastName: this.fb.control(undefined),
        taxCode: this.fb.control(undefined),
        customerGUID: this.fb.control(undefined)
      }),
      generalConsents: this.fb.group({
        generalServiceConditionsAccepted: this.fb.control(undefined),
        eSimConditionsAccepted: this.fb.control(undefined),
        precontractConditionsAccepted: this.fb.control(undefined)
      })
    });
  }

  public generateStepWinbackForm() {
    return this.fb.group({
      winbackInfo: this.fb.group({
        mobileNumber: this.fb.control(undefined)
      })
    });
  }

  public generateStepConsentsForm() {
    return this.fb.group({
        marketingConsents: this.fb.group({
          ownAndThirdServicesMarketingAccepted: this.fb.control(undefined),
          geolocalizationDataAndProfilingAccepted: this.fb.control(undefined),
          thirdServicesEnrichmentAccepted: this.fb.control(undefined),
          thirdServicesTransferAccepted: this.fb.control(undefined)
      })
    });
  }

  public generateStepCouponCode() {
    return this.fb.group({
      couponInfo: this.fb.group({
        code: this.fb.control(undefined, Validators.required)
      })
    });
  }


  public generateStepDocumentForm() {
    return this.fb.group({
      customerDocument: this.fb.group({
        type: this.fb.control(undefined),
        number: this.fb.control(undefined),
        issueDate: this.fb.control(null)
      })
    });
  }

  public generateStepContactsForm() {
    return this.fb.group({
      customerContacts: this.fb.group({
        email: this.fb.control(undefined),
        confirmEmail: this.fb.control(undefined, Validators.compose([ValidatorsService.equalTo('email')])),
        mobileNumber: this.fb.control(undefined)
      }),
      generalConsents: this.fb.group({
        generalServiceConditionsAccepted: this.fb.control(undefined),
        eSimConditionsAccepted: this.fb.control(undefined),
        precontractConditionsAccepted: this.fb.control(undefined)
      })
    });
  }

  public generateStepAddressForm() {
    return this.fb.group({
      customerResidenceAddress: this.generateResidenceAddressForm(),
      customerDeliveryAddress: this.generateDeliveryAddressForm()
    });
  }

  public generateStepActivationForm() {
    return this.fb.group({
      activationMethod: this.fb.group({
        method: this.fb.control(undefined)
      }),
      activationMnpInfo: this.fb.group({
        msisdn: this.fb.control(undefined),
        operator: this.fb.control(undefined),
        iccid: this.fb.control(undefined)
      })
    });
  }

  public generateStepOfferExtrasForm() {
    return this.fb.group({
      offerExtras: this.fb.group({
        smartTopUp: this.fb.control(undefined)
      }),
      selectedAddons: this.fb.group({
        addons: this.fb.control(undefined)
      })
    });
  }

  public generateStepPaymentMethodForm() {
    return this.fb.group({
      paymentMethod: this.fb.group({
        method: this.fb.control(undefined)
      })
    });
  }

  public generateStepSimTypeForm() {
    return this.fb.group({
      simType: this.fb.group({
        type: this.fb.control(undefined)
      })
    });
  }

  public generateStepFdoIccidForm() {
    return this.fb.group({
      fdoIccid: this.fb.group({
        iccid: this.fb.control(undefined)
      })
    });
  }

  public generateStepShippingMethodForm() {
    return this.fb.group({
      shippingMethod: this.fb.group({
        method: this.fb.control(undefined)
      })
    });
  }

  private generateDeliveryAddressForm() {
    return this.fb.group({
      at: this.fb.control(undefined),
      recipient: this.fb.control(undefined),
      street: this.fb.control(undefined),
      number: this.fb.control(undefined),
      zipCode: this.fb.control(undefined),
      city: this.fb.control(undefined),
      district: this.fb.control(undefined),
      fullStreet: this.fb.control(undefined)
    });
  }

  private generateResidenceAddressForm() {
    return this.fb.group({
      country: this.fb.control(undefined),
      street: this.fb.control(undefined),
      number: this.fb.control(undefined),
      zipCode: this.fb.control(undefined),
      city: this.fb.control(undefined),
      district: this.fb.control(undefined)
    });
  }

  public generateRecognitionForm() {
    return this.fb.group({
      taxCode: this.fb.control(undefined, Validators.compose([ValidatorsService.notEmpty(), ValidatorsService.taxcode()])),
      iccid: this.fb.control(undefined, Validators.compose([ValidatorsService.notEmpty(), ValidatorsService.size(5, 5)]))
    });
  }

  public generateStepActivationOtpForm() {
    return this.fb.group({
      activationMnpInfo: this.fb.group({
        msisdn: this.fb.control(undefined),
        operator: this.fb.control(undefined),
        esim: this.fb.control(undefined),
        iccid: this.fb.control(undefined)
      }),
    });
  }

  public generateStepSummaryForm() {
    return this.fb.group({
      summaryInfo: this.fb.group({
        contacts: this.fb.control(undefined)
      }),
    });
  }
}

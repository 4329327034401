import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';

import { DynamicEntityKey, StepActivationOtpEntityData, StepActivationOtpValue } from 'models';
import { StepId } from 'models';
import { EntityStoreFacadeService } from '../../../../facades/entity-store-facade/entity-store-facade.service';
import { NavigationActions, NavigationHelpers } from '../../../navigation-feature-store';
import * as FlowActions from '../../actions/flow.actions';

@Injectable()
export class StepActivationOtpEffects {

  private filterByStep = NavigationHelpers.filterByStepFactory(this.entityStoreFacadeService.store$);

  goNext$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.goNext),
    this.filterByStep(StepId.ActivationOtp),
    withLatestFrom(
      this.entityStoreFacadeService.step.activationOtpValue$,
      this.entityStoreFacadeService.entity.otpInfo$
    ),
    map(([ , stepData, otpInfo ]: [ any, StepActivationOtpValue, any]) => {
      const { activationMnpInfo } = stepData;
      const dataPayload: StepActivationOtpEntityData = {
        [DynamicEntityKey.ActivationMnpInfo]: activationMnpInfo
      };
      if (!dataPayload[DynamicEntityKey.ActivationMnpInfo].iccid) {
        delete dataPayload[DynamicEntityKey.ActivationMnpInfo].iccid;
      }
      if (otpInfo) {
        dataPayload[DynamicEntityKey.OtpInfo] = otpInfo.value;
      }
      return FlowActions.postStepData({ stepId: StepId.ActivationOtp, data: dataPayload });
    })
  ));

  restoreStepFromStorage$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.restoreStepFromStorage),
    this.filterByStep(StepId.ActivationOtp),
    map(({ storage }: any) => {
      this.entityStoreFacadeService.entity.storeEntityValue(DynamicEntityKey.ActivationMnpInfo, storage.activationMnpInfo);
      return null;
    }),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private entityStoreFacadeService: EntityStoreFacadeService
  ) {}
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  DynamicEntityKey,
  PaymentMethodEnum, SelectedAddons, StepActivationEntityData,
  StepData,
  StepId,
  StepPaymentMethodEntityData,
  StepPaymentMethodValue
} from 'models';
import { map, withLatestFrom } from 'rxjs/operators';
import { StepContactsEntityData, StepContactsValue } from '../../../../../app-shared/models/steps/step-contacts.interface';
import {StepFDOIccidValueEntityData} from '../../../../../app-shared/models/steps/step-digital-onboarding.interface';
import {
  StepOfferExtrasEntityData,
  StepOfferExtrasValue
} from '../../../../../app-shared/models/steps/step-offer-extras.interface';
import {StepSummaryValue} from '../../../../../app-shared/models/steps/step-summary.interface';
import { EntityStoreFacadeService } from '../../../../facades/entity-store-facade/entity-store-facade.service';
import { NavigationActions, NavigationHelpers } from '../../../navigation-feature-store';
import {postStepData} from '../../actions/flow.actions';
import * as FlowActions from '../../actions/flow.actions';

@Injectable()
export class StepOfferExtrasEffects {

  private filterByStep = NavigationHelpers.filterByStepFactory(this.entityStoreFacadeService.store$);

  goNext$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.goNext),
    this.filterByStep(StepId.OfferExtras),
    withLatestFrom(this.entityStoreFacadeService.step.extrasValue$),
    map(([ , stepData ]: [ any, StepOfferExtrasValue ]) => {
      const { offerExtras, selectedAddons } = stepData;
      const dataPayload: StepOfferExtrasEntityData = {
        [DynamicEntityKey.OfferExtras]: {...offerExtras},
        [DynamicEntityKey.SelectedAddons]: selectedAddons
      };
      return FlowActions.postStepData({ stepId: StepId.OfferExtras, data: dataPayload });
    })
  ));

  restoreStepFromStorage$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.restoreStepFromStorage),
    this.filterByStep(StepId.OfferExtras),
    map(({ storage }: any) => {
      this.entityStoreFacadeService.entity.storeEntityValue(DynamicEntityKey.CustomerContacts, storage.customerContacts);
      this.entityStoreFacadeService.entity.storeEntityValue(DynamicEntityKey.SimType, storage.simType);
      this.entityStoreFacadeService.entity.storeEntityValue(DynamicEntityKey.GeneralConsents, storage.generalConsents);
      this.entityStoreFacadeService.entity.storeEntityValue(DynamicEntityKey.MarketingConsents, storage.marketingConsents);
      return null;
    }),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private entityStoreFacadeService: EntityStoreFacadeService
  ) {}
}

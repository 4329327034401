import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { DynamicEntityKey, StepId } from 'models';
import { map, withLatestFrom } from 'rxjs/operators';
import { StepWinbackEntityData, StepWinbackValue } from '../../../../../app-shared/models/steps/step-winback.interface';
import { EntityStoreFacadeService } from '../../../../facades/entity-store-facade/entity-store-facade.service';
import { NavigationActions, NavigationHelpers } from '../../../navigation-feature-store';
import * as FlowActions from '../../actions/flow.actions';

@Injectable()
export class StepWinbackEffects {

  private filterByStep = NavigationHelpers.filterByStepFactory(this.entityStoreFacadeService.store$);


  goNext$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.goNext),
    this.filterByStep(StepId.Winback),
    withLatestFrom(this.entityStoreFacadeService.step.winbackValue$),
    map(([ , stepData ]: [ any, StepWinbackValue ]) => {
      const { couponInfo } = stepData;
      const dataPayload: StepWinbackEntityData = {
        [DynamicEntityKey.WinbackInfo]: {
          ...couponInfo,
        }
      };
      return FlowActions.postStepData({ stepId: StepId.Winback, data: dataPayload });
    })
  ));

  constructor(
    private actions$: Actions,
    private entityStoreFacadeService: EntityStoreFacadeService
  ) {}
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { concatMap, delay, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { MacroStepId, StaticEntityKey } from 'models';
import { NavigationStoreFacadeService } from '../../../facades/navigation-store-facade/navigation-store-facade.service';
import { NavigationHelpers } from '../../navigation-feature-store';
import * as NavigationActions from '../../navigation-feature-store/navigation.actions';
import * as BasketActions from '../actions/basket.actions';

@Injectable()
export class ProcessEffects {
  private filterByMacroStep = NavigationHelpers.filterByMacroStepFactory(this.navigationStoreFacadeService.store$);


  navigateToMacroStep$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.navigateToPrevious),
    this.filterByMacroStep(MacroStepId.Process),
    withLatestFrom(this.navigationStoreFacadeService.navigation.previousStepId$),
    filter(([ , stepId ]) => Boolean(stepId)),
    map(([ , stepId ]) => {
      return NavigationActions.navigateTo({ commands: [ `${ MacroStepId.Process }/${ stepId }` ] });
    })
  ));


  navigateToPrevious$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.navigateToPrevious),
    this.filterByMacroStep(MacroStepId.Process),
    withLatestFrom(this.navigationStoreFacadeService.navigation.previousStepId$),
    filter(([ , stepId ]) => Boolean(stepId)),
    map(([ , stepId ]) => NavigationHelpers.inferNavigateToAction(stepId))
  ));


  navigateToNext$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.navigateToNext),
    this.filterByMacroStep(MacroStepId.Process),
    withLatestFrom(this.navigationStoreFacadeService.navigation.nextStepId$),
    delay(150),
    switchMap(([ , stepId ]) => {
      if (stepId) {
        return [ NavigationHelpers.inferNavigateToAction(stepId) ];
      }
      return EMPTY;
    })
  ));



  loadOtherStepsDataOnSetNavigationHistory$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.setNavigationHistory),
    this.filterByMacroStep(MacroStepId.Process),
    withLatestFrom(this.navigationStoreFacadeService.navigation.currentStepId$),
    concatMap(([ action, currentStepId ]) => {
      const history = action.steps || [];
      const historyWithoutCurrentStep = history.filter((stepId) => stepId !== currentStepId);

      if (historyWithoutCurrentStep.length) {
        return [
            BasketActions.getMultipleStepData({ stepIds: historyWithoutCurrentStep, ignoredEntitiesKeys: [
            StaticEntityKey.Cart,
            StaticEntityKey.Navigation
          ] })
        ];
      }

      return EMPTY;
    })
  ));

  constructor(
    private navigationStoreFacadeService: NavigationStoreFacadeService,
    private actions$: Actions
  ) {}
}


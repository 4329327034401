import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inferCartPriceLabels'
})
export class InferCartPriceLabelsPipe implements PipeTransform {

  transform(priceType: any, ...args: any[]): any {
    return `ENTITY.CART.PRICE.TYPE.${priceType.toUpperCase()}`;
  }

}

import { Component, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { first, map } from 'rxjs/operators';

import { AppStoreFacadeService } from '../../facades/app-store-facade.service';


@Component({
  selector: 'sb-init',
  template: ''
})
export class InitComponent implements OnInit {
  constructor(
    private appStoreFacadeService: AppStoreFacadeService
  ) {}

  ngOnInit(): void {
    this.appStoreFacadeService.route.pathParams$
      .pipe(
        first((pathParams: Params) => pathParams && Boolean(pathParams.token)),
        map((pathParams: Params) => pathParams.token)
      )
      .subscribe((token: string) => {
        this.appStoreFacadeService.header.setToken(token);
        this.appStoreFacadeService.flow.getStepData();
      });
  }
}

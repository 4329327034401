import { Inject, Injectable } from '@angular/core';

import {
  AnalyticsConfiguration,
  ApiConfiguration,
  ApiEndpointConfiguration,
  EnvironmentConfiguration,
  PaypalConfiguration,
  RecognitionApiEndpointConfiguration,
  SentryConfiguration, TestOfferConfiguration
} from 'models';
import { WINDOW } from '../../../app/providers/browser.provider';

interface WindowWithEnv extends Window {
  __env: any;
}

@Injectable()
export class EnvironmentService {

  private environment: any;

  constructor(
    @Inject(WINDOW) private window: Window
  ) { }

  private getEnvironment(): EnvironmentConfiguration {
    if (!this.environment) {
      this.environment = (this.window as WindowWithEnv).__env;
    }

    return this.environment;
  }

  getApiConfiguration(): ApiConfiguration<ApiEndpointConfiguration> {
    const environment = this.getEnvironment();
    return environment.api;
  }

  getRecognitionApiConfiguration(): ApiConfiguration<RecognitionApiEndpointConfiguration> {
    const environment = this.getEnvironment();
    return environment.recognitionApi;
  }

  getSentryConfiguration(): SentryConfiguration {
    const environment = this.getEnvironment();
    return environment.sentry;
  }

  getAnalyticsConfiguration(): AnalyticsConfiguration {
    const environment = this.getEnvironment();
    return environment.analytics;
  }

  getPrecontractDefaultUrl(): string {
    const environment = this.getEnvironment();
    return environment.precontractUrl;
  }

  getTestOfferConfiguration(): TestOfferConfiguration {
    const environment = this.getEnvironment();
    return environment.testOffer;
  }

  getPaypalConfiguration(): PaypalConfiguration {
    const environment = this.getEnvironment();
    return environment.paypal;
  }

  isPhotoCaptureEnabled(): boolean {
    const environment = this.getEnvironment();
    return environment.photoCaptureEnabled;
  }

  isCashbackEnabled(): boolean {
    const environment = this.getEnvironment();
    return environment.cashbackEnabled;
  }

  isEloquaEnabled(): boolean {
    const environment = this.getEnvironment();
    return environment.eloquaEnabled;
  }

  isSingleActionEnabled(): boolean {
    const environment = this.getEnvironment();
    return !!environment.singleActionManagerEnabled;
  }

  isSingleFlowLambdaEnabled(): boolean {
    const environment = this.getEnvironment();
    return !!environment.singleFlowManagerEnabled;
  }

  getTaxCodeEncodeUrl(): string {
    const environment = this.getEnvironment();
    return environment.taxcodeEncodeUrl;
  }

  getCitiesListUrl(): string {
    const environment = this.getEnvironment();
    return environment.citiesListUrl;
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promo-banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../box-info/box-info.component.ngfactory";
import * as i3 from "../../box-info/box-info.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./promo-banner.component";
import * as i7 from "../../../../../facades/app-store-facade.service";
import * as i8 from "../../../../../../app-shared/services/environment/environment.service";
import * as i9 from "../../../../../../app-shared/services/offer-promo/offer-promo.service";
import * as i10 from "../../../../../../app-shared/services/analytics/analytics.service";
var styles_PromoBannerComponent = [i0.styles];
var RenderType_PromoBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PromoBannerComponent, data: {} });
export { RenderType_PromoBannerComponent as RenderType_PromoBannerComponent };
function View_PromoBannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "sb-box-info", [], null, null, null, i2.View_BoxInfoComponent_0, i2.RenderType_BoxInfoComponent)), i1.ɵdid(3, 49152, null, 0, i3.BoxInfoComponent, [], { iconType: [0, "iconType"], transparent: [1, "transparent"], additionalCSSClasses: [2, "additionalCSSClasses"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "div", [["class", "promo-box-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "promo-box-title wt-text-bold-m"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_v.context.$implicit.icon || ("promo-" + _v.context.$implicit.promoType.toLowerCase())); var currVal_2 = _co.isInCart; var currVal_3 = (((_co.isInCart ? "cart-promo " : "") + "success-box-big promo-") + ((_v.context.$implicit.promoType.toLowerCase() + "-") + (_v.context.$implicit.type || "generic").toLowerCase())); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(3, "wt-text-base-l wt-margin-bottom-6 promo-box promo-banner promo-", _v.context.$implicit.promoType.toLowerCase(), " promo-", _v.context.$implicit.promoType.toLowerCase(), "-", (_v.context.$implicit.type || "generic").toLowerCase(), ""); _ck(_v, 1, 0, currVal_0); var currVal_4 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform((_v.context.$implicit.message || (("PROMO-" + _v.context.$implicit.promoType.toUpperCase()) + ".BANNER")))); _ck(_v, 5, 0, currVal_4); }); }
export function View_PromoBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "position-cart": 0, "multiple-promos": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromoBannerComponent_1)), i1.ɵdid(5, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.isInCart, (_co.promos && (_co.promos.length > 1))); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.promos; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_PromoBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sb-promo-banner", [], null, null, null, View_PromoBannerComponent_0, RenderType_PromoBannerComponent)), i1.ɵdid(1, 245760, null, 0, i6.PromoBannerComponent, [i7.AppStoreFacadeService, i8.EnvironmentService, i9.OfferPromoService, i10.AnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PromoBannerComponentNgFactory = i1.ɵccf("sb-promo-banner", i6.PromoBannerComponent, View_PromoBannerComponent_Host_0, { isInCart: "isInCart" }, {}, []);
export { PromoBannerComponentNgFactory as PromoBannerComponentNgFactory };

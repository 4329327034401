export enum DynamicEntityKey {
  ActivationMethod = 'activation-method',
  ActivationMnpInfo = 'activation-mnp-info',
  CouponInfo = 'coupon-info',
  CustomerContacts = 'customer-contacts',
  CustomerDeliveryAddress = 'customer-delivery-address',
  CustomerDocument = 'customer-document',
  CustomerIdentity = 'customer-identity',
  CustomerResidenceAddress = 'customer-residence-address',
  OfferExtras = 'offer-extras',
  FdoIccid = 'fdo-iccid',
  GeneralConsents = 'general-consents',
  MarketingConsents = 'marketing-consents',
  MgmInfo = 'mgm-info',
  PaymentBankInfo = 'payment-bank-info',
  PaymentCreditCardInfo = 'payment-creditcard-info',
  PaymentInvoiceOptions = 'payment-invoice-options',
  PaymentMethod = 'payment-method',
  PaypalTransactionInfo = 'paypal-transaction-info',
  SelectedAddons = 'selected-addons',
  ShippingMethod = 'shipping-method',
  SimType = 'sim-type',
  WinbackInfo = 'winback-info',
  OtpInfo = 'otp-info',
  SummaryInfo = 'summary-info'
}
export enum StaticEntityKey {
  AvailableAddons = 'available-addons',
  AvailableCountries = 'available-countries',
  AvailableOperators = 'available-operators',
  AvailableActivationMethods = 'available-activation-methods',
  AvailableShippingMethods = 'available-shipping-methods',
  AvailableSimTypes = 'available-sim-types',
  AvailableSteps = 'available-steps',
  Cart = 'cart',
  CustomerAvailableCompanyTypes = 'customer-available-companytypes',
  CustomerAvailableDocuments = 'customer-available-documents',
  DebugInfo = 'debug-info',
  FunnelConfiguration = 'funnel-configuration',
  Navigation = 'navigation',
  Offer = 'offer',
  OfferAlternative = 'offer-alternative',
  OfferPromoInfo = 'offer-promo-info',
  OrderSummary = 'order-summary',
  PaymentAvailableMethods = 'payment-available-methods',
  FunnelInfo = 'funnel-info',
  PaymentPaypalConfiguration = 'payment-paypal-configuration',
  PaymentSIAConfiguration = 'payment-sia-configuration',
  SmartTopUpPromo = 'smart-top-up-promo'
}
export type EntityKey = StaticEntityKey | DynamicEntityKey;

export interface EntityConstraintArguments {
  message?: string;
  value?: string;

  [key: string]: string | number;
}
export enum EntityConstraintType {
  Address = 'Address',
  AssertTrue = 'AssertTrue',
  CidOrPec = 'CidOrPec',
  DocumentDate = 'DocumentDate',
  DocumentValue = 'DocumentValue',
  Email = 'Email',
  EqualTo = 'EqualTo',
  Future = 'Future',
  FutureOrPresent = 'FutureOrPresent',
  Iban = 'Iban',
  Iccid = 'Iccid',
  IccidNoPrefix = 'IccidNoPrefix',
  Max = 'Max',
  Min = 'Min',
  NotBlank = 'NotBlank',
  NotEmpty = 'NotEmpty',
  NotFutureDate = 'NotFutureDate',
  NotNull = 'NotNull',
  NotPastDate = 'NotPastDate',
  NotStartsWith = 'NotStartwith',
  Past = 'Past',
  PastOrPresent = 'PastOrPresent',
  Size = 'Size',
  StartsWith = 'Startswith',
  Taxcode = 'TaxCode',
  Textual = 'Textual'
}

export type EntityValue = any;
export type EntityOptions = any;
export interface EntityConstraint {

  type: EntityConstraintType;
  params: EntityConstraintArguments;

  key?: string;
}

export interface EntityValueGroup {
  [key: string]: EntityValue;
}
export interface EntityOptionsGroup {
  [key: string]: EntityOptions;
}
export interface EntityConstraintGroup {
  [key: string]: EntityConstraint[];
}

export interface EntityParsed {
  value: EntityValueGroup;
  constraint: EntityConstraintGroup;
  options: EntityOptionsGroup;
}
export interface Entity {
  value: EntityValue;
  constraints: EntityConstraint[];
  options: EntityOptions;
}



import { Pipe, PipeTransform } from '@angular/core';
import { CartAnalysis, CartAnalysisActivationMethodsEnum } from 'models';

@Pipe({
  name: 'inferOperatorsCartList'
})
export class InferOperatorsCartListPipe implements PipeTransform {

  transform(cartAnalysis: CartAnalysis, operators: string[]): any {
    const getRealOperatorName = op => op.split('(Pre')[0].trim();
    const getUniqueOperatorList = list => Array.from(new Set(list.map(getRealOperatorName)))
      .sort((a: string, b: string) => {
        if (a.toLowerCase() > b.toLowerCase()) {
          return 1;
        } else if (a.toLowerCase() < b.toLowerCase()) {
          return -1;
        }
        return 0;
      });

    // Remove duplicates.
    const uniqueOperators = getUniqueOperatorList(operators);

    let message = `${uniqueOperators.slice(1, uniqueOperators.length - 1).join(', ')} e ${uniqueOperators[uniqueOperators.length - 1]}`;
    if (!cartAnalysis) { return ''; }
    if (cartAnalysis.activationMechanism === CartAnalysisActivationMethodsEnum.New) {
      message = '';
    } else {
      if (Object.keys(cartAnalysis.operatorsByPrice).length > 1) {
        const transformPrice = price => {
          return (price).toFixed(2).replace('.', ',');
        };

        // Take the lowest price from the operator prices, and add it with the sim price from the cart
        const lowestPriceValue = Math.min(...Object.keys(cartAnalysis.operatorsByPrice).map(r => parseFloat(r)));
        const highestPriceValue = Math.max(...Object.keys(cartAnalysis.operatorsByPrice).map(r => parseFloat(r)));
        const lowestPrice = transformPrice(lowestPriceValue + cartAnalysis.simPrice.value);
        const highestPrice = transformPrice(highestPriceValue + cartAnalysis.simPrice.value);
        const lowestOperators =
          getUniqueOperatorList(cartAnalysis.operatorsByPrice[lowestPriceValue].map(r => r.name));
        const highestOperators =
          getUniqueOperatorList(cartAnalysis.operatorsByPrice[highestPriceValue].map(r => r.name));

        message = `<span class="wt-text-base-s"><span class="wt-font-bold">Il costo di SIM e Attivazione &egrave; ${highestPrice}€
scontato a ${lowestPrice}€ per i clienti provenienti da:</span> ${lowestOperators.join(', ')}.</span>`;
        // <span class="wt-font-bold">Il costo di SIM e Attivazione rimane a ${highestPrice}€
        // per i clienti provenienti da:</span> ${highestOperators.join(', ')}.
      } else {
        message = `${uniqueOperators.slice(0, uniqueOperators.length - 1).join(', ')} e ${uniqueOperators[uniqueOperators.length - 1]}`;
      }
    }
    return message.replace(/\.\./gi, '.');
  }

}

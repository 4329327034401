import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from 'services';
import { HEADER_TOKEN_NAME } from '../../app-shared/services/auth/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    public authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    const updatedRequest = req.clone({
      headers: req.headers.set(HEADER_TOKEN_NAME, token)
    });

    return next.handle(token ? updatedRequest : req);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inferRepetitionsLabel'
})
export class InferRepetitionsLabelPipe implements PipeTransform {

  transform(repetitions: any): any {
    if (repetitions && repetitions > 0) {
      return `ENTITY.OFFER-EXTRAS.ADDONS.${repetitions}-MONTH-DISCOUNT`;
    }
  }

}

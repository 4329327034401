import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inferDataOnlyLabel'
})
export class InferDataOnlyLabelPipe implements PipeTransform {
  transform(gb, minutes, sms, productId): any {
    let html = '';
    const getInfoBoxHTML = (mainInfo, secondaryInfo) => `<div class="sb-cart-partial-offer-info__box">
        <div class=" sb-cart-partial-offer-info__main-info">${mainInfo}</div>
        <div class=" sb-cart-partial-offer-info__secondary-info">${secondaryInfo}</div>
        </div><div class="separator"></div>`;

    const getInfoBoxHTMLInverted = (mainInfo, secondaryInfo) => `<div class="sb-cart-partial-offer-info__box">
        <div class=" sb-cart-partial-offer-info__secondary-info">${secondaryInfo}</div>
        <div class=" sb-cart-partial-offer-info__main-info">${mainInfo}</div>
        </div><div class="separator"></div>`;

    const LABELS = {
      GB: 'Giga',
      MINUTES: 'Minuti',
      SMS: 'SMS',
      MINUTES_SMS: 'Minuti e SMS',
      UNLIMITED: 'illimitati'
    };

    if (gb != null) {
      if (gb > 0) {
        html += getInfoBoxHTML(gb, LABELS.GB);
      } else if (gb === 0) {
        html += getInfoBoxHTMLInverted(LABELS.UNLIMITED, LABELS.GB);
      }
    }
    if (productId === 'PO_Very_010423_DO_AVM_GA') {
      html += '<img src="/assets/images/rete-full-speed-new.svg" class="logo-full-speed">';
    } else {
      html += '<img src="/assets/images/rete-full-speed.svg" class="logo-full-speed">';
    }
    return html;
  }

}

import {
  SimType
} from '../entity-dynamic.interface';
import { DynamicEntityKey } from '../entity.interface';
import { ImageInterface } from '../image.interface';

export enum SimTypeEnum {
  Standard = 'standard',
  eSim = 'e-sim',
  IdleSim = 'idle-sim'
}

export interface StepSimTypeValue {
  simType: SimType;
}

export interface SimTypeType {
  id: string;
  title: string;
  images: ImageInterface[];
}

export interface StepSimTypeEntityData {
  [DynamicEntityKey.SimType]?: SimType;
}

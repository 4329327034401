import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DebugInfo, TestOfferConfiguration } from 'models';
import {EnvironmentService, ExecService} from 'services';

@Component({
  selector: 'sb-creation-buttons',
  templateUrl: './creation-buttons.component.html',
  styleUrls: ['./creation-buttons.component.scss']
})
export class CreationButtonsComponent implements OnInit, OnDestroy {

  public debugInfo: DebugInfo;
  public offerConfig: TestOfferConfiguration;
  private subscriptions = new Subscription();

  constructor(
    private environmentService: EnvironmentService,
    private execService: ExecService) { }

  ngOnInit() {
    this.offerConfig = this.environmentService.getTestOfferConfiguration() || ({} as unknown as TestOfferConfiguration);
  }

  private getOfferName(offerName?): string {
    return (window as any).offerName || offerName
      || (window as any).prompt('Inserisci il nome dell\'offerta', 'PO_DEA_GA');
  }

  createOffer(offerName?) {
    const offer = this.getOfferName(offerName);
    if (!offer) {
      return;
    }
    const [ productId, simType ] = offer.split('@');
    this.execService.resetFlow(productId, simType);
  }

  getFlowId(offerName?) {
    const offer = this.getOfferName(offerName);
    if (!offer) {
      return;
    }
    const [ productId, simType ] = offer.split('@');
    this.execService.getNewFlow(productId, simType, {}, (res) => {
      // tslint:disable-next-line:no-console
      console.log(res.redirect.split('init/').pop());
      alert(res.redirect.split('init/').pop());
    });
  }

  generateLocalFlow(offerName?) {
    const offer = this.getOfferName(offerName);
    if (!offer) {
      return;
    }
    const [ productId, simType ] = offer.split('@');
    this.execService.getNewFlow(productId, simType, {}, (res) => {
      window.location.href = `${window.location.origin}/init/${res.redirect.split('init/').pop()}`;
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

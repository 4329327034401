import { createAction, props } from '@ngrx/store';

import { BasketData, EntityKey, StepId } from 'models';

export enum BasketActionTypes {
  GetEntitiesData = '[Basket] GetEntitiesData',
  GetStepData = '[Basket] GetStepData',
  GetMultipleStepData = '[Basket] GetMultipleStepData',
  PostEntitiesData = '[Basket] PostEntitiesData',
}

export const getEntitiesData = createAction(
  BasketActionTypes.GetEntitiesData,
  props<{ entities: EntityKey[] }>()
);

export const getStepData = createAction(
  BasketActionTypes.GetStepData,
  props<{ stepId: StepId, ignoredEntitiesKeys: EntityKey[] }>()
);

export const getMultipleStepData = createAction(
  BasketActionTypes.GetMultipleStepData,
  props<{stepIds: StepId[], ignoredEntitiesKeys: EntityKey[] }>()
);

export const postEntitiesData = createAction(
  BasketActionTypes.PostEntitiesData,
  props<{ data: BasketData }>()
);

export type BasketActionsUnion = ReturnType<
  | typeof getEntitiesData
  | typeof getStepData
  | typeof getMultipleStepData
  | typeof postEntitiesData
>;

import { InjectionToken } from '@angular/core';

// Window provider
export function windowFactory(): Window { return window; }
export const WINDOW = new InjectionToken<Window>('window');
export const WINDOW_PROVIDER = { provide: WINDOW, useFactory: windowFactory };

// Session storage provider
export function sessionStorageFactory(): WindowSessionStorage { return windowFactory().sessionStorage as unknown as WindowSessionStorage; }
export const SESSION_STORAGE = new InjectionToken<WindowSessionStorage>('sessionStorage');
export const SESSION_STORAGE_PROVIDER = { provide: SESSION_STORAGE, useFactory: sessionStorageFactory };


import { MacroStepId, StepId } from 'models';

export interface NavigationState {
  currentStepId: StepId;
  currentMacroStepId: MacroStepId;
  nextStepId: StepId;
  previousStepId: StepId;
  history: StepId[];
}

export const initialNavigationState: NavigationState = {
  currentStepId: null,
  currentMacroStepId: null,
  nextStepId: null,
  previousStepId: null,
  history: []
};

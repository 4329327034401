import { createAction, props } from '@ngrx/store';

export enum HeaderActionTypes {
  SetToken = '[Header] SetToken',
}

export const setToken = createAction(
  HeaderActionTypes.SetToken,
  props<{ token: string }>()
);

export type HeaderActionsUnion = ReturnType<
  | typeof setToken
  >;

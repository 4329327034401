/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./creation-buttons.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./creation-buttons.component";
import * as i4 from "../../../../../app-shared/services/environment/environment.service";
import * as i5 from "../../../../../app-shared/services/exec/exec.service";
var styles_CreationButtonsComponent = [i0.styles];
var RenderType_CreationButtonsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreationButtonsComponent, data: {} });
export { RenderType_CreationButtonsComponent as RenderType_CreationButtonsComponent };
function View_CreationButtonsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["class", "btn-offer"], ["type", "button"]], [[8, "value", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createOffer(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "Crea ", _v.context.$implicit, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_CreationButtonsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "debug-info wt-text-base-s"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["class", "btn-offer"], ["type", "button"], ["value", "Crea offerta generica"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createOffer() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "input", [["class", "btn-offer"], ["type", "button"], ["value", "Ottieni ID flusso"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getFlowId() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["class", "btn-offer"], ["type", "button"], ["value", "Genera flusso locale su ambiente"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.generateLocalFlow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreationButtonsComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.offerConfig.offers; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_CreationButtonsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreationButtonsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.offerConfig && _co.offerConfig.offers); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CreationButtonsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sb-creation-buttons", [], null, null, null, View_CreationButtonsComponent_0, RenderType_CreationButtonsComponent)), i1.ɵdid(1, 245760, null, 0, i3.CreationButtonsComponent, [i4.EnvironmentService, i5.ExecService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreationButtonsComponentNgFactory = i1.ɵccf("sb-creation-buttons", i3.CreationButtonsComponent, View_CreationButtonsComponent_Host_0, {}, {}, []);
export { CreationButtonsComponentNgFactory as CreationButtonsComponentNgFactory };

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { BasketValueData, MacroStepId, StaticEntityKey, Step, StepId } from 'models';
import { FlowService } from '../../../../app-shared/services/flow/flow.service';
import { NavigationStoreFacadeService } from '../../../facades/navigation-store-facade/navigation-store-facade.service';
import { NavigationActions, NavigationHelpers } from '../../navigation-feature-store';
import * as BasketActions from '../actions/basket.actions';
import * as FlowActions from '../actions/flow.actions';


@Injectable()
export class FlowEffects {


  getUnknownStepData$ =  createEffect(() => this.actions$.pipe(
    ofType(FlowActions.getStepData),
    filter((action) => !Boolean(action.stepId)),
    switchMap(() => this.flowService.getStepData().pipe(catchError(() => EMPTY))),
    map((step: Step) => NavigationHelpers.inferNavigateToAction(step.id as StepId))
  ));


  getStepData$ = createEffect(() => this.actions$.pipe(
    ofType(FlowActions.getStepData),
    filter((action) => Boolean(action.stepId)),
    switchMap((action) => this.flowService.getStepData(action.stepId)),
    withLatestFrom(this.route.queryParams),
    switchMap(([ step, queryParams ]) => {
      // TODO change, verify if is in Process
      const macroStep = step.id === StepId.Success ? '' : `/${ MacroStepId.Process }/`;
      return [
        BasketActions.getStepData({ stepId: step.id, ignoredEntitiesKeys: [] }),
        NavigationActions.navigateTo({ commands: [ `${ macroStep }${ step.id }` ], extras: { queryParams } })
      ];
    })
  ));


  postStepData$ = createEffect(() => this.actions$.pipe(
    ofType(FlowActions.postStepData),
    withLatestFrom(
      this.navigationStoreFacadeService.navigation.history$,
      this.navigationStoreFacadeService.navigation.currentStepId$
    ),
    switchMap(([ action, history, currentStep ]) => {
      const data: BasketValueData = {
        ...action.data,
        navigation: { history: history.slice(0, currentStep ? history.indexOf(currentStep) + 1 : undefined) }
      };

      return this.flowService.postStepData(data, action.stepId).pipe(catchError(() => EMPTY));
    }),
    switchMap((step: Step) => [
      BasketActions.getStepData( {
        stepId: step.id,
        ignoredEntitiesKeys: [
          StaticEntityKey.Navigation,
          // StaticEntityKey.Cart,
          // StaticEntityKey.Offer,
          StaticEntityKey.DebugInfo
        ]
      }),
      NavigationActions.setNavigationCurrentStepId({ stepId: step.id }),
      NavigationActions.pushToNavigationHistory({ step: step.id }),
      NavigationActions.navigateToNext()
    ])
  ));

  constructor(
    private navigationStoreFacadeService: NavigationStoreFacadeService,
    private actions$: Actions,
    private route: ActivatedRoute,
    private flowService: FlowService
  ) {}
}

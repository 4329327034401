import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import MoveTo from 'moveto';
import { Observable, Subject } from 'rxjs';
import { WINDOW } from '../../../app/providers/browser.provider';

@Injectable()
export class ScrollService {
  private scroll: MoveTo;
  private scrollOptions = {
    tolerance: 0,
    duration: 800,
    easing: 'easeOutQuart',
    container: this.window
  };
  private subject = new Subject<any>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window
  ) {
    this.scroll = new MoveTo(this.scrollOptions);
  }

  public scrollToFirstInvalidInput() {
    setTimeout(() => {
      // Use timeout to ensure that invalid classes are already present on DOM
      const selector = '.ng-invalid.ng-touched:not(form)';
      const invalidInputElement = this.document.querySelector(selector);
      const HEIGHT_TOLERANCE = 85;
      if (invalidInputElement) {
        this.scroll.move(invalidInputElement.getBoundingClientRect().top - HEIGHT_TOLERANCE);
      }
    }, 20);
  }

  public scrollToTop() {
    setTimeout(() => {
      // Scroll after angular renders view
      this.scroll.move(Number.NEGATIVE_INFINITY, this.scrollOptions);
    }, 0);
  }
  public setSticky(isSticky) {
    this.subject.next(isSticky);
  }
  public stickyStatus(): Observable<any> {
    return this.subject.asObservable();
  }
}

import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {fadeAnimation} from 'animations';
import {
  ActivationMethodEnum, AvailableAddons,
  AvailableOperators,
  CartAnalysis,
  CartAnalysisActivationMethodsEnum, EntityParsed,
  InternalPromoInfo,
  Offer, OfferExtras,
  OfferPromoInfo, PROMO_TYPES, SelectedAddons,
  TotalPriceEnum
} from 'models';
import {Subscription} from 'rxjs';
import {filter, first, withLatestFrom} from 'rxjs/operators';
import {OfferPromoService} from 'services';
import {DataService} from '../../../../../../app-shared/services/data/data.service';
import {AppStoreFacadeService} from '../../../../../facades/app-store-facade.service';


@Component({
  selector: 'sb-cart-partial-offer-info',
  templateUrl: './cart-partial-offer-info.component.html',
  styleUrls: ['./cart-partial-offer-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeAnimation]
})
export class CartPartialOfferInfoComponent implements OnInit, OnDestroy {

  @Input() offer: Offer;
  @Input() offerAlternative!: Offer;
  @Input() offerExtras!: OfferExtras | any;
  @Input() hasDoublePO!: boolean;
  @Input() offerpromo: OfferPromoInfo;
  @Input() activationMethods: string[];
  @Input() operators: string[];

  public cartAnalysis: CartAnalysis;
  public shouldShowOperators = false;
  public cart: any;
  public promos: InternalPromoInfo[];
  public currentOffer: Offer;
  smartTopUpIsSelected: any;
  private subscription: Subscription;
  public deltaGiga: number;

  constructor(
    private appStoreFacadeService: AppStoreFacadeService,
    private offerPromoService: OfferPromoService,
    private dataService: DataService) {}

  get shouldShowToggle() {
    return (this.cartAnalysis && this.operators) &&
      this.cartAnalysis.activationMechanism !== CartAnalysisActivationMethodsEnum.New && this.operators.length > 3;
  }

  private getAvailableActivationMethods(): CartAnalysisActivationMethodsEnum {
    let actMethod: CartAnalysisActivationMethodsEnum;
    if (this.activationMethods) {
      if (this.activationMethods.includes(ActivationMethodEnum.New)) {
        if (this.activationMethods.includes(ActivationMethodEnum.Mnp)) {
          actMethod = CartAnalysisActivationMethodsEnum.Both;
        } else {
          actMethod = CartAnalysisActivationMethodsEnum.New;
        }
      } else {
        actMethod = CartAnalysisActivationMethodsEnum.Mnp;
      }
    }
    return actMethod;
  }

  ngOnInit(): void {
    // groups operator by price
    this.appStoreFacadeService.entity.availableOperators$
      .pipe(
        first(Boolean),
        withLatestFrom(this.appStoreFacadeService.cart.cart$),
        filter((args) => args.every(Boolean))
      )
      .subscribe(
      ([ entity, cart] ) => {
        const operatorList = (entity as unknown as AvailableOperators).operators || [];
        const priceMap = {};
        operatorList.forEach(op => {
          priceMap[op.configuration.activationPrice.value] = priceMap[op.configuration.activationPrice.value] || [];
          priceMap[op.configuration.activationPrice.value].push(op);
        });
        this.cart = cart;
        this.cartAnalysis = {
          simPrice: cart.totalPrices.find(p => p.type === TotalPriceEnum.Sim).price,
          operatorsByPrice: priceMap,
          activationMechanism: this.getAvailableActivationMethods()
        };
      }
    );
    // promos
    this.appStoreFacadeService.entity.offerPromoInfo$
      .pipe(
        withLatestFrom(
          this.appStoreFacadeService.entity.offer$,
          this.appStoreFacadeService.entity.couponInfo$
        ),
        filter(args => args.every(Boolean))
      ).subscribe(([ offerPromoEntity, offer, couponInfo ]) => {
      if (!offerPromoEntity || !offer) {
        return;
      }
      // Verify if offer promo info has changed to show the modal.
      this.promos = this.offerPromoService.elaborateOfferPromoInfo(offerPromoEntity, offer, couponInfo);
    });
    this.subscription = this.dataService.currentValue.subscribe(value => {
      this.smartTopUpIsSelected = value;
    });
    this.currentOffer = this.manageCurrentOffer();
    this.deltaGiga = this.getDeltaGiga();
  }

  public manageCurrentOffer() {
    if (this.smartTopUpIsSelected == null) {
      this.offerExtras = this.offerExtras && this.offerExtras.smartTopUp ? this.offerExtras.smartTopUp : false;
    } else {
      this.offerExtras = this.smartTopUpIsSelected;
    }

    if (this.hasDoublePO && this.offerExtras) {
      return this.offerAlternative;
    } else {
      return this.offer;
    }
  }

  public getDeltaGiga() {
    return this.deltaGiga;
  }

  toggle() {
    this.shouldShowOperators = !this.shouldShowOperators;
  }

  get shouldShowGigax2() {
    return !!this.promos.find(promo => promo.type === 'GIGAX2');
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

<div class="error-page">
  <sb-header [showCart]="false" title=""></sb-header>
  <div class="error-page__container">
    <div class="error-page__content">
      <div class="error-page__title">
        {{'STEP.OLD-BROWSER.TITLE' | translate}}
      </div>
      <div class="error-page__subtitle" [innerHTML]="'STEP.OLD-BROWSER.SUBTITLE' | translate"></div>
      <div class="error-page__content--with-icons"></div>
    </div>
  </div>
</div>

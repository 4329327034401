import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UiKitLibModule } from 'ui-kit-lib';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorComponent } from './components/error/error.component';
import { InitComponent } from './components/init/init.component';
import { ModalsComponent } from './components/modals/modals.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { OldBrowserComponent } from './components/old-browser/old-browser.component';

import { SpinnerComponent } from './components/spinner/spinner.component';

import { WarningComponent } from './components/warning/warning.component';
import { ANIMATION_ENGINE_PROVIDER } from './providers/animation.provider';
import { SESSION_STORAGE_PROVIDER, WINDOW_PROVIDER } from './providers/browser.provider';

import { RootStoreModule } from './store';

import { LOCALE_PROVIDER } from './providers/locale.provider';

import {
  AnalyticsService,
  AuthService,
  BasketService, DatesService,
  EnvironmentService,
  ErrorsService,
  ExecService,
  FlowService,
  FormsService,
  OfferPromoService,
  PaypalService,
  RecognitionService,
  ScrollService,
  ValidatorsService
} from 'services';
import { AppStoreFacadeService } from './facades/app-store-facade.service';
import { EntityStoreFacadeService } from './facades/entity-store-facade/entity-store-facade.service';

import { NavigationStoreFacadeService } from './facades/navigation-store-facade/navigation-store-facade.service';
import { ProcessFeatureStoreFacadeService } from './facades/process-feature-store-facade/process-feature-store-facade.service';

import { SharedModule } from './modules/shared/shared.module';
import { TranslationModule } from './modules/translate/translation.module';

import { RequestsInterceptor } from './interceptors/requests.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';

const appComponents = [
  AppComponent,
  ErrorComponent,
  InitComponent,
  OldBrowserComponent,
  ModalsComponent,
  NotFoundComponent,
  SpinnerComponent,
  WarningComponent
];

const appProviders = [
  ANIMATION_ENGINE_PROVIDER,
  LOCALE_PROVIDER,
  WINDOW_PROVIDER,
  SESSION_STORAGE_PROVIDER
];

const appServices = [
  AnalyticsService,
  AppStoreFacadeService,
  AuthService,
  BasketService,
  DatesService,
  ProcessFeatureStoreFacadeService,
  EntityStoreFacadeService,
  EnvironmentService,
  ErrorsService,
  ExecService,
  FlowService,
  FormsService,
  NavigationStoreFacadeService,
  OfferPromoService,
  PaypalService,
  RecognitionService,
  ScrollService,
  ValidatorsService,
];

const appInterceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestsInterceptor,
    multi: true
  }
];

// Translation
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    ...appComponents,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // NGX Translate
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TranslationModule.forRoot(),
    // Custom Module
    UiKitLibModule,
    AppRoutingModule,
    RootStoreModule,
    SharedModule,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ReactiveFormsModule,
  ],
  providers: [
    ...appInterceptors,
    ...appProviders,
    ...appServices
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { DynamicEntityKey, StepId, StepShippingMethodEntityData, StepShippingValue } from 'models';
import { map, withLatestFrom } from 'rxjs/operators';
import { EntityStoreFacadeService } from '../../../../facades/entity-store-facade/entity-store-facade.service';
import { NavigationActions, NavigationHelpers } from '../../../navigation-feature-store';
import { postStepData } from '../../actions/flow.actions';

@Injectable()
export class StepShippingMethodEffects {

  private filterByStep = NavigationHelpers.filterByStepFactory(this.entityStoreFacadeService.store$);

  goNext$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.goNext),
    this.filterByStep(StepId.Shipping),
    withLatestFrom(this.entityStoreFacadeService.step.shippingValue$),
    map(([ , stepData ]: [ any, StepShippingValue ]) => {
      const { shippingMethod } = stepData;
      const dataPayload: StepShippingMethodEntityData = {
        [DynamicEntityKey.ShippingMethod]: shippingMethod
      };

      return postStepData({ stepId: StepId.Shipping, data: dataPayload });
    })
  ));

  constructor(
    private actions$: Actions,
    private entityStoreFacadeService: EntityStoreFacadeService
  ) {}
}

import { createAction, props } from '@ngrx/store';

export enum SpinnerActionTypes {
  UpdateSpinnerStatus = '[Spinner] UpdateSpinnerStatus',
}

export const updateSpinnerStatus = createAction(
  SpinnerActionTypes.UpdateSpinnerStatus,
  props<{ status: boolean }>()
);

export type SpinnerActionsUnion = ReturnType<
  | typeof updateSpinnerStatus
  >;

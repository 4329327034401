import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import get from 'lodash/get';

import { RouterStateUrl } from './route.state';

const findParentPath = (route: ActivatedRouteSnapshot): string => {
  let latestParentWithPath = route.parent;
  while (latestParentWithPath) {
    const path = get(latestParentWithPath, 'routeConfig.path', undefined);
    if (path) {
      return path;
    }

    latestParentWithPath = latestParentWithPath.parent;
  }

  return null;
};

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    while (route.firstChild) { route = route.firstChild; }

    const url = get(routerState, 'url', undefined);
    const queryParams = get(routerState, 'root.queryParams', {});
    const params = get(route, 'params', {});
    const componentUrlPath = get(route, 'routeConfig.path', undefined);
    const componentParentUrlPath = findParentPath(route);

    return { url, params, queryParams, componentParentUrlPath, componentUrlPath };
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { EntityFeatureStoreModule } from './entity-feature-store';
import { NavigationFeatureStoreModule } from './navigation-feature-store';
import { ProcessFeatureStoreModule } from './process-feature-store';
import { RouteFeatureStoreModule } from './route-feature-store';

@NgModule({
  imports: [
    CommonModule,
    // Store
    StoreModule.forRoot({}, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot([]),
    // Feature store modules
    ProcessFeatureStoreModule,
    EntityFeatureStoreModule,
    NavigationFeatureStoreModule,
    RouteFeatureStoreModule
  ]
})
export class RootStoreModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RecognitionUrl } from '../../../app/modules/recognition/models/recognition.interface';

import { EnvironmentService } from '../environment/environment.service';
import { ExecService } from '../exec/exec.service';

@Injectable()
export class RecognitionService {

  private readonly recognitionEndpoint: string;

  constructor(
    private environmentService: EnvironmentService,
    private execService: ExecService,
    private http: HttpClient
  ) {
    const apiConfiguration = this.environmentService.getRecognitionApiConfiguration();
    this.recognitionEndpoint = `${ apiConfiguration.host }${ apiConfiguration.endpoint.form }`;
  }

  generateRecognitionFormRequestUrl(): string {
    return `${ this.recognitionEndpoint }`;
  }

  getRecognitionUrl(iccid: string, taxCode: string, param: boolean): Observable<RecognitionUrl> {
    return this.http.post<RecognitionUrl>(this.generateRecognitionFormRequestUrl(), {
      iccid,
      taxCode: taxCode.toString().toUpperCase(),
      changeSim: param
    });
  }

  getVrLinkRecognition(iccid: string, taxCode: string): Observable<RecognitionUrl> {
    return this.execService.getVrLink(iccid, taxCode);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inferFrequencyLabel'
})
export class InferFrequencyLabelPipe implements PipeTransform {

  transform(value: any, special: any, itemType: any): any {
    if (special && itemType === 'offer' ) {
      return value ? 'ENTITY.CART.PRICE.FREQUENCY.FIRST-TWO-MONTHS' : null;
    }
    if (itemType === 'addon') {
      return value ? 'ENTITY.CART.PRICE.ADDON-FREQUENCY.MONTHLY' : null;
    }
    return value ? `ENTITY.CART.PRICE.FREQUENCY.${value.toUpperCase()}` : null;
  }

}

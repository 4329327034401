import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BasketValueData, Step, StepId } from 'models';
import { EnvironmentService } from '../environment/environment.service';

@Injectable()
export class FlowService {

  private readonly flowEndpoint: string;

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient
  ) {
    const apiConfiguration = this.environmentService.getApiConfiguration();
    this.flowEndpoint = `${ apiConfiguration.host }${ apiConfiguration.endpoint.flow }`;
  }

  getStepData(stepId?: StepId): Observable<Step> {
    const options = stepId ? { params: { stepId } } : {};
    return this.http.get<Step>(this.flowEndpoint, options);
  }

  postStepData(data: BasketValueData, stepId?: StepId): Observable<Step> {
    if (this.environmentService.isSingleFlowLambdaEnabled()) {
      const newData = {
        ...data,
        'backend-flow': { stepId }
      };
      return this.http.post<Step>(this.flowEndpoint, newData, { params: { stepId: 'manager' } });
    } else {
      return this.http.post<Step>(this.flowEndpoint, data, { params: { stepId } });
    }
  }
}

import { Price, TotalPriceEnum } from './cart.interface';
import { StepId } from './step.interface';
import { ActivationMethodEnum } from './steps/step-activation.interface';
import {OfferAddons} from './steps/step-offer-extras.interface';
import { PaymentMethodEnum } from './steps/step-payment-method.interface';
import { ShippingMethodEnum } from './steps/step-shipping.interface';
import { SimTypeEnum } from './steps/step-sim.interface';

export interface FunnelInfo {
  channel?: string;
  accessToken?: string;
  returnUrl: string;
  hasCoupon?: boolean;
  isInviteCodeEnabled?: boolean;
  isCouponEnabled?: boolean;
  isSmartTopUpMandatory?: boolean;
  isSmartTopUpEnabled?: boolean;
  payByLinkValidityHours?: number;
  isPaidAddonEnabled?: boolean;
  isStuOfferDoubleConfigEnabled?: boolean;
}

export interface FunnelConfiguration {
  initialQuote: Price;
}

export interface PaymentAvailableMethods {
  methods: PaymentMethodEnum[];
}

export interface AvailableAddons {
  addons: OfferAddons[];
}

export interface AvailableShippingMethods {
  methods: ShippingMethodEnum[];
}

export interface AvailableSimTypes {
  types: SimTypeEnum[];
}

export interface AvailableSteps {
  steps: StepId[];
}

export interface AvailableActivationMethods {
  methods: ActivationMethodEnum[];
}

export interface OrderSummary {
  orderId: string;
  msisdn: string;
  iccid?: string;
  taxCode?: string;
}

export interface GenericDiscountInfo {
  type: string;
  info: string;
}

export interface PromoInfo {
  promoType: PROMO_TYPES;
  type?: string;
  info?: string;
}

export interface InternalPromoInfo {
  promoType: PROMO_TYPES;
  type?: any;
  info?: any;
  message?: any;
  icon?: any;
  messageSuccessPage?: any;
  hideInSuccessPage?: boolean;
}

export enum PROMO_TYPES {
  DISCOUNT = 'DISCOUNT',
  ADDON = 'ADDON',
  COUPON = 'COUPON',
  CASHBACK = 'CASHBACK',
  CASHBACK_DOUBLE = 'CASHBACK-DOUBLE',
  ACTIVATION_FEE = 'ACTIVATION-FEE',
  HALLOWEEN = 'HALLOWEEN',
  FLASH = 'FLASH',
  XMAS = 'XMAS',
  SUMMER_FLASH = 'SUMMER_FLASH',
  EXCLUSIVE_DISCOUNT = 'EXCLUSIVE_DISCOUNT',
  SUPER_FLASH = 'SUPER_FLASH',
  FLASH_BACK = 'FLASH_BACK',
  BLACK_FRIDAY = 'BLACK_FRIDAY',
  GIGA_SPECIAL = 'GIGA_SPECIAL',
  FLASH_XL = 'FLASH_XL',
  FLASH_BACK_XL = 'FLASH_BACK_XL',
  UNLIMITED = 'UNLIMITED'
}

export enum RIBBON_TYPES {
  DISCOUNT = 'pink',
  ADDON = 'yellow',
  ADDON_GIGA = 'yellow',
  ADDON_GIGAX2 = 'gigax2',
  ADDON_XMAS = 'dark-red-xmas',
  CASHBACK = 'blue',
  COUPON = 'green',
  CASHBACK_DOUBLE = 'blue',
  ACTIVATION_FEE = 'pink',
  LIMITED = 'green',
  HALLOWEEN = 'violet',
  FLASH = 'yellow',
  XMAS = 'dark-red-xmas',
  DEFAULT = 'transp',
  SUMMER_FLASH = 'transp',
  EXCLUSIVE_DISCOUNT = 'transp',
  SUPER_FLASH = 'transp',
  FLASH_BACK = 'transp',
  BLACK_FRIDAY = 'transp',
  GIGA_SPECIAL = 'transp',
  FLASH_XL = 'transp',
  FLASH_BACK_XL = 'transp',
  UNLIMITED = 'transp'
}

export interface OfferPromoInfo {
  promos: PromoInfo[];
}

export interface AvailableOperatorConfiguration {
  activationPrice?: Price;
}

export interface AvailableOperator {
  id: string;
  name: string;
  donatingCode: string;
  configuration?: AvailableOperatorConfiguration;
}

export interface AvailableOperators {
  operators: AvailableOperator[];
}

export interface CustomerAvailableCompanyTypes {
  companyTypes: string[];
}

export interface CustomerAvailableDocuments {
  documents: string[];
}

export interface AvailableCountry {
  name: string;
  id: string;
}

export interface AvailableCountries {
  countries: AvailableCountry[];
}

export interface Cart {
  totalPrices: TotalPrice[];
  totalPrice: TotalPrice;
}

export interface TotalPrice {
  price: Price;
  title?: string;
  type: TotalPriceEnum;
  activationPrice?: Price;
}

export interface Offer {
  productId?: string;
  ropz?: string;
  name: string;
  activationPrice: Price;
  price: Price;
  gb: number;
  minutes?: number;
  sms?: number;
  offerType?: string;
  previousGb?: number;
}

export interface DebugInfo {
  cartID: string;
  basketID: string;
}

export interface PaymentPaypalConfiguration {
  amount: number;
  currency: string;
  orderID: string;
}

export interface PaymentSIAConfiguration {
  redirectUrl: string;
  paymentItems: PaymentSIAItem[];
}

export interface PaymentSIAItem {
  name: string;
  value: string;
}

export interface SmartTopUpPromo {
  title?: string;
  subtitle?: string;
}

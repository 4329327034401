import { Routes } from '@angular/router';
import { MacroStepId } from 'models';
import { ErrorComponent } from './components/error/error.component';
import { InitComponent } from './components/init/init.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { OldBrowserComponent } from './components/old-browser/old-browser.component';
import { WarningComponent } from './components/warning/warning.component';
const ɵ0 = { animation: MacroStepId.Process }, ɵ1 = () => import("./modules/process/process.module.ngfactory").then(mod => mod.ProcessModuleNgFactory), ɵ2 = { animation: MacroStepId.Success }, ɵ3 = () => import("./modules/success/success.module.ngfactory").then(mod => mod.SuccessModuleNgFactory), ɵ4 = { animation: MacroStepId.Recognition }, ɵ5 = () => import("./modules/recognition/recognition.module.ngfactory").then(mod => mod.RecognitionModuleNgFactory), ɵ6 = { animation: MacroStepId.Error }, ɵ7 = { animation: MacroStepId.NotFound };
const routes = [
    {
        path: `${MacroStepId.Initialization}/:token`,
        component: InitComponent
    },
    {
        path: MacroStepId.Process,
        data: ɵ0,
        loadChildren: ɵ1,
    },
    {
        path: MacroStepId.Success,
        data: ɵ2,
        loadChildren: ɵ3,
    },
    {
        path: MacroStepId.Recognition,
        data: ɵ4,
        loadChildren: ɵ5,
    },
    {
        path: MacroStepId.Error,
        data: ɵ6,
        component: ErrorComponent,
    },
    {
        path: MacroStepId.Warning,
        component: WarningComponent,
    },
    {
        path: MacroStepId.OldBrowser,
        component: OldBrowserComponent
    },
    {
        path: MacroStepId.NotFound,
        data: ɵ7,
        component: NotFoundComponent,
    },
    {
        path: '',
        redirectTo: MacroStepId.Recognition,
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: MacroStepId.NotFound
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };

import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { map, switchMap, tap } from 'rxjs/operators';

import { MacroStepId, StepId } from 'models';
import { WINDOW } from '../../providers/browser.provider';
import { RouterStateUrl } from '../route-feature-store/route.state';
import * as NavigationActions from './navigation.actions';

@Injectable()
export class NavigationEffects {

  setQueryParams$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.setQueryParams),
    tap(action => {
      this.router.navigate([], {
        queryParams: action.queryParams
      });
    })
  ), { dispatch: false });

  navigateTo$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.navigateTo),
    tap(action => {
      if (action.external) {
        const url = action.commands[0];

        if (!url) {
          console.warn('Missing external url in NavigateTo action');
          return;
        }

        this.window.location.href = url;
      } else {
        this.router.navigate(action.commands, action.extras);
      }
    })
  ), { dispatch: false });


  navigateToErrorPage$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.navigateToErrorPage),
    map(({ errorMessage }) => {
      const queryParams = errorMessage ? { textNote: errorMessage } : {};
      return NavigationActions.navigateTo({ commands: [ `/${ MacroStepId.Error }` ],  extras: { queryParams } });
    })
  ));

  navigateToWarningPage$ = createEffect(() => this.actions$.pipe(
    ofType(NavigationActions.navigateToWarningPage),
    map(({ errorMessage }) => {
      const queryParams = errorMessage ? errorMessage : {};
      return NavigationActions.navigateTo({ commands: [ `/${ MacroStepId.Warning }` ],  extras: { queryParams } });
    })
  ));


  navigationEnd$ = createEffect(() => this.actions$.pipe(
    ofType<RouterNavigatedAction<RouterStateUrl>>(ROUTER_NAVIGATED),
    switchMap((action: RouterNavigatedAction<RouterStateUrl>) => {
      const routerStateUrl: RouterStateUrl = action.payload.routerState;
      const { componentUrlPath, componentParentUrlPath } = routerStateUrl;

      return [
        NavigationActions.setNavigationCurrentMacroStepId({ macroStepId: componentParentUrlPath as MacroStepId }),
        NavigationActions.setNavigationCurrentStepId({ stepId: componentUrlPath as StepId })
      ];
    })
  ));

  constructor(
    private actions$: Actions,
    private router: Router,
    @Inject(WINDOW) private window: Window
  ) {}
}

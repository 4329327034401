import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

import * as TranslationActions from '../actions/translation.actions';


@Injectable()
export class TranslationEffects {


  setTranslations$ = createEffect(() => this.actions$.pipe(
    ofType(TranslationActions.setTranslations),
    tap(action => {
      this.translateService.setTranslation(action.lang, action.translations, action.shouldMerge);
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private translateService: TranslateService
  ) {}
}

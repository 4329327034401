/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./box-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./box-info.component";
var styles_BoxInfoComponent = [i0.styles];
var RenderType_BoxInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BoxInfoComponent, data: {} });
export { RenderType_BoxInfoComponent as RenderType_BoxInfoComponent };
function View_BoxInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "sb-box-info__text wt-text-base-l"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.infoText; _ck(_v, 1, 0, currVal_0); }); }
function View_BoxInfoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_BoxInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "sb-box-info__image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "alt", 0], [8, "src", 4], [8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoxInfoComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoxInfoComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.infoText; _ck(_v, 4, 0, currVal_4); var currVal_5 = !_co.infoText; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(3, "sb-box-info ", _co.additionalCSSClasses, " ", (_co.transparent ? "sb-box-info--transparent" : ""), " ", (_co.autowidth ? "sb-box-info--autowidth" : ""), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.iconType, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "/assets/images/", (_co.iconType.includes(".") ? _co.iconType : (_co.iconType + ".svg")), ""); var currVal_3 = i1.ɵinlineInterpolate(1, "sb-box-info__icon ", _co.iconType, ""); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_BoxInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sb-box-info", [], null, null, null, View_BoxInfoComponent_0, RenderType_BoxInfoComponent)), i1.ɵdid(1, 49152, null, 0, i3.BoxInfoComponent, [], null, null)], null, null); }
var BoxInfoComponentNgFactory = i1.ɵccf("sb-box-info", i3.BoxInfoComponent, View_BoxInfoComponent_Host_0, { infoText: "infoText", iconType: "iconType", transparent: "transparent", autowidth: "autowidth", additionalCSSClasses: "additionalCSSClasses" }, {}, ["*"]);
export { BoxInfoComponentNgFactory as BoxInfoComponentNgFactory };

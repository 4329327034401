import {Action} from '@ngrx/store';
import difference from 'lodash/difference';

import {BasketData, BasketValueData} from '../../../app-shared/models/basket.interface';
import {
  DynamicEntityKey,
  Entity,
  EntityKey,
  EntityParsed,
  StaticEntityKey
} from '../../../app-shared/models/entity.interface';
import {StepId, StepRequiredEntitiesKeysMap} from '../../../app-shared/models/step.interface';
import {DatesService} from '../../../app-shared/services/dates/dates.service';
import {EntityActions} from '../entity-feature-store';
import {NavigationActions} from '../navigation-feature-store';

export const convertToParsedEntity = (entity: Entity): EntityParsed => {
  const entityItemsKeys = Object.keys(entity || {});
  const parsedEntity: EntityParsed = { value: {}, constraint: {}, options: {} };

  entityItemsKeys.forEach((entityItemKey: string) => {
    parsedEntity.value = { ...parsedEntity.value, [entityItemKey]: entity[entityItemKey].value };
    parsedEntity.constraint = { ...parsedEntity.constraint, [entityItemKey]: entity[entityItemKey].constraints };
    parsedEntity.options = { ...parsedEntity.options, [entityItemKey]: entity[entityItemKey].options };
  });

  return parsedEntity;
};

export const parseStaticEntities = (basketData: BasketValueData): Action[] => {
  const entitiesKeys = Object.keys(basketData || {});
  const StaticEntityKeys = Object.values(StaticEntityKey);

  const actionsToEmit = [];
  entitiesKeys.forEach((entityKey: StaticEntityKey) => {
    const entityValue = basketData[entityKey];
    const isAvailableEntityKey = StaticEntityKeys.includes(entityKey);
    if (!isAvailableEntityKey) {
      // Ignore unknown entities
      return;
    }

    if (entityValue) {
      switch (entityKey) {
        // TODO Refactor to new way of entities data model (store)
        case StaticEntityKey.Navigation:
          actionsToEmit.push(NavigationActions.setNavigationHistory({ steps: entityValue.history }));
          break;

        default:
          actionsToEmit.push(EntityActions.storeStaticEntity({ key: entityKey, value: entityValue }));
      }
    }
  });

  return actionsToEmit;
};

export const parseDynamicEntities = (basketData: BasketData): Action[] => {
  const entitiesKeys = Object.keys(basketData || {});
  const DynamicEntityKeys = Object.values(DynamicEntityKey);

  const actionsToEmit = [];
  entitiesKeys.forEach((entityKey: DynamicEntityKey) => {
    const entityData: Entity = basketData[entityKey] as unknown as Entity;
    const isAvailableEntityKey = DynamicEntityKeys.includes(entityKey);
    if (!isAvailableEntityKey) {
       // Ignore unknown entities
      return;
    }

    switch (entityKey) {
      case DynamicEntityKey.PaymentCreditCardInfo:
        const entityCreditCardParsed = convertToParsedEntity(entityData);
        // Normalize date value
        entityCreditCardParsed.value = {
          ...entityCreditCardParsed.value,
          expirationDate: DatesService.fromDateToUICreditCard(DatesService.fromISOToDate(entityCreditCardParsed.value.expirationDate))
        };
        actionsToEmit.push(EntityActions.storeDynamicEntity({ key: entityKey, value: entityCreditCardParsed }));
        break;
      case DynamicEntityKey.CustomerDocument: {
        const entityDocumentParsed = convertToParsedEntity(entityData);

        // Normalize date value
        entityDocumentParsed.value = {
          ...entityDocumentParsed.value,
          issueDate: DatesService.fromDateToUI(DatesService.fromISOToDate(entityDocumentParsed.value.issueDate))
        };

        actionsToEmit.push(EntityActions.storeDynamicEntity({ key: entityKey, value: entityDocumentParsed }));
        break;
      }

      default:
        actionsToEmit.push(EntityActions.storeDynamicEntity({ key: entityKey, value: convertToParsedEntity(entityData)}));
    }
  });

  return actionsToEmit;
};

export const parseBasketData = (basketData: BasketData): Action[] => {
  return [
    ...parseStaticEntities(basketData),
    ...parseDynamicEntities(basketData)
  ];
};

export const generateRequiredEntitiesKeysFromStepId = (stepId: StepId, ignoredEntitiesKeys: EntityKey[]): EntityKey[] => {
  let entitiesKeys: EntityKey[] = [
    StaticEntityKey.Cart,
    StaticEntityKey.Navigation,
    StaticEntityKey.PaymentAvailableMethods,
    StaticEntityKey.AvailableAddons,
    StaticEntityKey.AvailableOperators,
    StaticEntityKey.AvailableActivationMethods,
    // StaticEntityKey.AvailableSimTypes,
    StaticEntityKey.AvailableSteps,
    StaticEntityKey.DebugInfo,
    StaticEntityKey.Offer,
    StaticEntityKey.OfferAlternative,
    StaticEntityKey.OfferPromoInfo,
    DynamicEntityKey.CouponInfo,
    DynamicEntityKey.SimType,
    DynamicEntityKey.MgmInfo,
    DynamicEntityKey.SelectedAddons
  ];

  if (StepRequiredEntitiesKeysMap[stepId]) {
    entitiesKeys = [ ...entitiesKeys, ...StepRequiredEntitiesKeysMap[stepId] ];
  }

  return difference(entitiesKeys, ignoredEntitiesKeys);
};

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inferExclusiveDiscount'
})
export class InferExclusiveDiscountPipe implements PipeTransform {

  transform(value: any, special: any): any {
    if (value && special) {
      return value * 2;
    }
  }

}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {InternalPromoInfo, PROMO_TYPES} from 'models';
import {combineLatest, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {AnalyticsService, EnvironmentService, OfferPromoService} from 'services';

import {AppStoreFacadeService} from '../../../../../facades/app-store-facade.service';

@Component({
  selector: 'sb-promo-banner',
  templateUrl: './promo-banner.component.html',
  styleUrls: ['./promo-banner.component.scss']
})
export class PromoBannerComponent implements OnInit, OnDestroy {

  protected subscriptions: Subscription = new Subscription();
  public promos: InternalPromoInfo[];
  @Input() public isInCart = false;

  private analyticsSent = false;

  constructor(
    private appStoreFacadeService: AppStoreFacadeService,
    private environmentService: EnvironmentService,
    private offerPromoService: OfferPromoService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.managePromos();
  }

  private managePromos(): void {
    this.subscriptions.add(
      combineLatest([
        this.appStoreFacadeService.entity.offerPromoInfo$,
        this.appStoreFacadeService.entity.offer$,
        this.appStoreFacadeService.entity.couponInfo$
      ])
        .pipe(filter(args => args.every(Boolean)))
        .subscribe(([ offerPromoEntity, offer, couponInfo ]) => {
          const messages = {};
          this.promos = this.offerPromoService.elaborateOfferPromoInfo(offerPromoEntity, offer, couponInfo).filter(promo => {
            // Should remove promos which have same type and message
            const promoKey = promo.promoType + (promo.message || promo.promoType || promo.type || '');
            if (messages[promoKey]) {
              return false;
            }
            messages[promoKey] = true;
            return true;
          });
          if (!this.analyticsSent) {
            this.analyticsService.sendPromoInfo(this.promos);
            this.analyticsSent = true;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}

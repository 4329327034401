import { Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { MacroStepId, ProcessStepsIds, StepId } from 'models';
import { navigateTo } from './navigation.actions';
import { selectCurrentMacroStepId, selectCurrentStepId } from './navigation.selectors';

export function filterByStepFactory(store: Store<any>) {
  return function filterByStep<T>(stepId: StepId) {
    return function filterByStepImplementation(source: Observable<T>): Observable<T> {
      return source.pipe(
        withLatestFrom(
          store.select(selectCurrentStepId),
          (action, stepIdFromStore) => ({ action, stepIdFromStore })
        ),
        filter(({ stepIdFromStore }) => stepIdFromStore === stepId),
        map(({ action }) => action)
      );
    };
  };
}

export function filterByMacroStepFactory(store: Store<any>) {
  return function filterByStep<T>(macroStepId: MacroStepId) {
    return function filterByStepImplementation(source: Observable<T>): Observable<T> {
      return source.pipe(
        withLatestFrom(
          store.select(selectCurrentMacroStepId),
          (action, macroStepIdFromStore) => ({ action, macroStepIdFromStore })
        ),
        filter(({ macroStepIdFromStore }) => macroStepIdFromStore === macroStepId),
        map(({ action }) => action)
      );
    };
  };
}

export const inferNavigateToAction = (stepId: StepId, queryParams?: Params ) => {
  if (ProcessStepsIds.includes(stepId)) {
    return navigateTo({ commands: [ `/${ MacroStepId.Process }/${ stepId }` ], extras: { queryParams } });
  }

  return navigateTo({ commands: [ `/${ stepId }` ] });
};

import { createAction, props } from '@ngrx/store';

export enum TranslationActionTypes {
  SetTranslations = '[Translation] Set Translations',
}

export const setTranslations = createAction(
  TranslationActionTypes.SetTranslations,
  props<{ lang: string, translations: any, shouldMerge: boolean }>()
);

export type TranslationActionsUnion = ReturnType<
  | typeof setTranslations
  >;

import { createSelector } from '@ngrx/store';

import { Cart, StaticEntityKey } from 'models';

import { EntitySelectors } from '../../entity-feature-store';

export const selectCart = EntitySelectors.getEntitySelectorByKey(StaticEntityKey.Cart);
export const selectTotalPrices = createSelector(
  selectCart,
  (cart: Cart) => cart && cart.totalPrices
);

<ng-template #headerTemplate [ngIf]="shouldBeVisible">
  <div class="sb-header-section" [ngClass]="promoCSSClasses">
    <header class="sb-header" >
      <div class="sb-header__logo" [ngClass]="redirectUrl ? 'sb-header__logo--link' : ''" (click)="onLogoClick()"></div>
      <div class="sb-header__title wt-text-bold-xxl">{{ headerTitle }}</div>
      <div class="cart-toggle">
        <div class="sb-header__price--label--discount strikediag" *ngIf="showCart && cart?.totalPrice?.price?.previous">
          {{ cart.totalPrice.price.previous | inferExclusiveDiscount:promoExclusiveDiscount | currency:cart.totalPrice.price.currency | inferCurrencyLabel | translate}}
        </div>
        <div class="sb-header__total center-xs" *ngIf="showCart && cart" (click)="toggleCollapse($event)">
          <div class="sb-header__price--label" *ngIf="cart">{{cart.totalPrice.type | inferCartPriceLabels | translate}}</div>
          <div class="sb-header__price--amount wt-text-bold-xxl" *ngIf="cart">{{cart.totalPrice.price.value | currency:cart.totalPrice.price.currency | inferCurrencyLabel }}</div>
          <div class="arrow-icon" [class.arrow-icon--close]="modalIsOpen"></div>
        </div>
      </div>
    </header>
    <div class="sb-header__container__steps">
      <div class="process__steps" *ngIf="steps && activeStep">
        <wt-steps-bar
          [steps]="steps"
          [activeStep]="activeStep"
          [showAllLabels]="false"
          [showActiveLabel]="false"
        ></wt-steps-bar>
      </div>
    </div>
    <div class="wt-text-light-l sb-cart" *ngIf="modalIsOpen">
      <div class="sb-cart__container" [ngClass]="{'hasPromo': promos.length > 0}">
        <div class="promo-ribbon {{promoCSSClasses}}" *ngIf="promos && promos.length > 0">
          <img src="assets/images/promo-ribbon-{{promoCSSRibbon}}.svg" alt="Promo">
        </div>
        <sb-cart-partial-offer-info [offer]="offer" [offerAlternative]="offerAlternative" [offerExtras]="offerExtras" [hasDoublePO]="hasDoublePO" [activationMethods]="availableActivationMethods" [operators]="availableOperators"></sb-cart-partial-offer-info>
        <ng-container *ngIf="offer.offerType === 'data-only'">
          <div class="data-only-info">
            <strong>I minuti e gli SMS sono a consumo</strong> e prevedono un costo di 0,29€ al minuto e di 0,29€ a SMS
          </div>
        </ng-container>
      </div>

      <div class="sb-cart__container-prices">
        <div *ngIf="promoAddon" class="sb-cart-addon promo-addon-{{(promoAddon.type || 'generic').toLowerCase()}}">
          <div class="sb-cart-addon__image">
            <img src="assets/images/promo-addon-{{promoAddon.type.toLowerCase()}}-cart.svg"alt="">
          </div>
          <div class="sb-cart-addon__info promo-addon-{{(promoAddon.type || 'generic').toLowerCase()}}">
          <div class="sb-cart-addon__details">
              <span class="title">
                {{('PROMO-ADDON-' + promoAddon.type + '.CART.TITLE') | translate}}
              </span>
              <span class="subtitle">
                {{('PROMO-ADDON-' + promoAddon.type + '.CART.DETAILS') | translate}}
              </span>
            </div>
            <div class="sb-cart-addon__frequency">{{('PROMO-ADDON-' + promoAddon.type + '.CART.FREQUENCY') | translate}}</div>
          </div>
          <div class="fake"></div>
        </div>

<!--        BANNER SPENTO IN ATTESA DI CAPIRE COSA SI INTENDE FARE-->
<!--        <div class="sb-cart-lbanner" *ngIf="hasDoublePO">-->
<!--          <div class="sb-cart-lbanner__image">-->
<!--            <img src="assets/images/promo-addon-generic-cart.svg" alt="">-->
<!--          </div>-->
<!--          <div class="sb-cart-lbanner__info">-->
<!--            <div class="sb-cart-lbanner__details">-->
<!--              <span class="title">-->
<!--                TITOLO-->
<!--              </span>-->
<!--              <span class="subtitle">-->
<!--                sottotitolo-->
<!--              </span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <sb-cart-partial-price-and-delivery-info [cartItems]="cart.totalPrices | inferCartItems" [simType]="simType" [total]="cart.totalPrice"></sb-cart-partial-price-and-delivery-info>
      </div>
    </div>
  </div>
</ng-template>
<!--  -->
